import React from "react";
import { Badge, Flex } from "@chakra-ui/react";
import { Text } from "../Text";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { percentFormatter, priceFormatter } from "../../helpers/formatters";
import { Tooltip } from "../Tooltip";
import { Decimal, Price, Trove } from "@liquity/lib-base";

type PoolInfoProps = {
  currentPrice: Decimal;

  original: Trove | undefined;
  adjusted: Trove | undefined;
  price: Price;
  usdPrice: Decimal;
  fee: Decimal;
  feeToken: string;
  minLoanValue: number | undefined;
};

type ItemProps = {
  label: string;
  value: string;
  tooltip?: string;
  badge?: string;
  scheme?: "green" | "red";
};

const Item: React.FC<ItemProps> = ({ label, value, tooltip, badge, scheme }) => {
  return (
    <Flex direction="column">
      <Text
        color="text.500"
        fontSize="18px"
        lineHeight="23px"
        display="flex"
        gap="2px"
        alignItems="flex-start"
      >
        {label} {tooltip && <Tooltip label={tooltip} />}
      </Text>
      <Text color="text.200" fontSize="18px" lineHeight="18px">
        {value}
      </Text>
      <Badge colorScheme={scheme} alignSelf="start" mt="5px">
        {badge}
      </Badge>
    </Flex>
  );
};

/*

(adjustedTrove
  ? adjustedTrove.collateral.isZero
    ? adjustedTrove.setCollateral(Decimal.ONE).liquidationPrice(price).get()
    : adjustedTrove.liquidationPrice(price).get()
  : originalTrove?.liquidationPrice(price).get() ?? Decimal.ZERO
).mul(
  selectedBorrowingOption.selectOption === "ETHZ" &&
    selectedCollateralOption.selectOption !== "USDC"
    ? prices["WETH"]?.price ?? Decimal.ONE
    : Decimal.ONE
)

 */

export const PoolInfo: React.FC<PoolInfoProps> = ({
  currentPrice,
  original,
  adjusted,
  price,
  usdPrice,
  fee,
  feeToken,
  minLoanValue
}) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  const isNew = original && original.collateral.isZero && original.debt.isZero;
  const before =
    !original || !price || isNew
      ? 0
      : isNaN(original.collateralRatio(price).toNumber())
      ? null
      : original.collateralRatio(price).toNumber();
  const after = (
    adjusted ? adjusted.collateralRatio(price) : original?.collateralRatio(price) ?? Decimal.ZERO
  ).toNumber();
  const liqPriceBefore =
    !original || !price || isNew
      ? Decimal.ZERO
      : isNaN(original.liquidationPrice(price).get().toNumber())
      ? null
      : original.liquidationPrice(price).get().mul(usdPrice);
  const liqPriceAfter = (
    adjusted
      ? adjusted.liquidationPrice(price).get()
      : original?.liquidationPrice(price).get() ?? Decimal.ZERO
  ).mul(usdPrice);

  return (
    <Flex w={isMobileOrTablet ? "100%" : "30%"}>
      <Flex
        direction="column"
        bg="white"
        borderRadius="20px"
        p="24px 32px"
        gap="16px"
        w={isMobileOrTablet ? "100%" : "auto"}
      >
        <Item label="Current Price" value={priceFormatter.format(currentPrice.toNumber())} />
        <Item
          label="Collateralization Ratio"
          value={
            isNaN(liqPriceAfter.toNumber()) || liqPriceAfter.isZero || after === 0
              ? "N/A"
              : isNew
              ? percentFormatter.format(after)
              : before
              ? percentFormatter.format(before)
              : "N/A"
          }
          tooltip="Your collateralization ratio"
          badge={
            isNew || isNaN(liqPriceAfter.toNumber()) || liqPriceAfter.isZero || after === 0
              ? undefined
              : `${percentFormatter.format(after)} after`
          }
          scheme={!before || before === after ? undefined : before > after ? "red" : "green"}
        />
        <Item
          label="Liquidation Price"
          value={
            isNaN(liqPriceAfter.toNumber()) || liqPriceAfter.isZero || after === 0
              ? "N/A"
              : isNew
              ? priceFormatter.format(liqPriceAfter.toNumber())
              : liqPriceBefore
              ? priceFormatter.format(liqPriceBefore.toNumber())
              : "N/A"
          }
          tooltip="Your liquidation price"
          badge={
            isNew || isNaN(liqPriceAfter.toNumber()) || liqPriceAfter.isZero || after === 0
              ? undefined
              : `${priceFormatter.format(liqPriceAfter.toNumber())} after`
          }
          scheme={
            !liqPriceBefore || liqPriceBefore.eq(liqPriceAfter)
              ? undefined
              : liqPriceBefore.gt(liqPriceAfter)
              ? "green"
              : "red"
          }
        />
        {!isNew && (
          <Item label="Fee" value={`${fee.prettify(2)} ${feeToken}`} tooltip="Fee on borrow" />
        )}
        <Item label="Min. Loan Value" value={`${Decimal.from(minLoanValue ?? 0).prettify(2)} ${feeToken}`} />
      </Flex>
    </Flex>
  );
};
