import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Text } from "../Text";

type DescriptionCardProps = {
  color: string;
  title: string;
  label: string;
};

export const DescriptionCard: React.FC<DescriptionCardProps> = ({ color, label, title }) => {
  return (
    <Box
      background="white"
      border={`1px solid ${color}`}
      borderRadius="20px"
      p="32px 24px 24px 24px"
      maxW="410px"
      w="100%"
    >
      <Flex direction="column" alignItems="center" gap="16px">
        <Text fontSize="24px" fontWeight={600} textAlign="center">
          {title}
        </Text>
        <Text fontSize="18px" color="text.200" textAlign="center">
          {label}
        </Text>
      </Flex>
    </Box>
  );
};
