import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  UnorderedList,
  useToast
} from "@chakra-ui/react";
import { useSeparatedDataArray } from "../hooks/useSeparatedDataArray";
import { SwiperWithPaginator } from "../components/Swiper";
import { SwiperSlide } from "swiper/react";
import { useMatchBreakpoints } from "../hooks/useMatchBreakpoints";
import { Decimal, Price, UserTrove } from "@liquity/lib-base";
import { useLiquitySelector, useLiquityStore } from "@liquity/lib-react";
import { useLiquity } from "../hooks/LiquityContext";
import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import { shortenAddress } from "../helpers/formatters";
import { InputGroup, Option } from "../components/InputGroup";
import { AppPage } from "../types/AppPage";
import { useTransactionFunction } from "../hooks/useTransactionFunction";
import { useReferrer } from "../hooks/useReferrer";
import { NavLink } from "react-router-dom";
import { learnMoreLink } from "../constants/links";
import { ArrowIcon } from "../components/ArrowIcon";
import { LearnAnd } from "../components/LearnAnd";
import { _getPoolName, _splitPoolName } from "@liquity/lib-ethers/dist/src/contracts";
import DropdownIcon from "../assets/icons/dropdown.svg";
import { matchErrorMessage } from "../helpers/regex";

const PAGE_SIZE = 15;

const select = ({
  allPoolsInfo,
  blockTag,
  spInfoByUser,
  prices,
  redemptionFees
}: BlockPolledLiquityStoreState) => ({
  poolNames: Object.keys(allPoolsInfo),
  allPoolsInfo,
  blockTag,
  prices,
  spInfoByUser,
  redemptionFees
});

export const Dashboard = () => {
  const toast = useToast();
  const { isMobileOrTablet } = useMatchBreakpoints();
  const { poolNames, blockTag, allPoolsInfo, spInfoByUser, prices, redemptionFees } =
    useLiquitySelector(select);
  const [troves, setTroves] = useState<Array<UserTrove & { price: Price; name: string }>>([]);
  const [numberOfVaults, setNumberOfVaults] = useState<number>(0);
  const { liquity } = useLiquity();
  const store = useLiquityStore();
  const referrer = useReferrer();
  const borrowingOptions = useMemo(() => liquity.getAllBorrowingOptions(), [liquity]);

  const depositOptions: Option[] = borrowingOptions.map(([name, token]) => ({
    selectOption: token.optionName,
    price: spInfoByUser[token.optionName]?.price ?? new Price(Decimal.from(1), false),
    symbol: token.optionName,
    upTo: spInfoByUser[token.optionName]?.balance ?? Decimal.ZERO,
    apy: Decimal.from(0),
    poolSize: Decimal.from(0)
  }));
  const [selectedDepositOption, setSelectedDepositOption] = useState<Option>(depositOptions[0]);
  const [selectedItem, setSelectedItem] = useState(depositOptions[0].selectOption);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const depositOption = depositOptions.find(
      d => d.selectOption === selectedDepositOption.selectOption
    );
    if (depositOption) {
      depositOption.upTo = spInfoByUser[selectedDepositOption.selectOption]?.balance ?? Decimal.ZERO;
      selectedDepositOption.upTo =
        spInfoByUser[selectedDepositOption.selectOption]?.balance ?? Decimal.ZERO;
    }
  }, [selectedDepositOption, spInfoByUser]);

  useEffect(() => {
    let mounted = true;
    Promise.all(
      poolNames
        .map(_splitPoolName)
        .filter(({ borrowingTokenName }) => borrowingTokenName === selectedItem)
        .map(async poolName =>
          (
            await liquity.getTroves(
              _getPoolName(poolName.collateralTokenName, poolName.borrowingTokenName),
              { first: 1000, sortedBy: "ascendingCollateralRatio" },
              { blockTag }
            )
          ).map(trove => {
            const upd = trove as UserTrove & { price: Price; name: string };
            upd.price =
              allPoolsInfo[
                _getPoolName(poolName.collateralTokenName, poolName.borrowingTokenName)
              ].price;
            upd.name = _getPoolName(poolName.collateralTokenName, poolName.borrowingTokenName);
            return upd;
          })
        )
    ).then(trovesArray => {
      if (mounted) {
        const flatten = trovesArray
          .flat()
          .sort(
            (a, b) => a.collateralRatio(a.price).toNumber() - b.collateralRatio(b.price).toNumber()
          );
        setTroves(flatten);
        setNumberOfVaults(flatten.length);
      }
    });

    return () => {
      mounted = false;
    };
  }, [liquity, selectedItem]);

  const separatedVaults = useSeparatedDataArray(troves, PAGE_SIZE);

  const [amount, setAmount] = useState<number>(0);

  const transactionId = "redeem-stablecoin";

  const [redeem, state, setRedeemState] = useTransactionFunction(transactionId, () =>
    liquity.send.redeemLUSD.call(
      liquity.send,
      referrer,
      selectedDepositOption.selectOption,
      amount,
      undefined,
      false,
      undefined
    )
  );

  useEffect(() => {
    if (state.type === "failed" && state.id === transactionId) {
      const matchedError = matchErrorMessage(state.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setRedeemState({ type: "idle" });
    }
    if (state.type === "confirmed" && state.id === transactionId) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Redeem confirmed.",
        description: `Successfully redeemed ${Decimal.from(amount).prettify(2)} of ${
          selectedDepositOption.selectOption
        }.`,
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setRedeemState({ type: "idle" });
    }
  }, [state]);

  // useEffect(() => {
  //   if (store.setStop) {
  //     store.setStop(store.start());
  //   } else {
  //     store.start();
  //   }
  // }, [store]);

  const onButtonClick = async () => {
    try {
      if (spInfoByUser[selectedDepositOption.selectOption].balance.lt(amount)) {
        throw new Error("Not enough balance");
      }
      if (amount <= 0) {
        throw new Error(
          `Redeem allowed only with more than 0 ${selectedDepositOption.selectOption}`
        );
      }
      await redeem();
    } catch (e) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: e instanceof Error ? e.message : String(e),
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  const items: [string, string][] = [
    [
      "Total Value Locked (TVL)",
      `$${Object.entries(allPoolsInfo)
        .filter(([name]) => _splitPoolName(name).borrowingTokenName === selectedItem)
        .reduce(
          (a, [name, value]) =>
            a.add(
              value.total.collateral.mul(
                prices[_splitPoolName(name).collateralTokenName]?.price ?? Decimal.ZERO
              )
            ),
          Decimal.ZERO
        )
        .prettify(2)}`
    ],
    [
      `${selectedItem} collateral ratio`,
      `${Object.entries(allPoolsInfo)
        .filter(([name, value]) => _splitPoolName(name).borrowingTokenName === selectedItem)
        .map(([, value]) => value)
        .reduce(
          (a, b) =>
            a.add(b.total.collateralRatio(b.price).infinite ? 0 : b.total.collateralRatio(b.price)),
          Decimal.ZERO
        )
        .mul(100)
        .div(
          Object.entries(allPoolsInfo).filter(
            ([name, value]) => _splitPoolName(name).borrowingTokenName === selectedItem
          ).length
        )
        .prettify(2)}%`
    ],
    [`${selectedItem} Supply`, `${spInfoByUser[selectedItem].supply.prettify(2)}`],
    [
      `${selectedItem} in stability Pool`,
      `${Object.entries(allPoolsInfo)
        .filter(([name]) => name.includes(selectedItem))
        .map(([, value]) => value)
        .reduce((a, b) => a.add(b.lusdPool), Decimal.ZERO)
        .prettify(2)}`
    ],
    ["Number of vaults ", numberOfVaults.toString()],
    [
      "Recovery mode [On/Off]",
      Object.entries(allPoolsInfo)
        .filter(([name]) => name.includes(selectedItem))
        .map(([, value]) => value)
        .some(p => p.total.collateralRatioIsBelowMinimum(p.price))
        ? "On"
        : "Off"
    ],
    [
      "Recovery mode price",
      `$${Object.entries(allPoolsInfo)
        .filter(([name]) => name.includes(selectedItem))
        .map(([, value]) => value)
        .reduce(
          (a, b) =>
            a.add(
              b.total.collateralRatio(b.price).infinite
                ? 0
                : b.price.price
                    .mul(b.total.poolBaseConfig.criticalCollateralRatio)
                    .div(b.total.collateralRatio(b.price))
            ),
          Decimal.ZERO
        )
        .div(Object.values(allPoolsInfo).length)
        // .mul(selectedItem === "ETHZ" ? prices["WETH"]?.get() ?? Decimal.ONE : Decimal.ONE)
        .prettify(2)}`
    ]
  ];

  return (
    <Flex w="100%" direction="column" alignItems="center">
      <Flex fontSize="18px" fontWeight={600} gap="4px">
        <Text>Protocol Statistics for </Text>
        <Menu onOpen={() => setIsOpened(true)} onClose={() => setIsOpened(false)}>
          <MenuButton transition="all 0.2s" zIndex={9}>
            <Flex gap="8px">
              <Text fontSize="18px" fontWeight={600}>
                {selectedItem}
              </Text>{" "}
              <Image src={DropdownIcon} alt="indicator" style={{ rotate: isOpened ? "180deg" : "360deg" }} />
            </Flex>
          </MenuButton>
          <MenuList minW="80px" maxW="80px" p="20px" borderRadius="10px" zIndex={10}>
            {depositOptions
              .map(o => o.selectOption)
              .map(o => (
                <MenuItem
                  key={o}
                  zIndex={10}
                  maxW="80px"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="14px"
                  color="text.300"
                  onClick={() => setSelectedItem(o)}
                  mb="8px"
                  _last={{
                    mb: 0
                  }}
                  _hover={{
                    color: "#FD7B1D",
                    fontWeight: 500
                  }}
                  _focus={{
                    color: "#FD7B1D",
                    fontWeight: 500
                  }}
                  _active={{}}
                  p={0}
                >
                  {o}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </Flex>
      <Grid templateRows="1fr" gap="2px" mt="24px" mb="64px" maxW="540px" w="100%" position="relative">
        {items.map(([header, value]) => (
          <GridItem key={header} minW="100%" position="relative">
            <Box
              position="absolute"
              bg="rgba(255,255,255,0.4)"
              top={0}
              zIndex={0}
              left="-500%"
              w="1000%"
              h="100%"
            ></Box>
            <Grid
              templateColumns="repeat(2, 1fr)"
              py="13px"
              w="100%"
              justifyItems="flex-start"
              px="16px"
              {...(isMobileOrTablet ? {} : { gap: "100px" })}
            >
              <GridItem zIndex={1}>
                <Text color="text.200" fontSize="16px">
                  {header}
                </Text>
              </GridItem>
              <GridItem zIndex={1} {...(isMobileOrTablet && { justifySelf: "flex-end" })}>
                <Text color="text.200" fontSize="16px">
                  {value}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        ))}
      </Grid>
      <InputGroup
          page={AppPage.Protocol}
          firstInputOptions={depositOptions}
          secondInputOptions={[]}
          firstWithoutIcons
          onButtonClick={onButtonClick}
          onFirstInputOptionChanged={setSelectedDepositOption}
          onChangeFirstAmount={v => setAmount(v)}
          onChangeSecondAmount={() => undefined}
          onCancel={() => undefined}
          buttonName="Redeem"
          state={state}
          withoutSecond
          protocolInfoItems={depositOptions.map(o => ({
            token: o.selectOption,
            price: o.price.get(),
            redemptionFee: redemptionFees[o.selectOption]?.fee ?? Decimal.ZERO
          }))}
          selectedCollateralOption={selectedDepositOption}
          selectedBorrowingOption={depositOptions[0]}
      />
      <Text fontSize="18px" fontWeight={600} mt="64px">
        Riskiest Vaults
      </Text>
      <Grid templateRows="1fr" gap="2px" mt="24px" justifyItems="center">
        <GridItem position="relative">
          <Box
            position="absolute"
            bg="rgba(255,255,255,0.4)"
            top={0}
            zIndex={0}
            left="-500%"
            w="1000%"
            h="100%"
          ></Box>
          <Grid
            templateColumns="repeat(4, 1fr)"
            py="13px"
            gap={isMobileOrTablet ? "0" : "60px"}
            w="100%"
          >
            <GridItem {...(!isMobileOrTablet ? { zIndex: 1 } : {})}>
              <Text textAlign="center" color="text.200" fontSize="16px" fontWeight={600}>
                Owner
              </Text>
            </GridItem>
            <GridItem {...(!isMobileOrTablet ? { zIndex: 1 } : {})}>
              <Text textAlign="center" color="text.200" fontSize="16px" fontWeight={600}>
                Collateral
              </Text>
            </GridItem>
            <GridItem {...(!isMobileOrTablet ? { zIndex: 1 } : {})}>
              <Text textAlign="center" color="text.200" fontSize="16px" fontWeight={600}>
                Debt
              </Text>
            </GridItem>
            <GridItem {...(!isMobileOrTablet ? { zIndex: 1 } : {})}>
              <Text textAlign="center" color="text.200" fontSize="16px" fontWeight={600}>
                Collateral Ratio
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
        <SwiperWithPaginator
          paginatorType="num"
          posLength={troves.length || 1}
          pageSize={PAGE_SIZE}
          style={{ transition: "none !important" }}
        >
          {separatedVaults.map((array, index) => (
            <SwiperSlide key={index} style={{ gap: "2px", transition: "none" }}>
              {array.map((v, insetIndex) => {
                const { borrowingTokenName, collateralTokenName } = _splitPoolName(v.name);
                return (
                  <GridItem
                    key={v.ownerAddress.concat(v.collateral.toString())}
                    position="relative"
                    justifyItems="center"
                  >
                    {index === 0 && (
                      <Box
                        position="absolute"
                        bg="rgba(255,255,255,0.4)"
                        top={0}
                        zIndex={0}
                        left="-200%"
                        w="5000%"
                        h="100%"
                      ></Box>
                    )}
                    <Grid
                      templateColumns="repeat(4, 1fr)"
                      py="13px"
                      gap={isMobileOrTablet ? "0" : "50px"}
                      justifyItems="center"
                      maxW="650px"
                      mx={isMobileOrTablet ? "15px" : "auto"}
                    >
                      <GridItem zIndex={1}>
                        <Text textAlign="center" color="text.200" fontSize="16px">
                          {shortenAddress(v.ownerAddress, 4, 2)}
                        </Text>
                      </GridItem>
                      <GridItem zIndex={1}>
                        <Text textAlign="center" color="text.200" fontSize="16px">
                          {v.collateral.prettify(0)} {collateralTokenName}
                        </Text>
                      </GridItem>
                      <GridItem zIndex={1}>
                        <Text textAlign="center" color="text.200" fontSize="16px">
                          {v.debt.prettify(0)} {borrowingTokenName}
                        </Text>
                      </GridItem>
                      <GridItem zIndex={1}>
                        <Text textAlign="center" color="text.200" fontSize="16px">
                          {v.collateralRatio(v.price).mul(100).prettify(2)}%
                        </Text>
                      </GridItem>
                    </Grid>
                  </GridItem>
                );
              })}
            </SwiperSlide>
          ))}
        </SwiperWithPaginator>
      </Grid>
      <LearnAnd and="Earn">
        <UnorderedList color="text.200" fontSize="16px" mt="15px" lineHeight="20px">
          <ListItem>
            Deposit {depositOptions.map(o => o.selectOption).join(" or ")} in the stability pool to
            earn token rewards.
          </ListItem>
          <ListItem>
            The Stability Pool earns the excess collateral in loan liquidations in addition to ZRO
            token rewards.
          </ListItem>
          <ListItem>Stake ZRO to earn a proportion of Protocol revenue.</ListItem>
          <ListItem listStyleType="none" pt="30px">
            <Link
              as={NavLink}
              to={learnMoreLink}
              fontSize="16px"
              color="primary.100"
              justifySelf="flex-start"
              target="_blank"
              textUnderlineOffset="5px"
            >
              Learn more
            </Link>{" "}
            <ArrowIcon />
          </ListItem>
        </UnorderedList>
      </LearnAnd>
    </Flex>
  );
};
