import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  useBreakpointValue,
  useConst
} from "@chakra-ui/react";
import { Text } from "../Text";
import { NavLink } from "react-router-dom";
import {
  codebaseLink,
  disclaimerLink,
  linkedinLink,
  privacyPolicyLink,
  whitepaperLink,
  documentationLink,
  telegramLink,
  twitterLink
} from "../../constants/links";
import LinkedinLogo from "../../assets/footer/linkedin.svg";
import TelegramLogo from "../../assets/footer/telegram.svg";
import TwitterLogo from "../../assets/footer/twitter.svg";
import Arrow from "../../assets/footer/arrow.svg";
import { SocialLink } from "./SocialLink";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import FooterChevronUp from "../../assets/footer/footer-chevron-up.svg";
import FooterChevronDown from "../../assets/footer/footer-chevron-down.svg";

export const Footer = () => {
  const { px } = useBreakpointValue([{ px: "16px" }, { px: "16px" }, { px: "80px" }]) ?? {
    px: "16px"
  };
  const { isMobileOrTablet } = useMatchBreakpoints();
  const socials = useConst(() => [
    {
      icon: TwitterLogo,
      link: twitterLink
    },
    // {
    //   icon: YoutubeLogo,
    //   link: youtubeLink
    // },
    {
      icon: LinkedinLogo,
      link: linkedinLink
    },
    {
      icon: TelegramLogo,
      link: telegramLink
    }
  ]);
  const [email, setEmail] = useState<string>("");

  return (
    <Flex
      w="100%"
      px={px}
      pb="48px"
      justifyContent="space-between"
      mt="90px"
      direction={isMobileOrTablet ? "column" : "row"}
    >
      <Flex direction="column" justifyContent="space-between">
        <Text color="text.200" fontSize="24px" fontWeight={700}>
          Zero Protocol
        </Text>
        {isMobileOrTablet ? (
          <Accordion allowMultiple>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    px={0}
                    pb={0}
                    mb={0}
                    sx={{
                      "&:hover": { backgroundColor: "transparent !important" }
                    }}
                  >
                    <Text
                      color="text.200"
                      flex="1"
                      textAlign="left"
                      fontSize="18px"
                      fontWeight={600}
                    >
                      Product
                    </Text>
                    <Image src={isExpanded ? FooterChevronUp : FooterChevronDown} alt="chevron" />
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    <Link as={NavLink} to={whitepaperLink}>
                      <Text color="text.200" fontSize="18px">
                        Support
                      </Text>
                    </Link>
                    <Link href="#faq">
                      <Text color="text.200" fontSize="18px">
                        FAQ
                      </Text>
                    </Link>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    px={0}
                    pb={0}
                    mb={0}
                    sx={{
                      "&:hover": { backgroundColor: "transparent !important" }
                    }}
                  >
                    <Text
                      color="text.200"
                      flex="1"
                      textAlign="left"
                      fontSize="18px"
                      fontWeight={600}
                    >
                      For Developers
                    </Text>
                    <Image src={isExpanded ? FooterChevronUp : FooterChevronDown} alt="chevron" />
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    <Link as={NavLink} to={codebaseLink} target="_blank">
                      <Text color="text.200" fontSize="18px">
                        Code base
                      </Text>
                    </Link>
                    <Link as={NavLink} to={documentationLink} target="_blank">
                      <Text color="text.200" fontSize="18px">
                        Technical Resources
                      </Text>
                    </Link>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    px={0}
                    pb={0}
                    mb={0}
                    sx={{
                      "&:hover": { backgroundColor: "transparent !important" }
                    }}
                  >
                    <Text
                      color="text.200"
                      flex="1"
                      textAlign="left"
                      fontSize="18px"
                      fontWeight={600}
                    >
                      Company
                    </Text>
                    <Image src={isExpanded ? FooterChevronUp : FooterChevronDown} alt="chevron" />
                  </AccordionButton>
                  <AccordionPanel px={0}>
                    <Link as={NavLink} to={disclaimerLink} target="_blank">
                      <Text color="text.200" fontSize="18px">
                        Contact us
                      </Text>
                    </Link>
                    <Link as={NavLink} to={privacyPolicyLink} target="_blank">
                      <Text color="text.200" fontSize="18px">
                        Press kit
                      </Text>
                    </Link>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        ) : (
          <Flex gap="100px" mt="40px">
            <Flex direction="column" gap="14px">
              <Text color="text.200" fontSize="18px" fontWeight={600}>
                Product
              </Text>
              <Link as={NavLink} to={whitepaperLink} target="_blank">
                <Text color="text.200" fontSize="18px">
                  Whitepaper
                </Text>
              </Link>
              <Link href="#faq" target="_blank">
                <Text color="text.200" fontSize="18px">
                  FAQ
                </Text>
              </Link>
            </Flex>
            <Flex direction="column" gap="14px">
              <Text color="text.200" fontSize="18px" fontWeight={600}>
                For Developers
              </Text>
              <Link as={NavLink} to={documentationLink} target="_blank">
                <Text color="text.200" fontSize="18px">
                  Documentation
                </Text>
              </Link>
              <Link as={NavLink} to={codebaseLink} target="_blank">
                <Text color="text.200" fontSize="18px">
                  Codebase
                </Text>
              </Link>
            </Flex>
            <Flex direction="column" gap="14px">
              <Text color="text.200" fontSize="18px" fontWeight={600}>
                Company
              </Text>
              <Link as={NavLink} to={disclaimerLink} target="_blank">
                <Text color="text.200" fontSize="18px">
                  Disclaimer
                </Text>
              </Link>
              <Link as={NavLink} to={privacyPolicyLink} target="_blank">
                <Text color="text.200" fontSize="18px">
                  Privacy Policy
                </Text>
              </Link>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        width={isMobileOrTablet ? "100%" : "auto"}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        w="100%"
        maxW={isMobileOrTablet ? "100%" : "463px"}
        mt={isMobileOrTablet ? "32px" : "0"}
      >
        {isMobileOrTablet ? null : <Flex />}
        <Flex
          alignItems="center"
          gap="14px"
          justifyContent={isMobileOrTablet ? "center" : "flex-end"}
          w="100%"
        >
          {socials.map(({ icon, link }) => (
            <SocialLink key={icon} icon={icon} link={link} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
