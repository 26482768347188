import React from "react";
import { Flex, Image, useBreakpointValue, useConst } from "@chakra-ui/react";
import { Text } from "../Text";
import MarketCycles from "../../assets/landing/market-cycles.svg";
import { DescriptionCard } from "./DescriptionCard";

export const LandingDescription = () => {
  const { mcMt, direction, gap, nMt, mx } = useBreakpointValue(
    [
      { mcMt: "24px", direction: "column" as const, gap: "16px", nMt: "0", mx: "16px" },
      { mcMt: "24px", direction: "column" as const, gap: "16px", nMt: "0", mx: "16px" },
      { mcMt: "40px", direction: "row" as const, gap: "30px", nMt: "-180px", mx: "80px" }
    ],
    {
      ssr: false
    }
  ) ?? { mcMt: "24px", direction: "column" as const, gap: "16px", nMt: "0", mx: "16px" };

  const infos = useConst(() => [
    {
      color: "#4FDFFF",
      title: "Leveraged Long",
      label:
        "Borrow synthetic USD against your wETH stETH order to buy more of your collateral asset."
    },
    {
      color: "#4F6BFF",
      title: "Short Position",
      label: "Lock up USDC to borrow synthetic Ether and mange your exposure."
    },
    {
      color: "#E64FFF",
      title: "Amplify yield",
      label: "Borrow synthetic Ether against stETH to buy more collateral."
    }
  ]);

  return (
    <Flex direction="column" alignItems="center" mt="80px" position="relative">
      <Text fontSize="24px" fontWeight={500} textAlign="center" mx="75px">
        Manage Exposure Across Market Cycles
      </Text>
      <Image src={MarketCycles} mt={mcMt} maxH="615px" />
      <Flex direction={direction} gap={gap} mx={mx} mt={nMt}>
        {infos.map(({ color, title, label }) => (
          <DescriptionCard key={title} color={color} title={title} label={label} />
        ))}
      </Flex>
    </Flex>
  );
};
