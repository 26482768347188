import { Interface, JsonFragment, LogDescription } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import { BlockTag, Listener, Log } from "@ethersproject/abstract-provider";

import {
  Contract,
  ContractInterface,
  ContractFunction,
  Overrides,
  CallOverrides,
  PopulatedTransaction,
  ContractTransaction,
  EventFilter
} from "@ethersproject/contracts";

import activePoolAbi from "../abi/ActivePool.json";
import borrowerOperationsAbi from "../abi/BorrowerOperations.json";
import borrowerOperationsETHAbi from "../abi/BorrowerOperationsETH.json";
import troveManagerAbi from "../abi/TroveManager.json";
import lusdTokenAbi from "../abi/LUSDToken.json";
import collSurplusPoolAbi from "../abi/CollSurplusPool.json";
import communityIssuanceAbi from "../abi/CommunityIssuance.json";
import defaultPoolAbi from "../abi/DefaultPool.json";
import lqtyTokenAbi from "../abi/LQTYToken.json";
import hintHelpersAbi from "../abi/HintHelpers.json";
import lockupContractFactoryAbi from "../abi/LockupContractFactory.json";
import lqtyStakingAbi from "../abi/LQTYStaking.json";
import multiTroveGetterAbi from "../abi/MultiTroveGetter.json";
import priceFeedAbi from "../abi/PriceFeed.json";
import priceFeedTestnetAbi from "../abi/PriceFeedTestnet.json";
import sortedTrovesAbi from "../abi/SortedTroves.json";
import stabilityPoolAbi from "../abi/StabilityPool.json";
import gasPoolAbi from "../abi/GasPool.json";
import unipoolAbi from "../abi/Unipool.json";
import iERC20Abi from "../abi/IERC20.json";
import erc20MockAbi from "../abi/ERC20Mock.json";
import aggregatorV3InterfaceAbi from "../abi/AggregatorV3Interface.json";
import iWETH9Abi from "../abi/IWETH9.json";
import poolAddressesRegistrar from "../abi/PoolAddressesRegistrar.json";
import referralCenter from "../abi/ReferralCenter.json";
import multicall from "../abi/Multicall.json";
import redemptionManager from "../abi/RedemptionManager.json";
import tellorCallerAbi from "../abi/TellorCaller.json";

export { 
  troveManagerAbi
};

import {
  ActivePool,
  BorrowerOperations,
  TroveManager,
  LUSDToken,
  CollSurplusPool,
  CommunityIssuance,
  DefaultPool,
  LQTYToken,
  HintHelpers,
  LockupContractFactory,
  LQTYStaking,
  MultiTroveGetter,
  PriceFeed,
  PriceFeedTestnet,
  SortedTroves,
  StabilityPool,
  GasPool,
  Unipool,
  ERC20Mock,
  IERC20,
  PoolAddressesRegistrar,
  ReferralCenter,
  Multicall,
  RedemptionManager,
  BorrowerOperationsETH,
  IWETH9,
  AggregatorV3Interface,
  TellorCaller
} from "../types";

import { EthersProvider, EthersSigner } from "./types";
import { Event } from "@ethersproject/contracts";

export interface _TypedLogDescription<T> extends Omit<LogDescription, "args"> {
  args: T;
}

type BucketOfFunctions = Record<string, (...args: unknown[]) => never>;

// Removes unsafe index signatures from an Ethers contract type
export type _TypeSafeContract<T> = Pick<
  T,
  {
    [P in keyof T]: BucketOfFunctions extends T[P] ? never : P;
  } extends {
    [_ in keyof T]: infer U;
  }
    ? U
    : never
>;

type EstimatedContractFunction<R = unknown, A extends unknown[] = unknown[], O = Overrides> = (
  overrides: O,
  adjustGas: (gas: BigNumber) => BigNumber,
  ...args: A
) => Promise<R>;

type CallOverridesArg = [overrides?: CallOverrides];

type TypedContract<T extends Contract, U, V> = _TypeSafeContract<T> &
  U & {
    address: string;
  } & {
    interface: Interface;
  } & {
    [P in keyof V]: V[P] extends (...args: infer A) => unknown
      ? (...args: A) => Promise<ContractTransaction>
      : never;
  } & {
    readonly callStatic: {
      [P in keyof V]: V[P] extends (...args: [...infer A, never]) => infer R
        ? (...args: [...A, ...CallOverridesArg]) => R
        : never;
    };

    readonly estimateGas: {
      [P in keyof V]: V[P] extends (...args: infer A) => unknown
        ? (...args: A) => Promise<BigNumber>
        : never;
    };

    readonly populateTransaction: {
      [P in keyof V]: V[P] extends (...args: infer A) => unknown
        ? (...args: A) => Promise<PopulatedTransaction>
        : never;
    };

    readonly estimateAndPopulate: {
      [P in keyof V]: V[P] extends (...args: [...infer A, infer O | undefined]) => unknown
        ? EstimatedContractFunction<PopulatedTransaction, A, O>
        : never;
    };

    on(event: EventFilter | string, listener: Listener): T;
    removeListener(eventName: EventFilter | string, listener: Listener): T;
    queryFilter(
      eventFilter: EventFilter | string,
      fromBlock?: BlockTag,
      toBlock?: BlockTag
    ): Promise<Array<Event>>;
  };

const buildEstimatedFunctions = <T>(
  estimateFunctions: Record<string, ContractFunction<BigNumber>>,
  functions: Record<string, ContractFunction<T>>
): Record<string, EstimatedContractFunction<T>> =>
  Object.fromEntries(
    Object.keys(estimateFunctions).map(functionName => [
      functionName,
      async (overrides, adjustEstimate, ...args) => {
        if (overrides.gasLimit === undefined) {
          const estimatedGas = await estimateFunctions[functionName](...args, overrides);

          overrides = {
            ...overrides,
            gasLimit: adjustEstimate(estimatedGas)
          };
        }

        return functions[functionName](...args, overrides);
      }
    ])
  );

export class _LiquityContract extends Contract {
  readonly estimateAndPopulate: Record<string, EstimatedContractFunction<PopulatedTransaction>>;

  constructor(
    addressOrName: string,
    contractInterface: ContractInterface,
    signerOrProvider?: EthersSigner | EthersProvider
  ) {
    super(addressOrName, contractInterface, signerOrProvider);

    // this.estimateAndCall = buildEstimatedFunctions(this.estimateGas, this);
    this.estimateAndPopulate = buildEstimatedFunctions(this.estimateGas, this.populateTransaction);
  }

  extractEvents(logs: Log[], name: string): _TypedLogDescription<unknown>[] {
    return logs
      .filter(log => log.address === this.address)
      .map(log => this.interface.parseLog(log))
      .filter(e => e.name === name);
  }
}

/** @internal */
export type _TypedLiquityContract<T = unknown, U = unknown> = TypedContract<_LiquityContract, T, U>;

export interface _LiquitySharedContracts {
  communityIssuance: CommunityIssuance;
  lqtyToken: LQTYToken;
  lqtyStaking: LQTYStaking;
  lockupContractFactory: LockupContractFactory;
  referralCenter: ReferralCenter;
  poolAddressesRegistrar: PoolAddressesRegistrar;
  multicall: Multicall;
}
/** @internal */
export interface _LiquityPoolContracts {
  activePool: ActivePool;
  unipool: Unipool;
  uniToken: IERC20 | ERC20Mock;
  borrowerOperations: BorrowerOperations | BorrowerOperationsETH;
  troveManager: TroveManager;
  collSurplusPool: CollSurplusPool;
  defaultPool: DefaultPool;
  hintHelpers: HintHelpers;
  multiTroveGetter: MultiTroveGetter;
  priceFeed: PriceFeed | PriceFeedTestnet;
  sortedTroves: SortedTroves;
  gasPool: GasPool;
  collateralToken: IERC20 | IWETH9;
  borrowingToken: LUSDToken;
  stabilityPool: StabilityPool;
  redemptionManager: RedemptionManager;
  // tellorCaller?: TellorCaller;
}

export interface _LiquityBorrowingOptionContracts {
  unipool: Unipool;
  uniToken: IERC20 | ERC20Mock;
  borrowingToken: LUSDToken;
  stabilityPool: StabilityPool;
  redemptionManager: RedemptionManager;
}

export interface _LiquityContracts {
  sharedContracts: _LiquitySharedContracts;
  borrowingOptionContracts: Record<string, _LiquityBorrowingOptionContracts>;
  poolContracts: Record<string, _LiquityPoolContracts>;
  feedContracts: _LiquityPriceFeedsContracts;
}

/** @internal */
export const _priceFeedIsTestnet = (
  priceFeed: PriceFeed | PriceFeedTestnet
): priceFeed is PriceFeedTestnet => "setPrice" in priceFeed;

export const _isBorrowerOperationsETH = (
  bo: BorrowerOperations | BorrowerOperationsETH
): bo is BorrowerOperationsETH => "addColl(address,address)" in bo;

/** @internal */
export const _uniTokenIsMock = (uniToken: IERC20 | ERC20Mock): uniToken is ERC20Mock =>
  "mint" in uniToken;

type LiquitySharedContractsKey = keyof _LiquitySharedContracts;
type LiquityPoolContractsKey = keyof _LiquityPoolContracts;

/** @internal */
export type _LiquitySharedContractAddresses = Record<LiquitySharedContractsKey, string>;

/** @internal */
export type _LiquityPoolContractAddresses = Record<LiquityPoolContractsKey, string>;

type LiquitySharedContractAbis = Record<LiquitySharedContractsKey, JsonFragment[]>;
type LiquityPoolContractAbis = Record<LiquityPoolContractsKey, JsonFragment[]>;

const getPoolContractsAbi = (
  priceFeedIsTestnet: boolean,
  uniTokenIsMock: boolean,
  isPoolWETH: boolean
): LiquityPoolContractAbis => ({
  activePool: activePoolAbi,
  borrowerOperations: isPoolWETH ? borrowerOperationsETHAbi: borrowerOperationsAbi,
  troveManager: troveManagerAbi,
  defaultPool: defaultPoolAbi,
  hintHelpers: hintHelpersAbi,
  // tellorCaller: tellorCallerAbi,
  multiTroveGetter: multiTroveGetterAbi,
  priceFeed: priceFeedIsTestnet ? priceFeedTestnetAbi : priceFeedAbi,
  sortedTroves: sortedTrovesAbi,
  gasPool: gasPoolAbi,
  collSurplusPool: collSurplusPoolAbi,
  collateralToken: isPoolWETH ? iWETH9Abi : iERC20Abi,
  borrowingToken: lusdTokenAbi,
  stabilityPool: stabilityPoolAbi,
  unipool: unipoolAbi,
  redemptionManager,
  uniToken: uniTokenIsMock ? erc20MockAbi : iERC20Abi
});

const getSharedContractsAbi = (): LiquitySharedContractAbis => ({
  communityIssuance: communityIssuanceAbi,
  lqtyToken: lqtyTokenAbi,
  lqtyStaking: lqtyStakingAbi,
  lockupContractFactory: lockupContractFactoryAbi,
  poolAddressesRegistrar: poolAddressesRegistrar,
  referralCenter: referralCenter,
  multicall
});

const mapLiquityContracts = <
  TKeys extends LiquitySharedContractsKey | LiquityPoolContractsKey,
  T,
  U
>(
  contracts: Record<TKeys, T>,
  f: (t: T, key: TKeys) => U
) =>
  Object.fromEntries(
    Object.entries(contracts)?.map(([key, t]) => [key, f(t as T, key as TKeys)])
  ) as Record<TKeys, U>;

/** @internal */
export interface _LiquityDeploymentJSON {
  readonly chainId: number;
  readonly _isDev: boolean;
  readonly config: {
    readonly referralsConfig: _LiquityDeploymentConfigJSON["sharedContractsConfig"]["referralCenter"];
    readonly lqtyConfig: _LiquityDeploymentConfigJSON["sharedContractsConfig"]["lqty"];
  };
  readonly assetToUsdPriceFeeds: _LiquityDeploymentConfigJSON["assetToUsdPriceFeeds"];
  readonly sharedContracts: {
    version: string;
    readonly deploymentDate: number;
    readonly startBlock: number;
    readonly addresses: _LiquitySharedContractAddresses;
  };
  readonly pools: Record<
    string,
    {
      version: string;

      readonly liquidityMiningLQTYRewardRate: string;
      readonly issuanceFactor: string;
      readonly totalStabilityPoolLQTYReward: string;
      readonly isPriceOracleReversed: boolean;
      readonly isWETH: boolean;
      readonly _uniTokenIsMock: boolean;
      readonly startBlock: number;
      readonly deploymentDate: number;
      readonly bootstrapPeriod: number;
      readonly _priceFeedIsTestnet: boolean;
      readonly config: _LiquityPoolDeploymentConfigJSON;
      readonly addresses: _LiquityPoolContractAddresses;
    }
  >;
}

export interface _LiquityPriceFeedConfigJSON {
  address: string;
  decimals: number;
}

export type _LiquityPriceFeedsContracts = Record<
  string,
  {
    feed: AggregatorV3Interface;
    decimals: number;
  }
>;

export interface _LiquityDeploymentConfigJSON {
  assetPools: Record<string, _LiquityPoolDeploymentConfigJSON>;
  borrowingOptions: Record<string, _LiquityBorrowingOptionConfigJSON>;
  assetToUsdPriceFeeds: Record<string, _LiquityPriceFeedConfigJSON>;
  sharedContractsConfig: {
    referralCenter: {
      referrerPartPercentage: string;
    };
    lqty: {
      bountyAddress: string;
      multisigAddress: string;
    };
  };
}

export interface _LiquityBorrowingOptionConfigJSON {
  name: string;
  symbol: string;
  stabilityPool: string;
  redemptionManager: string;
  lqtyIssuance: {
    LQTYSupplyCap: string;
    issuanceFactor: string;
  };
}

export interface _LiquityPoolDeploymentConfigJSON {
  poolConfig: {
    collateralToken: string;
    collateralTokenDecimals?: number;
    isWETH: boolean
  };
  priceFeed?: { 
    hasReversedOracle: boolean,
    tellorRequestId: string,
    chainLinkOracle?: string;
    tellorMaster?: string;
  },
  referralsConfig: {
    troveManager: {
      referralsPartPercentage: string;
    };
    borrowerOperations: {
      referralsPartPercentage: string;
    };
  };
  troveManagerConfig: {
    bootstrapPeriod: string;
  };
  baseConfig: {
    mcr: string;
    ccr: string;
    lusdGasCompensation: string;
    minNetDebt: string;
    borrowingFeeFloor: string;
  };
}

export class DeploymentCacheWithUpdate  {
  constructor(
    readonly cache: DeploymentCache,
    private readonly _save: (cache: DeploymentCache) => void,
    private readonly _read: () => DeploymentCache,
  ) { }

  public save() {
    this._save(this.cache);
  }

  public read() {
    return new DeploymentCacheWithUpdate(this._read(), this._save, this._read);
  }
}

export type Nullable<T> = {
  [P in keyof T]: T[P] | null | undefined;
};

export interface DeploymentCache { 
  networkName: string;
  sharedContracts: { 
    config: _LiquityDeploymentConfigJSON['sharedContractsConfig'],
    startBlock?: number;
    addresses: Nullable<_LiquityDeploymentJSON['sharedContracts']['addresses']>
  },
  poolContracts: Record<string,{ 
    startBlock?: number;
    config?: _LiquityDeploymentConfigJSON['assetPools'][string],
    addresses: Nullable<_LiquityDeploymentJSON['pools'][string]['addresses']>
  }>,
}

const mapTypedContractFunc = <TAbi extends Record<string, ContractInterface>>(
  address: string,
  key: keyof TAbi,
  abis: TAbi,
  signerOrProvider: EthersSigner | EthersProvider
) => {
  return new _LiquityContract(address, abis[key], signerOrProvider) as _TypedLiquityContract;
};

export const _connectToSP = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): StabilityPool => {
  return new _LiquityContract(
    address,
    stabilityPoolAbi,
    signerOrProvider
  ) as _TypedLiquityContract as StabilityPool;
};

export const _connectToRM = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): RedemptionManager => {
  return new _LiquityContract(
    address,
    redemptionManager,
    signerOrProvider
  ) as _TypedLiquityContract as RedemptionManager;
};


export const _connectToPriceFeed = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): PriceFeed => {
  return new _LiquityContract(
    address,
    priceFeedAbi,
    signerOrProvider
  ) as _TypedLiquityContract as PriceFeed;
};

export const _connectToHintHelpers = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): HintHelpers => {
  return new _LiquityContract(
    address,
    hintHelpersAbi,
    signerOrProvider
  ) as _TypedLiquityContract as HintHelpers;
};


export const _connectToSortedTroves = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): SortedTroves => {
  return new _LiquityContract(
    address,
    sortedTrovesAbi,
    signerOrProvider
  ) as _TypedLiquityContract as SortedTroves;
};

export const _connectToTM = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): TroveManager => {
  return new _LiquityContract(
    address,
    troveManagerAbi,
    signerOrProvider
  ) as _TypedLiquityContract as TroveManager;
};

export const _connectToBT = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): LUSDToken => {
  return new _LiquityContract(
    address,
    lusdTokenAbi,
    signerOrProvider
  ) as _TypedLiquityContract as LUSDToken;
};

export const _connectToPoolRegistrar = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): PoolAddressesRegistrar => {
  const abis = getSharedContractsAbi();
  return new _LiquityContract(
    address,
    abis["poolAddressesRegistrar"],
    signerOrProvider
  ) as _TypedLiquityContract as PoolAddressesRegistrar;
};

export const _connectToCollateralToken = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): IERC20 => {
  const abis = getPoolContractsAbi(false, false, false);
  return new _LiquityContract(
    address,
    abis["collateralToken"],
    signerOrProvider
  ) as _TypedLiquityContract as IERC20;
};

export const _connectToBorrowingToken = (
  address: string,
  signerOrProvider: EthersSigner | EthersProvider
): IERC20 => {
  const abis = getPoolContractsAbi(false, false, false);
  return new _LiquityContract(
    address,
    abis["borrowingToken"],
    signerOrProvider
  ) as _TypedLiquityContract as IERC20;
};

/** @internal */
export const _connectToPriceFeeds = (
  signerOrProvider: EthersSigner | EthersProvider,
  feeds: _LiquityDeploymentJSON["assetToUsdPriceFeeds"]
): _LiquityPriceFeedsContracts => {
  return Object.fromEntries(
    Object.entries(feeds).map(([key, val]) => {
      console.log([key, val]);
      return [
        key,
        {
          feed: new _LiquityContract(
            val.address,
            aggregatorV3InterfaceAbi,
            signerOrProvider
          ) as _TypedLiquityContract,
          decimals: val.decimals
        } as _LiquityPriceFeedsContracts[string]
      ];
    })
  );
};

/** @internal */
export const _connectToSharedContracts = (
  signerOrProvider: EthersSigner | EthersProvider,
  sharedContracts: _LiquityDeploymentJSON["sharedContracts"]
): _LiquitySharedContracts => {
  const sharedContractsAbi = getSharedContractsAbi();

  return mapLiquityContracts(sharedContracts.addresses, (address, key) =>
    mapTypedContractFunc(address, key, sharedContractsAbi, signerOrProvider)
  ) as _LiquitySharedContracts;
};

/** @internal */
export const  _connectToPoolContracts = (
  signerOrProvider: EthersSigner | EthersProvider,
  pool: _LiquityDeploymentJSON["pools"][string]
): _LiquityPoolContracts => {
  const poolContractsAbi = getPoolContractsAbi(pool._priceFeedIsTestnet, false, pool.isWETH);

  return mapLiquityContracts(pool.addresses, (address, key) =>
    address ? mapTypedContractFunc(address, key, poolContractsAbi, signerOrProvider) : undefined
  ) as _LiquityPoolContracts;
};

export const _connectToBorrowingOptionContracts = (
  signerOrProvider: EthersSigner | EthersProvider,
  pool: _LiquityDeploymentJSON["pools"][string]
): _LiquityBorrowingOptionContracts => {
  const poolContractsAbi = getPoolContractsAbi(pool._priceFeedIsTestnet, false, pool.isWETH);

  const res = mapLiquityContracts(pool.addresses, (address, key) =>
    mapTypedContractFunc(address, key, poolContractsAbi, signerOrProvider)
  ) as _LiquityPoolContracts;

  return {
    unipool: res.unipool,
    uniToken: res.uniToken,
    stabilityPool: res.stabilityPool,
    borrowingToken: res.borrowingToken,
    redemptionManager: res.redemptionManager
  };
};

/** @internal */
export const _connectToContracts = (
  signerOrProvider: EthersSigner | EthersProvider,
  json: _LiquityDeploymentJSON
): _LiquityContracts => {
  return {
    sharedContracts: _connectToSharedContracts(signerOrProvider, json.sharedContracts),
    borrowingOptionContracts: Object.keys(json.pools).reduce((prev, key) => {
      const bOption = _splitPoolName(key).borrowingTokenName;
      return {
        ...prev,
        [bOption]: _connectToBorrowingOptionContracts(signerOrProvider, json.pools[key])
      };
    }, {}),
    poolContracts: Object.keys(json.pools).reduce((prev, key) => {
      return {
        ...prev,
        [key]: _connectToPoolContracts(signerOrProvider, json.pools[key])
      };
    }, {}),
    feedContracts: _connectToPriceFeeds(signerOrProvider, json.assetToUsdPriceFeeds)
  };
};

export const _splitPoolName = (poolName: string) => {
  const [collateralTokenName, borrowingTokenName] = poolName.split("/");
  return { collateralTokenName, borrowingTokenName };
};

export const _isPoolName = (poolName: string | undefined) => {
  return poolName?.split("/")?.length === 2;
};

export const _getPoolName = (collateralTokenName: string, borrowingTokenName: string) => {
  return `${collateralTokenName}/${borrowingTokenName}`;
};
