import { BigNumber } from "@ethersproject/bignumber";
import { Event } from "@ethersproject/contracts";

import {
  Decimal,
  ObservableLiquity,
  StabilityDeposit,
  Trove,
  TroveWithPendingRedistribution
} from "@liquity/lib-base";

import {
  _getContracts,
  _getPoolContracts,
  _getSharedContracts,
  _requireAddress
} from "./EthersLiquityConnection";
import { ReadableEthersLiquity } from "./ReadableEthersLiquity";
import { _getBorrowingOptionContracts } from "./EthersLiquityConnection";
import { _splitPoolName } from "./contracts";

const debouncingDelayMs = 50;

const debounce = (listener: (latestBlock: number) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
  let latestBlock = 0;

  return (...args: unknown[]) => {
    const event = args[args.length - 1] as Event;

    if (event.blockNumber !== undefined && event.blockNumber > latestBlock) {
      latestBlock = event.blockNumber;
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      listener(latestBlock);
      timeoutId = undefined;
    }, debouncingDelayMs);
  };
};

/** @alpha */
export class ObservableEthersLiquity implements ObservableLiquity {
  private readonly _readable: ReadableEthersLiquity;

  constructor(readable: ReadableEthersLiquity) {
    this._readable = readable;
  }

  watchTotalRedistributed(
    onTotalRedistributedChanged: (totalRedistributed: Trove) => void,
    poolName: string
  ): () => void {
    const { activePool, defaultPool } = _getPoolContracts(poolName, this._readable.connection);
    const etherSent = activePool.filters.EtherSent();

    const redistributionListener = debounce((blockTag: number) => {
      this._readable.getTotalRedistributed(poolName, { blockTag }).then(onTotalRedistributedChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      if (toAddress === defaultPool.address) {
        redistributionListener(event);
      }
    };

    activePool.on(etherSent, etherSentListener);
    activePool.on(etherSent, etherSentListener);

    return () => {
      activePool.removeListener(etherSent, etherSentListener);
    };
  }

  watchTroveWithoutRewards(
    onTroveChanged: (trove: TroveWithPendingRedistribution) => void,
    poolName: string,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { troveManager, borrowerOperations } = _getPoolContracts(
      poolName,
      this._readable.connection
    );
    const troveUpdatedByTroveManager = troveManager.filters.TroveUpdated(address);
    const troveUpdatedByBorrowerOperations = borrowerOperations.filters.TroveUpdated(address);

    const troveListener = debounce((blockTag: number) => {
      this._readable
        .getTroveBeforeRedistribution(poolName, address, { blockTag })
        .then(onTroveChanged);
    });

    troveManager.on(troveUpdatedByTroveManager, troveListener);
    borrowerOperations.on(troveUpdatedByBorrowerOperations, troveListener);

    return () => {
      troveManager.removeListener(troveUpdatedByTroveManager, troveListener);
      borrowerOperations.removeListener(troveUpdatedByBorrowerOperations, troveListener);
    };
  }

  watchNumberOfTroves(
    onNumberOfTrovesChanged: (numberOfTroves: number) => void,
    poolName: string
  ): () => void {
    const { troveManager } = _getPoolContracts(poolName, this._readable.connection);
    const { TroveUpdated } = troveManager.filters;
    const troveUpdated = TroveUpdated();

    const troveUpdatedListener = debounce((blockTag: number) => {
      this._readable.getNumberOfTroves(poolName, { blockTag }).then(onNumberOfTrovesChanged);
    });

    troveManager.on(troveUpdated, troveUpdatedListener);

    return () => {
      troveManager.removeListener(troveUpdated, troveUpdatedListener);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  watchPrice(onPriceChanged: (price: Decimal) => void, poolName: string): () => void {
    // TODO revisit
    // We no longer have our own PriceUpdated events. If we want to implement this in an event-based
    // manner, we'll need to listen to aggregator events directly. Or we could do polling.
    throw new Error("Method not implemented.");
  }

  watchTotal(onTotalChanged: (total: Trove) => void, poolName: string): () => void {
    const { troveManager } = _getPoolContracts(poolName, this._readable.connection);
    const { TroveUpdated } = troveManager.filters;
    const troveUpdated = TroveUpdated();

    const totalListener = debounce((blockTag: number) => {
      this._readable.getTotal(poolName, { blockTag }).then(onTotalChanged);
    });

    troveManager.on(troveUpdated, totalListener);

    return () => {
      troveManager.removeListener(troveUpdated, totalListener);
    };
  }

  watchStabilityDeposit(
    onStabilityDepositChanged: (stabilityDeposit: StabilityDeposit) => void,
    poolName: string,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { collateralTokenName, borrowingTokenName } = _splitPoolName(poolName);

    const { stabilityPool } = _getBorrowingOptionContracts(borrowingTokenName, this._readable.connection);
    const { activePool } = _getPoolContracts( poolName, this._readable.connection);
    const { UserDepositChanged } = stabilityPool.filters;
    const { EtherSent } = activePool.filters;

    const userDepositChanged = UserDepositChanged(address);
    const etherSent = EtherSent();

    const depositListener = debounce((blockTag: number) => {
      this._readable
        .getStabilityDeposit(borrowingTokenName, address, { blockTag })
        .then(onStabilityDepositChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      if (toAddress === stabilityPool.address) {
        // Liquidation while Stability Pool has some deposits
        // There may be new gains
        depositListener(event);
      }
    };

    stabilityPool.on(userDepositChanged, depositListener);
    activePool.on(etherSent, etherSentListener);

    return () => {
      stabilityPool.removeListener(userDepositChanged, depositListener);
      activePool.removeListener(etherSent, etherSentListener);
    };
  }

  watchLUSDInStabilityPool(
    onLUSDInStabilityPoolChanged: (lusdInStabilityPool: Decimal) => void,
    borrowingTokenName: string
  ): () => void {
    const { borrowingToken, stabilityPool } = _getBorrowingOptionContracts(borrowingTokenName, this._readable.connection);

    const { Transfer } = borrowingToken.filters;

    const transferLUSDFromStabilityPool = Transfer(stabilityPool.address);
    const transferLUSDToStabilityPool = Transfer(null, stabilityPool.address);

    const stabilityPoolLUSDFilters = [transferLUSDFromStabilityPool, transferLUSDToStabilityPool];

    const stabilityPoolLUSDListener = debounce((blockTag: number) => {
      this._readable
        .getLUSDInStabilityPool(borrowingTokenName, { blockTag })
        .then(onLUSDInStabilityPoolChanged);
    });

    stabilityPoolLUSDFilters.forEach(filter => borrowingToken.on(filter, stabilityPoolLUSDListener));

    return () =>
      stabilityPoolLUSDFilters.forEach(filter =>
        borrowingToken.removeListener(filter, stabilityPoolLUSDListener)
      );
  }

  watchLUSDBalance(
    onLUSDBalanceChanged: (balance: Decimal) => void,
    borrowingTokenName: string,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { borrowingToken } = _getBorrowingOptionContracts(borrowingTokenName, this._readable.connection);
    const { Transfer } = borrowingToken.filters;
    const transferLUSDFromUser = Transfer(address);
    const transferLUSDToUser = Transfer(null, address);

    const lusdTransferFilters = [transferLUSDFromUser, transferLUSDToUser];

    const lusdTransferListener = debounce((blockTag: number) => {
      this._readable.getLUSDBalance(borrowingTokenName, address, { blockTag }).then(onLUSDBalanceChanged);
    });

    lusdTransferFilters.forEach(filter => borrowingToken.on(filter, lusdTransferListener));

    return () =>
      lusdTransferFilters.forEach(filter => borrowingToken.removeListener(filter, lusdTransferListener));
  }
}
