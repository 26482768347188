import React, { FC, useState } from "react";

import { PaginatorDot } from "./Dotted/PaginatorDot";
import { usePagination } from "./hooks";
import { PageDesktop } from "./Numeric/PageDesktop";
import { PaginatorArrow, PaginatorContainer, PaginatorPagesContainer } from "./styles";
import { PaginatorPropsType } from "./types";

import LeftArrow from "../../../assets/pagination/LeftArrow.svg";
import RightArrow from "../../../assets/pagination/RightArrow.svg";
import LeftArrowDisabled from "../../../assets/pagination/LeftArrowDisabled.svg";
import RightArrowDisabled from "../../../assets/pagination/RightArrowDisabled.svg";
import { Box, Flex } from "@chakra-ui/react";
import { useMatchBreakpoints } from "../../../hooks/useMatchBreakpoints";

export const Paginator: FC<PaginatorPropsType> = ({
  type,
  pageSize,
  totalCount,
  currentPage,
  onPageChange
}) => {
  const pagesCount = Math.ceil(totalCount / pageSize);
  const pages = usePagination({
    totalCount,
    pageSize,
    currentPage
  });
  const [gotoPage, setGotoPage] = useState<number>(1);
  const { isMobileOrTablet } = useMatchBreakpoints();
  return (
    <Flex
      direction="column"
      px="16px"
      w="100%"
      alignItems="center"
      gap="24px"
      position="relative"
      py={isMobileOrTablet ? "16px" : "37px"}
    >
      {!isMobileOrTablet && (
        <Box
          position="absolute"
          bg="rgba(255,255,255,0.4)"
          top={0}
          zIndex={-1}
          left="-500%"
          w="1000%"
          h="100%"
        ></Box>
      )}

      <PaginatorContainer style={{ marginTop: isMobileOrTablet ? "24px" : "0" }}>
        {type === "num" && (
          <PaginatorArrow
            src={currentPage === 1 ? LeftArrowDisabled : LeftArrow}
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage === 1) return;
              onPageChange(currentPage - 1);
            }}
          />
        )}
        <PaginatorPagesContainer>
          {type === "num" &&
            pages &&
            pages.map((pageNumber, index) => (
              <PageDesktop
                key={index}
                currentPage={currentPage}
                pageNumber={pageNumber}
                onPageClick={() => {
                  onPageChange(+pageNumber);
                }}
              />
            ))}
          {type === "dot" &&
            pages &&
            pages.map(pageNumber => (
              <PaginatorDot
                key={pageNumber}
                active={currentPage === pageNumber}
                onClick={() => {
                  onPageChange(+pageNumber);
                }}
              />
            ))}
        </PaginatorPagesContainer>
        {type === "num" && (
          <PaginatorArrow
            src={currentPage === pagesCount ? RightArrowDisabled : RightArrow}
            disabled={currentPage === pagesCount}
            onClick={() => {
              if (currentPage === pagesCount) return;
              onPageChange(currentPage + 1);
            }}
          />
        )}
      </PaginatorContainer>
    </Flex>
  );
};
