import React, {Fragment, useEffect, useMemo, useState} from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useClipboard,
  useToast
} from "@chakra-ui/react";
import { useMatchBreakpoints } from "../hooks/useMatchBreakpoints";
import CopyWhite from "../assets/referral/copy-white.svg";
import CopyGray from "../assets/referral/copy-gray.svg";
import ReferralIcon from "../assets/landing/referral.svg";
import ReferralSmallIcon from "../assets/landing/referral-small.svg";
import {
  Decimal,
  LiquityStoreState,
  PoolInfo,
  ReferralsType,
  PendingRewardsType,
  ReferralWithDateType
} from "@liquity/lib-base";
import { constants } from "ethers";
import { shortenAddress } from "../helpers/formatters";
import { useAccount } from "wagmi";
import { useLiquitySelector } from "@liquity/lib-react";
import {
  _getPoolBaseConfig,
  _getPoolsContracts,
  _getSharedContracts,
  decimalify,
  EthersLiquityConnection,
  toBase18
} from "@liquity/lib-ethers";
import { useLiquity } from "../hooks/LiquityContext";
import { useTransactionFunction } from "../hooks/useTransactionFunction";
import { Button } from "../components/Button";
import {
  _getBorrowingOptions,
  _getCollateralOptions
} from "@liquity/lib-ethers/dist/src/EthersLiquityConnection";
import { _isPoolName, _splitPoolName } from "@liquity/lib-ethers/dist/src/contracts";
import { ReferralAssetMenu } from "../components/Referral/ReferralAssetMenu";
import { matchErrorMessage } from "../helpers/regex";
import { format } from "date-fns"

type ReferralItem = {
  wallet: string;
  earnings: Decimal;
  expireDate: Date;
};

const AddressCopyOnMobile: React.FC<{ address: string }> = ({ address }) => {
  const { onCopy } = useClipboard(address);
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex alignItems="center" justifyContent="center" gap="15px">
      <Text color="text.200" fontSize="16px">
        {isMobileOrTablet ? shortenAddress(address, 4) : address}
      </Text>
      {isMobileOrTablet && <Image src={CopyGray} onClick={onCopy} cursor="pointer" />}
    </Flex>
  );
};

const select = ({
  referrer,
  referrals,
  allPoolsInfo,
  claimedEarnings,
  pendingEarningAmount,
  referrerEarnings
}: LiquityStoreState) => ({
  referrer,
  referrals,
  allPoolsInfo,
  claimedEarnings,
  pendingEarningAmount,
  referrerEarnings
});

const prettifyReferrals = (
  referrals: ReferralWithDateType,
  connection: EthersLiquityConnection,
  allPoolsInfo: Record<string, PoolInfo>
) => {
  const bOptions = _getBorrowingOptions(connection);

  const pools = _getPoolsContracts(connection);
  
  return Object.entries(referrals).map(([referral, {expireDate, total}]) => ({
    wallet: referral,
    expireDate,
    earnings: Object.entries(total)
      .map(([token, earning]) => {
        const foundBOption = bOptions.find(
          v => v.tokenAddress.toLowerCase() === token.toLowerCase()
        );
        if (foundBOption && foundBOption.optionName.toLowerCase() === "usdz") {
          return Decimal.fromBigNumberString(earning.toString());
        }
        // FIXME: one token can be used with different borrowing options
        const poolName = Object.keys(pools).find(
          pool => pools[pool].collateralToken.address.toLowerCase() === token.toLowerCase()
        );
        if (poolName) {
          return allPoolsInfo[poolName].price
            .get()
            .mul(Decimal.fromBigNumberString(earning.toString()));
        }
        return Decimal.from(0);
      })
      .reduce((a, b) => {
        return a.add(b);
      }, Decimal.ZERO)
  }));
};

const prettifyForOne = (
  connection: EthersLiquityConnection,
  referrals: PendingRewardsType,
  allPoolsInfo: Record<string, PoolInfo>,
  uniqueTokens?: string[],
  setUniqueTokens?: (v: string[]) => void
): Decimal => {
  const bOptions = _getBorrowingOptions(connection);
  const pools = _getPoolsContracts(connection);
  return Object.entries(referrals)
    .map(([poolName, earning]) => {
      let collateralToken: string | undefined = undefined;
      let borrowingToken: string | undefined = undefined;

      if (_isPoolName(poolName)) {
        const splitName = _splitPoolName(poolName);
        const bOption = bOptions.find(v => v.optionName === splitName.borrowingTokenName);

        collateralToken = pools[poolName].collateralToken.address;
        if (bOption) borrowingToken = bOption.tokenAddress;
      } else {
        const bOption = bOptions.find(v => v.optionName === poolName);
        if (bOption) borrowingToken = bOption.tokenAddress;
      }

      if (uniqueTokens && setUniqueTokens) {
        if (collateralToken && !uniqueTokens.find(t => t === collateralToken)) {
          setUniqueTokens([...uniqueTokens, collateralToken]);
        }

        if (borrowingToken && !uniqueTokens.find(t => t === borrowingToken)) {
          setUniqueTokens([...uniqueTokens, borrowingToken]);
        }
      }

      if (!collateralToken && borrowingToken?.toLowerCase() === "usdz") {
        return earning;
      } else if (!collateralToken) {
        // TODO: calculate borrowing token price in USD here
        return Decimal.ZERO;
      }

      if (poolName) {
        return allPoolsInfo[poolName].price.get().mul(earning);
      }
      return Decimal.ZERO;
    })
    .reduce((a, b) => a.add(b), Decimal.ZERO);
};

type EarningsByToken = { [pool: string]: { total: Decimal; pending: Decimal; totalValue: Decimal } };

const getEarningsByToken = (
  allPoolsInfo: Record<string, PoolInfo>,
  claimedReferrals: ReferralsType,
  pendingEarning: PendingRewardsType,
  connection: EthersLiquityConnection,
  setUniqueTokens: (v: string[]) => void
): EarningsByToken => {
  const pools = _getPoolsContracts(connection);
  const borrowingTokens = _getBorrowingOptions(connection);
  const result: EarningsByToken = [
    "USDZ",
    "ETHZ",
    ...Object.keys(pools).map(pool => _splitPoolName(pool).collateralTokenName)
  ].reduce((a, b) => {
    a[b] = {
      pending: pendingEarning[b],
      total: Decimal.ZERO,
      totalValue: Decimal.ZERO
    };
    return a;
  }, {} as EarningsByToken);

  const unique = Object.keys(result)
    .map(token => {
      const t = token.toLowerCase();
      const address =
        Object.entries(pools).find(
          ([name, pool]) => _splitPoolName(name).collateralTokenName.toLowerCase() === t
        )?.[1].collateralToken.address ??
        borrowingTokens.find(({ optionName }) => optionName.toLowerCase() === t)?.tokenAddress;
      if (address && !pendingEarning[token].isZero) {
        return address;
      }

      return "";
    })
    .filter(address => !!address);
  setUniqueTokens(unique);

  Object.entries(claimedReferrals).map(([referral, earnedObj]) => {
    Object.entries(earnedObj).map(([tokenAddress, earned]) => {
      const poolName = Object.keys(pools).find(
        pool => pools[pool].collateralToken.address.toLowerCase() === tokenAddress.toLowerCase()
      );
      if (poolName) {
        const decimals = _getPoolBaseConfig(poolName, connection).collateralTokenDecimals;
        const name = _splitPoolName(poolName).collateralTokenName;
        result[name].total = result[name].total.add(decimalify(toBase18(earned, 18)));
        result[name].totalValue = result[name].totalValue.add(
          allPoolsInfo[poolName].price.get().mul(decimalify(toBase18(earned, 18)))
        );
      } else {
        const name =
          borrowingTokens.find(t => t.tokenAddress === tokenAddress)?.optionName ?? "USDZ";

        result[name].total = result[name].total.add(decimalify(earned));
        result[name].totalValue = result[name].total;
      }
    });
  });

  return result;
};

export const Referral = () => {
  const { address } = useAccount();
  const { liquity } = useLiquity();
  const { isMobileOrTablet } = useMatchBreakpoints();
  const [referralLink, setReferralLink] = useState<string>(window.location.origin);
  const {
    referrer: referred,
    referrals: onChainReferrals,
    allPoolsInfo,
    claimedEarnings,
    pendingEarningAmount,
    referrerEarnings
  } = useLiquitySelector(select);
  const [referrals, setReferrals] = useState<ReferralItem[]>([]);
  const { onCopy, setValue, hasCopied } = useClipboard(referralLink);
  const [uniqueTokens, setUniqueTokens] = useState<string[]>([]);
  const [referrerUsdzEarned, setReferrerUsdzEarned] = useState<Decimal>(Decimal.ZERO);
  const [earningsByToken, setEarningsByToken] = useState<EarningsByToken>({});
  const [selectedEarningByToken, setSelectedEarningByToken] = useState<string>("USDZ");
  const toast = useToast();
  const date = useMemo(() => new Date(), []);

  useEffect(() => {
    (async () => {
      const referrals = prettifyReferrals(
        onChainReferrals.referral,
        liquity.connection,
        allPoolsInfo
      );
      setReferrals(referrals);
      setEarningsByToken(
        getEarningsByToken(
          allPoolsInfo,
          claimedEarnings.referral,
          pendingEarningAmount.referral,
          liquity.connection,
          setUniqueTokens
        )
      );
      setReferrerUsdzEarned(
        prettifyForOne(liquity.connection, referrerEarnings.referral, allPoolsInfo)
      );
    })();
  }, [
    onChainReferrals.referral,
    claimedEarnings.referral,
    pendingEarningAmount.referral,
    referrerEarnings.referral
  ]);

  useEffect(() => {
    if (address) {
      setReferralLink(`${window.location.origin}/?ref=${btoa(address)}`);
    }
  }, [address]);

  useEffect(() => {
    setValue(referralLink);
  }, [setValue, referralLink]);

  const id = "claim";

  const [claimRewards, state, setState] = useTransactionFunction(
    id,
    liquity.send.claimRewards.bind(liquity.send, address ?? "", uniqueTokens, false)
  );

  useEffect(() => {
    if (state.type === "failed" && state.id === id) {
      const matchedError = matchErrorMessage(state.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setState({ type: "idle" });
    }
    if (state.type === "confirmed" && state.id === id) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Claim confirmed.",
        description: "Rewards were added to your wallet.",
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setState({ type: "idle" });
    }
  }, [state]);

  return (
    <Flex direction="column" alignItems="center" w="100%">
      <Flex
        direction="column"
        alignItems="center"
        w="100%"
        bg="rgba(255,255,255,0.4)"
        pt="56px"
        pb="56px"
      >
        <Text color="text.100" fontWeight={600} fontSize="18px">
          Refer a Friend
        </Text>
        <Text color="text.200" fontSize="16px" textAlign="center" mt="16px" mx="16px">
          Refer someone to us and you both get rewarded with 5% of all fees they generate on Zero
          Protocol for one year.
        </Text>
        <Flex
          w="100%"
          px={isMobileOrTablet ? "16px" : "80px"}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            mt="40px"
            alignItems="center"
            justifyContent="center"
            py="32px"
            borderRadius="20px"
            templateColumns={isMobileOrTablet ? "1fr" : "1fr auto 1fr"}
            gap="24px"
          >
            {!isMobileOrTablet && (
              <GridItem>
                <Image src={ReferralIcon} alt="referral" />
              </GridItem>
            )}
            <GridItem>
              <Flex direction="column" gap="24px" alignItems="center">
                {isMobileOrTablet ? (
                  <Flex alignItems="center" gap="8px">
                    <Image src={ReferralSmallIcon} alt="referral-small" />
                    <Text color="text.100" fontSize="18px" fontWeight={600}>
                      Your Zero Loans referral link
                    </Text>
                  </Flex>
                ) : (
                  <Text color="text.100" fontSize="18px" fontWeight={600}>
                    Your Zero Loans referral link
                  </Text>
                )}

                <InputGroup maxW="750px">
                  <Input
                    name="referral"
                    h="48px"
                    value={referralLink}
                    borderRadius="16px"
                    borderColor="#587CFF"
                    fontSize="16px"
                    pl="24px"
                    py="14px"
                    pr="130px"
                    mx="16px"
                    readOnly
                  />
                  <InputRightElement>
                    <Flex
                      minW="132px"
                      minH="48px"
                      bg="#587CFF"
                      borderRadius="16px"
                      alignItems="center"
                      justifyContent="center"
                      gap="8px"
                      color="white"
                      mr="125px"
                      mt="8px"
                      cursor="pointer"
                      transition="all .2s ease-in-out"
                      _hover={{
                        backgroundColor: "#7793FC"
                      }}
                      _active={{
                        backgroundColor: "#3761FA"
                      }}
                      onClick={onCopy}
                    >
                      {hasCopied ? "Copied" : "Copy"} <Image src={CopyWhite} alt="copy" />
                    </Flex>
                  </InputRightElement>
                </InputGroup>
                <Text textAlign="center" color="text.200" fontSize="16px" maxW="760px" px="16px">
                  Once someone clicks your referral link and accepts then you will see them appear in
                  the “Accounts you have referred” below. Then you are all set, fee sharing is
                  active! Your link is reusable as many times as you like.
                </Text>
              </Flex>
            </GridItem>
            {!isMobileOrTablet && <GridItem />}
          </Grid>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        direction="column"
        alignItems="center"
        bg="rgba(255,255,255,0.4)"
        py="32px"
        mt="107px"
        px={isMobileOrTablet ? "16px" : "80px"}
        gap="32px"
      >
        <Flex
          w="100%"
          direction="column"
          maxW={isMobileOrTablet ? "100%" : "625px"}
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
          gap="16px"
          py="32px"
        >
          <Text color="text.100" fontSize="18px" fontWeight={600}>
            Referral Earnings
          </Text>
          <Grid w="100%" templateColumns={`repeat(${isMobileOrTablet ? 3 : 4}, 1fr)`} gap="16px">
            <GridItem>
              <Text w="100%" color="text.100" fontSize="18px" fontWeight={500} textAlign="center">
                Token
              </Text>
            </GridItem>
            <GridItem>
              <Text w="100%" color="text.100" fontSize="18px" fontWeight={500} textAlign="center">
                Total
              </Text>
            </GridItem>
            <GridItem>
              <Text w="100%" color="text.100" fontSize="18px" fontWeight={500} textAlign="center">
                Not Claimed
              </Text>
            </GridItem>
            {!isMobileOrTablet && (
              <GridItem>
                <Text w="100%" color="text.100" fontSize="18px" fontWeight={500} textAlign="center">
                  Total value
                </Text>
              </GridItem>
            )}
            <GridItem>
              <Flex
                alignItems="center"
                justifyContent="center"
                w="100%"
                color="text.200"
                fontSize="18px"
              >
                <ReferralAssetMenu
                  selectedToken={selectedEarningByToken}
                  setSelectedToken={setSelectedEarningByToken}
                  tokens={Object.keys(earningsByToken)}
                />
              </Flex>
            </GridItem>
            <GridItem display="flex" alignItems="center">
              <Text w="100%" color="text.200" fontSize="18px" textAlign="center">
                {earningsByToken[selectedEarningByToken]?.total.prettify(2)}
              </Text>
            </GridItem>
            <GridItem display="flex" alignItems="center">
              <Text w="100%" color="text.200" fontSize="18px" textAlign="center">
                {earningsByToken[selectedEarningByToken]?.pending.prettify(2)}
              </Text>
            </GridItem>
            <GridItem colSpan={isMobileOrTablet ? 3 : 1}  display="flex" alignItems="center">
              {isMobileOrTablet ? (
                <Flex w="100%" alignItems="center" justifyContent="center" gap="20px">
                  <Text color="text.100" fontSize="18px" fontWeight={500} textAlign="center">
                    Total value
                  </Text>
                  <Text color="text.200" fontSize="18px" textAlign="center">
                    ${earningsByToken[selectedEarningByToken]?.totalValue.prettify(2)}
                  </Text>
                </Flex>
              ) : (
                <Text w="100%" color="text.200" fontSize="18px" textAlign="center">
                  ${earningsByToken[selectedEarningByToken]?.totalValue.prettify(2)}
                </Text>
              )}
            </GridItem>
          </Grid>
        </Flex>
        <Flex h="100%" w="100%" alignItems="center" justifyContent="center" mt="-16px">
          {Object.values(earningsByToken).every(v => v.pending.isZero) ? (
            <Flex
              border="1px solid #DAD8D8"
              borderRadius="16px"
              px="24px"
              maxW="343px"
              w="100%"
              h="48px"
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
            >
              <Text color="text.200" fontSize="16px" fontWeight={500}>
                No claims
              </Text>
            </Flex>
          ) : (
            <Button
              borderRadius="16px"
              px="24px"
              py="14px"
              fontSize="16px"
              maxW="343px"
              w="100%"
              h="48px"
              fontWeight={500}
              onClick={() => {
                try {
                  if (Object.values(earningsByToken).every(v => v.pending.isZero)) {
                    throw new Error("You don't have tokens to claim.");
                  }
                  return claimRewards();
                } catch (e) {
                  toast({
                    variant: "subtle",
                    position: "bottom-right",
                    title: "Error occurred.",
                    description: e instanceof Error ? e.message : String(e),
                    status: "error",
                    duration: 9000,
                    isClosable: true
                  });
                }
              }}
              isDisabled={
                state.type === "waitingForApproval" || state.type === "waitingForConfirmation"
              }
            >
              {state.type === "waitingForApproval"
                ? "Confirm in Wallet"
                : state.type === "waitingForConfirmation"
                ? "Processing..."
                : "Claim"}
            </Button>
          )}
        </Flex>
        {referred &&
          referred !== constants.AddressZero &&
          referred.toLowerCase() !== "0x000000000000000000000000000000000000dead" && (
            <Flex
              w="100%"
              direction="column"
              maxW="750px"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
              gap="16px"
              py="32px"
            >
              <Text color="text.100" fontSize="20px" fontWeight={600}>
                Account that referred you
              </Text>
              <Grid w="100%" templateColumns="repeat(3, 1fr)" px="16px" gap="16px">
                <GridItem>
                  <Text
                    w="100%"
                    color="text.100"
                    fontSize="16px"
                    fontWeight={600}
                    textAlign="center"
                  >
                    Wallet
                  </Text>
                </GridItem>
                <GridItem>
                  <Text
                    w="100%"
                    color="text.100"
                    fontSize="16px"
                    fontWeight={600}
                    textAlign="center"
                  >
                    Referrer Earnings
                  </Text>
                </GridItem>
                <GridItem>
                  <Text
                      w="100%"
                      color="text.100"
                      fontSize="16px"
                      fontWeight={600}
                      textAlign="center"
                  >
                    Expires
                  </Text>
                </GridItem>
                <GridItem>
                  <AddressCopyOnMobile address={referred} />
                </GridItem>
                <GridItem>
                  <Text w="100%" color="text.200" fontSize="16px" textAlign="center">
                    ${referrerUsdzEarned.prettify(2)}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text w="100%" color="text.200" fontSize="16px" textAlign="center">
                    {format(referrerEarnings.expireDate, "yyyy-MM-dd")}
                  </Text>
                </GridItem>
              </Grid>
            </Flex>
          )}
        {referrals.length > 0 && (
          <Flex
            w="100%"
            direction="column"
            maxW="750px"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            gap="16px"
            py="32px"
          >
            <Text color="text.100" fontSize="20px" fontWeight={600}>
              Accounts you have referred
            </Text>
            <Grid w="100%" templateColumns="repeat(3, 1fr)" px="16px" gap="16px">
              <GridItem>
                <Text w="100%" color="text.100" fontSize="16px" fontWeight={600} textAlign="center">
                  Wallet
                </Text>
              </GridItem>
              <GridItem>
                <Text w="100%" color="text.100" fontSize="16px" fontWeight={600} textAlign="center">
                  Referral Earnings
                </Text>
              </GridItem>
              <GridItem>
                <Text w="100%" color="text.100" fontSize="16px" fontWeight={600} textAlign="center">
                  Expires
                </Text>
              </GridItem>
              {referrals.map(({ wallet, earnings, expireDate }) => (
                <Fragment key={wallet}>
                  <GridItem>
                    <AddressCopyOnMobile address={wallet}></AddressCopyOnMobile>
                  </GridItem>
                  <GridItem>
                    <Text w="100%" color="text.200" fontSize="16px" textAlign="center">
                      ${earnings.prettify(2)}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Text w="100%" color="text.200" fontSize="16px" textAlign="center">
                      {format(expireDate, "yyyy-MM-dd")}
                    </Text>
                  </GridItem>
                </Fragment>
              ))}
            </Grid>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
