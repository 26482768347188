import React from "react";
import { Flex } from "@chakra-ui/react";
import { LandingHeading } from "../components/LandingHeading";
import { LandingCards } from "../components/LandingCard";
import { LandingDescription } from "../components/LandingDescription";
import { LandingReferral } from "../components/LandingReferral";
import { LandingFaq } from "../components/LandingFaq";
import Bg from "../assets/background/bg.png";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const Landing: React.FC = () => {
  return (
    <Flex direction="column" height="100%" bgImage={Bg} bgSize="100% 100%" bgRepeat="no-repeat">
      <Header />
      <Flex direction="column">
        <LandingHeading />
        <LandingCards />
        <LandingDescription />
        <LandingReferral />
        <LandingFaq />
      </Flex>
      <Footer />
    </Flex>
  );
};
