import { BigNumber } from "@ethersproject/bignumber";

import { Decimal } from "@liquity/lib-base";
import { parseUnits } from "ethers/lib/utils";

const _convertToBase = (bigNumber: BigNumber, inDecimals: number, outDecimals: number): BigNumber => {
  return bigNumber.mul(parseUnits('1', outDecimals)).div(parseUnits('1', inDecimals));
}

export const numberify = (bigNumber: BigNumber): number => bigNumber.toNumber();

export const decimalify = (bigNumber: BigNumber): Decimal =>
  Decimal.fromBigNumberString(bigNumber.toHexString());

export const toBase18 = (bigNumber: BigNumber, inDecimals: number): BigNumber => {
  return _convertToBase(bigNumber, inDecimals, 18);
}

export const fromBase18 = (bigNumber: BigNumber, outDecimals: number): BigNumber => {
  return _convertToBase(bigNumber, 18, outDecimals);
}

export const panic = <T>(e: unknown): T => {
  throw e;
};

export type Resolved<T> = T extends Promise<infer U> ? U : T;
export type ResolvedValues<T> = { [P in keyof T]: Resolved<T[P]> };

export const promiseAllValues = <T extends Record<string, unknown>>(object: T): Promise<ResolvedValues<T>> => {
  const keys = Object.keys(object);

  return Promise.all(Object.values(object)).then(values =>
    Object.fromEntries(values.map((value, i) => [keys[i], value]))
  ) as Promise<ResolvedValues<T>>;
};
