import React, { useMemo, useRef, useState } from "react";
import { Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Decimal } from "@liquity/lib-base";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import DropdownIcon from "../../assets/icons/dropdown.svg";

export type EarnAssetMenuItem = {
  symbol: string;
  value: Decimal;
};

export type EarnAssetMenuProps = {
  items: EarnAssetMenuItem[];
};

const EarnAssetMenu: React.FC<EarnAssetMenuProps> = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { isMobileOrTablet } = useMatchBreakpoints();
  const selectedItem = useMemo(() => {
    return items[selectedIndex];
  }, [selectedIndex, items]);
  const [isOpened, setIsOpened] = useState(false);

  if (items.length === 0) {
    return null;
  }

  return (
    <Menu onOpen={() => setIsOpened(true)} onClose={() => setIsOpened(false)}>
      <MenuButton bg="white" zIndex={9} px={2} py={1} borderRadius="10px" border="1px solid #A1B6FF" _active={{
        outline: "1px solid #2684FF",
        outlineOffset: "0px"
      }}>
        <Flex gap="8px" alignItems="center">
          {isMobileOrTablet && <Image src={DropdownIcon} alt="indicator" style={{ rotate: isOpened ? "180deg" : "360deg" }} />}
          <span>
            {(() => (
              <>
                {selectedItem?.value.prettify(2)} {selectedItem?.symbol}
              </>
            ))()}
          </span>
          {!isMobileOrTablet && <Image src={DropdownIcon} alt="indicator" style={{ rotate: isOpened ? "180deg" : "360deg" }} />}
        </Flex>
      </MenuButton>
      <MenuList minW="80px" maxW="80px" ml="40px" p="20px" borderRadius="10px" zIndex={10}>
        {items.map(({ symbol }, i) => (
          <MenuItem
            zIndex={10}
            key={symbol}
            maxW="80px"
            w="100%"
            alignItems="center"
            justifyContent="center"
            fontSize="14px"
            color="text.300"
            onClick={() => setSelectedIndex(i)}
            mb="8px"
            _last={{
              mb: 0
            }}
            _hover={{
              color: "#FD7B1D",
              fontWeight: 500
            }}
            _focus={{
              color: "#FD7B1D",
              fontWeight: 500
            }}
            _active={{}}
            p={0}
          >
            {symbol}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default EarnAssetMenu;
