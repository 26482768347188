import { Decimal } from "./Decimal";
import { Fees } from "./Fees";
import { LQTYStake } from "./LQTYStake";
import { StabilityDeposit } from "./StabilityDeposit";
import { Trove, TroveWithPendingRedistribution, UserTrove } from "./Trove";
import { FrontendStatus, ReadableLiquity, TroveListingParams } from "./ReadableLiquity";
import { PendingReferralRewards, Price } from "./LiquityStore";

/** @internal */
export type _ReadableLiquityWithExtraParamsBase<T extends unknown[]> = {
  [P in keyof ReadableLiquity]: ReadableLiquity[P] extends (...params: infer A) => infer R
    ? (...params: [...originalParams: A, ...extraParams: T]) => R
    : never;
};

/** @internal */
export type _LiquityReadCacheBase<T extends unknown[]> = {
  [P in keyof ReadableLiquity]: ReadableLiquity[P] extends (...args: infer A) => Promise<infer R>
    ? (...params: [...originalParams: A, ...extraParams: T]) => R | undefined
    : never;
};

// Overloads get lost in the mapping, so we need to define them again...

/** @internal */
export interface _ReadableLiquityWithExtraParams<T extends unknown[]>
  extends _ReadableLiquityWithExtraParamsBase<T> {
  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution[]>;

  getTroves(poolName: string, params: TroveListingParams, ...extraParams: T): Promise<UserTrove[]>;
}

/** @internal */
export interface _LiquityReadCache<T extends unknown[]> extends _LiquityReadCacheBase<T> {
  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): TroveWithPendingRedistribution[] | undefined;

  getTroves(
    poolName: string,
    params: TroveListingParams,
    ...extraParams: T
  ): UserTrove[] | undefined;
}

/** @internal */
export class _CachedReadableLiquity<T extends unknown[]>
  implements _ReadableLiquityWithExtraParams<T>
{
  private _readable: _ReadableLiquityWithExtraParams<T>;
  private _cache: _LiquityReadCache<T>;

  constructor(readable: _ReadableLiquityWithExtraParams<T>, cache: _LiquityReadCache<T>) {
    this._readable = readable;
    this._cache = cache;
  }

  async getTotalRedistributed(poolName: string, ...extraParams: T): Promise<Trove> {
    return (
      this._cache.getTotalRedistributed(poolName, ...extraParams) ??
      this._readable.getTotalRedistributed(poolName, ...extraParams)
    );
  }

  async getTroveBeforeRedistribution(
    poolName: string,
    address?: string,
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution> {
    return (
      this._cache.getTroveBeforeRedistribution(poolName, address, ...extraParams) ??
      this._readable.getTroveBeforeRedistribution(poolName, address, ...extraParams)
    );
  }

  async getTrove(poolName: string, address?: string, ...extraParams: T): Promise<UserTrove> {
    const [troveBeforeRedistribution, totalRedistributed] = await Promise.all([
      this.getTroveBeforeRedistribution(poolName, address, ...extraParams),
      this.getTotalRedistributed(poolName, ...extraParams)
    ]);

    return troveBeforeRedistribution.applyRedistribution(totalRedistributed);
  }

  async getNumberOfTroves(poolName: string, ...extraParams: T): Promise<number> {
    return (
      this._cache.getNumberOfTroves(poolName, ...extraParams) ??
      this._readable.getNumberOfTroves(poolName, ...extraParams)
    );
  }

  async getPrice(poolName: string, ...extraParams: T): Promise<Price> {
    return (
      this._cache.getPrice(poolName, ...extraParams) ??
      this._readable.getPrice(poolName, ...extraParams)
    );
  }

  async getTotal(poolName: string, ...extraParams: T): Promise<Trove> {
    return (
      this._cache.getTotal(poolName, ...extraParams) ??
      this._readable.getTotal(poolName, ...extraParams)
    );
  }

  async getAllPendingReferralRewards(
    address?: string,
    ...extraParams: T
  ): Promise<PendingReferralRewards> {
    return (
      this._cache.getAllPendingReferralRewards(address, ...extraParams) ??
      this._readable.getAllPendingReferralRewards(address, ...extraParams)
    );
  }

  async getStabilityDeposit(
    poolName: string,
    address?: string,
    ...extraParams: T
  ): Promise<StabilityDeposit> {
    return (
      this._cache.getStabilityDeposit(poolName, address, ...extraParams) ??
      this._readable.getStabilityDeposit(poolName, address, ...extraParams)
    );
  }

  async getRemainingStabilityPoolLQTYReward(poolName: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getRemainingStabilityPoolLQTYReward(poolName, ...extraParams) ??
      this._readable.getRemainingStabilityPoolLQTYReward(poolName, ...extraParams)
    );
  }
  async getUserReferrer(address?: string, ...extraParams: T): Promise<string> {
    return (
      this._cache.getUserReferrer(address, ...extraParams) ??
      this._readable.getUserReferrer(address, ...extraParams)
    );
  }

  async getLUSDInStabilityPool(borrowingTokenName: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getLUSDInStabilityPool(borrowingTokenName, ...extraParams) ??
      this._readable.getLUSDInStabilityPool(borrowingTokenName, ...extraParams)
    );
  }

  async getLUSDSupply(borrowingTokenName: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getLUSDSupply(borrowingTokenName, ...extraParams) ??
      this._readable.getLUSDSupply(borrowingTokenName, ...extraParams)
    );
  }

  async getLUSDBalance(
    borrowingTokenName: string,
    address?: string,
    ...extraParams: T
  ): Promise<Decimal> {
    return (
      this._cache.getLUSDBalance(borrowingTokenName, address, ...extraParams) ??
      this._readable.getLUSDBalance(borrowingTokenName, address, ...extraParams)
    );
  }

  async getLQTYBalance(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getLQTYBalance(address, ...extraParams) ??
      this._readable.getLQTYBalance(address, ...extraParams)
    );
  }

  async getUniTokenBalance(
    borrowingTokenName: string,
    address?: string,
    ...extraParams: T
  ): Promise<Decimal> {
    return (
      this._cache.getUniTokenBalance(borrowingTokenName, address, ...extraParams) ??
      this._readable.getUniTokenBalance(borrowingTokenName, address, ...extraParams)
    );
  }

  async getUniTokenAllowance(
    borrowingTokenName: string,
    address?: string,
    ...extraParams: T
  ): Promise<Decimal> {
    return (
      this._cache.getUniTokenAllowance(borrowingTokenName, address, ...extraParams) ??
      this._readable.getUniTokenAllowance(borrowingTokenName, address, ...extraParams)
    );
  }

  async getRemainingLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    ...extraParams: T
  ): Promise<Decimal> {
    return (
      this._cache.getRemainingLiquidityMiningLQTYReward(borrowingTokenName, ...extraParams) ??
      this._readable.getRemainingLiquidityMiningLQTYReward(borrowingTokenName, ...extraParams)
    );
  }

  async getLiquidityMiningStake(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getLiquidityMiningStake(address, ...extraParams) ??
      this._readable.getLiquidityMiningStake(address, ...extraParams)
    );
  }

  async getTotalStakedUniTokens(borrowingTokenName: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getTotalStakedUniTokens(borrowingTokenName, ...extraParams) ??
      this._readable.getTotalStakedUniTokens(borrowingTokenName, ...extraParams)
    );
  }

  async getLiquidityMiningLQTYReward(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getLiquidityMiningLQTYReward(address, ...extraParams) ??
      this._readable.getLiquidityMiningLQTYReward(address, ...extraParams)
    );
  }

  async getCollateralSurplusBalance(
    poolName: string,
    address?: string,
    ...extraParams: T
  ): Promise<Decimal> {
    return (
      this._cache.getCollateralSurplusBalance(poolName, address, ...extraParams) ??
      this._readable.getCollateralSurplusBalance(poolName, address, ...extraParams)
    );
  }

  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution[]>;

  getTroves(poolName: string, params: TroveListingParams, ...extraParams: T): Promise<UserTrove[]>;

  async getTroves(
    poolName: string,
    params: TroveListingParams,
    ...extraParams: T
  ): Promise<UserTrove[]> {
    const { beforeRedistribution, ...restOfParams } = params;

    const [totalRedistributed, troves] = await Promise.all([
      beforeRedistribution ? undefined : this.getTotalRedistributed(poolName, ...extraParams),
      this._cache.getTroves(
        poolName,
        { beforeRedistribution: true, ...restOfParams },
        ...extraParams
      ) ??
        this._readable.getTroves(
          poolName,
          { beforeRedistribution: true, ...restOfParams },
          ...extraParams
        )
    ]);

    if (totalRedistributed) {
      return troves.map(trove => trove.applyRedistribution(totalRedistributed));
    } else {
      return troves;
    }
  }

  async getFees(poolName: string, ...extraParams: T): Promise<Fees> {
    return (
      this._cache.getFees(poolName, ...extraParams) ??
      this._readable.getFees(poolName, ...extraParams)
    );
  }

  async getLQTYStake(address?: string, ...extraParams: T): Promise<LQTYStake> {
    return (
      this._cache.getLQTYStake(address, ...extraParams) ??
      this._readable.getLQTYStake(address, ...extraParams)
    );
  }


  async getTotalStakedLQTY(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getTotalStakedLQTY(...extraParams) ??
      this._readable.getTotalStakedLQTY(...extraParams)
    );
  }

  async getFrontendStatus(
    poolName: string,
    address?: string,
    ...extraParams: T
  ): Promise<FrontendStatus> {
    return (
      this._cache.getFrontendStatus(poolName, address, ...extraParams) ??
      this._readable.getFrontendStatus(poolName, address, ...extraParams)
    );
  }

  async getPoolNameWithLowestCR(
    borrowingTokenName: string,
    ...extraParams: T
  ): Promise<string> {
    return (
      this._cache.getPoolNameWithLowestCR(borrowingTokenName, ...extraParams) ??
      this._readable.getPoolNameWithLowestCR(borrowingTokenName, ...extraParams)
    );
  }

}
