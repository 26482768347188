import React from "react";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { Decimal } from "@liquity/lib-base";

export type BalanceInfoItem = {
  token: string;
  balance: Decimal;
};

export type BalanceInfoProps = {
  items: BalanceInfoItem[];
};

export const BalanceInfo: React.FC<BalanceInfoProps> = ({ items }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex w={isMobileOrTablet ? "100%" : "30%"} justifyContent="flex-end">
      <Flex
        direction="column"
        bg="white"
        borderRadius="20px"
        p={isMobileOrTablet ? "18px 14px" : "32px 28px"}
        w={isMobileOrTablet ? "100%" : "auto"}
      >
        <Grid
          w="100%"
          templateColumns="repeat(2, 1fr)"
          fontSize="18px"
          columnGap="36px"
          rowGap="10px"
          justifyItems="center"
          color="text.500"
        >
          <GridItem>Token</GridItem>
          <GridItem>Balance</GridItem>
          {items.map(({ token, balance }) => (
            <React.Fragment key={token}>
              <GridItem color="text.200">{token}</GridItem>
              <GridItem color="text.200">{balance.prettify(2)}</GridItem>
            </React.Fragment>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};
