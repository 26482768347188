import styled from "@emotion/styled";

export const PaginatorContainer = styled("div")`
  display: flex;
  gap: 41px;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 576px) {
    gap: 14px;
  }
`;
export const PaginatorPagesContainer = styled("div")`
  display: flex;
  gap: 24px;
  flex-direction: row;
  align-items: center;
`;

export const PaginatorArrow = styled("img")<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
`;

export const PaginatorPageText = styled.p<{ active?: boolean }>`
  font-family: DM Sans, sans-serif;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? "#515151" : "#518AF9")};
  text-decoration: ${({ active }) => (active ? "" : "underline")};
  text-underline-offset: 2px;
  cursor: ${({ active }) => (active ? "default" : "pointer")};
`;
export const PaginatorPageDot = styled.p<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? "#3A7DFF" : "white")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid #3a7dff;
`;
