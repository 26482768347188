import React from "react";
import { Flex } from "@chakra-ui/react";
import { Text } from "../Text";
import { TransactionItemElement } from "./index";
import { Decimal } from "@liquity/lib-base";

type HeaderAndValue = {
  header: string;
  value?: TransactionItemElement;
};

type TransactionVerticalProps = {
  index: number;
  first: HeaderAndValue;
  second: HeaderAndValue;
  third: HeaderAndValue;
  fourth?: HeaderAndValue;
  textToClick: string;
  onClick: (index: number) => void | Promise<void>;
};

export const TransactionVertical: React.FC<TransactionVerticalProps> = ({
  index,
  first,
  second,
  third,
  fourth,
  textToClick,
  onClick
}) => {
  return (
    <Flex direction="column" gap="8px" mt="8px">
      <Text ml="16px" color="text.100" fontSize="14px" fontWeight={500}>
        #{index + 1}
      </Text>
      <Flex
        w="100%"
        px="16px"
        bg="rgba(255,255,255,0.5)"
        alignItems="center"
        justifyContent="space-between"
        h="47px"
      >
        <Text color="text.100" fontSize="18px" fontWeight={500}>
          {first.header}
        </Text>
        <Text color="text.200" fontSize="18px">
          {Decimal.from(first.value?.value ?? 0).prettify(2)} {first.value?.symbol}
        </Text>
      </Flex>
      <Flex
        w="100%"
        px="16px"
        bg="rgba(255,255,255,0.5)"
        alignItems="center"
        justifyContent="space-between"
        h="47px"
      >
        <Text color="text.100" fontSize="18px" fontWeight={500}>
          {second.header}
        </Text>
        <Text color="text.200" fontSize="18px">
          {Decimal.from(second.value?.value ?? 0).prettify(2)} {second.value?.symbol}
        </Text>
      </Flex>
      <Flex
        w="100%"
        px="16px"
        bg="rgba(255,255,255,0.5)"
        alignItems="center"
        justifyContent="space-between"
        h="47px"
      >
        <Text color="text.100" fontSize="18px" fontWeight={500}>
          {third.header}
        </Text>
        <Text color="text.200" fontSize="18px">
          {third.value?.value && Decimal.from(third.value.value ?? 0).prettify(2)}
          {third.value?.symbol}
        </Text>
      </Flex>
      {fourth?.value && (
        <Flex
          w="100%"
          px="16px"
          bg="rgba(255,255,255,0.5)"
          alignItems="center"
          justifyContent="space-between"
          h="47px"
        >
          <Text color="text.100" fontSize="18px" fontWeight={500}>
            {fourth.header}
          </Text>
          <Text color="text.200" fontSize="18px">
            {fourth.value.symbol}
            {fourth.value.value && Decimal.from(fourth.value.value ?? 0).prettify(2)}
          </Text>
        </Flex>
      )}
      <Text
        w="100%"
        textAlign="center"
        color="primary.100"
        fontSize="18px"
        fontWeight={500}
        mt="16px"
        sx={{
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
            textUnderlineOffset: "5px"
          }
        }}
        onClick={() => onClick(index)}
      >
        {textToClick}
      </Text>
    </Flex>
  );
};
