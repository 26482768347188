import { BlockTag } from "@ethersproject/abstract-provider";

import {
  Decimal,
  Fees,
  FrontendStatus,
  LiquityStore,
  LQTYStake,
  PendingReferralRewards,
  PendingReferralRewardsWithDate,
  PendingRewardsType,
  PoolBaseConfig,
  PoolInfo,
  PoolInfoByUser,
  Price,
  ReadableLiquity,
  RedemptionFee,
  Referrals,
  ReferralsType,
  ReferralsWithDate,
  SPInfoByUser,
  StabilityDeposit,
  Trove,
  TroveListingParams,
  TroveWithPendingRedistribution,
  UserTrove,
  UserTroveStatus,
  ReferralWithDateType,
  fetchLqtyPrice
} from "@liquity/lib-base";

import { ActivePool, DefaultPool, MultiTroveGetter, StabilityPool, TroveManager } from "../types";

import { decimalify, numberify, panic, toBase18 } from "./_utils";
import { EthersCallOverrides, EthersProvider, EthersSigner } from "./types";

import {
  _connect,
  _getBlockTimestamp,
  _getBorrowingOptionContracts,
  _getBorrowingOptionContractsFromPoolName,
  _getBorrowingOptions,
  _getFeedsContracts,
  _getPool,
  _getPoolBaseConfig,
  _getPoolContracts,
  _getPoolsContracts,
  _getSharedContracts,
  _requireAddress,
  _requireFrontendAddress,
  EthersLiquityConnection,
  EthersLiquityConnectionOptionalParams,
  EthersLiquityStoreOption,
  _getCollateralOptions,
  _getOverride,
  _getPoolConfig
} from "./EthersLiquityConnection";

import { BlockPolledLiquityStore } from "./BlockPolledLiquityStore";
import { BigNumber, ethers } from "ethers/lib.esm";
import { _getPoolName, _isPoolName, _splitPoolName } from "./contracts";
import { formatUnits, getAddress } from "ethers/lib/utils";

// TODO: these are constant in the contracts, so it doesn't make sense to make a call for them,
// but to avoid having to update them here when we change them in the contracts, we could read
// them once after deployment and save them to LiquityDeployment.
const MINUTE_DECAY_FACTOR = Decimal.from("0.999037758833783000");
const BETA = Decimal.from(2);

export const DECIMAL_PRECISION = ethers.BigNumber.from("1000000000000000000");

export const decMul = (x: ethers.BigNumber, y: ethers.BigNumber) => {
  const prod_xy = x.mul(y);
  return prod_xy.add(DECIMAL_PRECISION.div(2)).div(DECIMAL_PRECISION);
};

export const decPow = (_base: ethers.BigNumber, _minutes: number) => {
  if (_minutes > 525_600_000) {
    _minutes = 525_600_000;
  }
  if (_minutes === 0) {
    return DECIMAL_PRECISION;
  }

  let y = DECIMAL_PRECISION.add(0);
  let x = _base.add(0);
  let n = _minutes;

  while (n > 1) {
    if (n % 2 === 0) {
      x = decMul(x, x);
      n /= 2;
    } else {
      y = decMul(x, y);
      x = decMul(x, x);
      n = (n - 1) / 2;
    }
  }

  return decMul(x, y);
};

enum BackendTroveStatus {
  nonExistent,
  active,
  closedByOwner,
  closedByLiquidation,
  closedByRedemption
}

const userTroveStatusFrom = (backendStatus: BackendTroveStatus): UserTroveStatus =>
  backendStatus === BackendTroveStatus.nonExistent
    ? "nonExistent"
    : backendStatus === BackendTroveStatus.active
    ? "open"
    : backendStatus === BackendTroveStatus.closedByOwner
    ? "closedByOwner"
    : backendStatus === BackendTroveStatus.closedByLiquidation
    ? "closedByLiquidation"
    : backendStatus === BackendTroveStatus.closedByRedemption
    ? "closedByRedemption"
    : panic(new Error(`invalid backendStatus ${backendStatus}`));

const convertToDate = (timestamp: number) => new Date(timestamp * 1000);

const validSortingOptions = ["ascendingCollateralRatio", "descendingCollateralRatio"];

const expectPositiveInt = <K extends string>(obj: { [P in K]?: number }, key: K) => {
  if (obj[key] !== undefined) {
    if (!Number.isInteger(obj[key])) {
      throw new Error(`${key} must be an integer`);
    }

    if (obj[key] < 0) {
      throw new Error(`${key} must not be negative`);
    }
  }
};

/**
 * Ethers-based implementation of {@link @liquity/lib-base#ReadableLiquity}.
 *
 * @public
 */
export class ReadableEthersLiquity implements ReadableLiquity {
  readonly connection: EthersLiquityConnection;

  public poolPrices: Record<string, Price> = {};
  public prices: Record<string, Price> = {};

  /** @internal */
  constructor(connection: EthersLiquityConnection) {
    this.connection = connection;
  }

  /** @internal */
  static _from(
    connection: EthersLiquityConnection & { useStore: "blockPolled" }
  ): ReadableEthersLiquityWithStore<BlockPolledLiquityStore>;

  /** @internal */
  static _from(connection: EthersLiquityConnection): ReadableEthersLiquity;

  /** @internal */
  static _from(connection: EthersLiquityConnection): ReadableEthersLiquity {
    const readable = new ReadableEthersLiquity(connection);

    return connection.useStore === "blockPolled"
      ? new _BlockPolledReadableEthersLiquity(readable)
      : readable;
  }

  /** @internal */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams: EthersLiquityConnectionOptionalParams & { useStore: "blockPolled" }
  ): Promise<ReadableEthersLiquityWithStore<BlockPolledLiquityStore>>;

  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersLiquityConnectionOptionalParams
  ): Promise<ReadableEthersLiquity>;

  /**
   * Connect to the Liquity protocol and create a `ReadableEthersLiquity` object.
   *
   * @param signerOrProvider - Ethers `Signer` or `Provider` to use for connecting to the Ethereum
   *                           network.
   * @param optionalParams - Optional parameters that can be used to customize the connection.
   */
  static async connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersLiquityConnectionOptionalParams
  ): Promise<ReadableEthersLiquity> {
    return ReadableEthersLiquity._from(await _connect(signerOrProvider, optionalParams));
  }

  /**
   * Check whether this `ReadableEthersLiquity` is a {@link ReadableEthersLiquityWithStore}.
   */
  hasStore(): this is ReadableEthersLiquityWithStore;

  /**
   * Check whether this `ReadableEthersLiquity` is a
   * {@link ReadableEthersLiquityWithStore}\<{@link BlockPolledLiquityStore}\>.
   */
  hasStore(store: "blockPolled"): this is ReadableEthersLiquityWithStore<BlockPolledLiquityStore>;

  hasStore(): boolean {
    return false;
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalRedistributed} */
  async getTotalRedistributed(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    const { multicall } = _getSharedContracts(this.connection);
    overrides ??= _getOverride(overrides);

    const { troveManager } = _getPoolContracts(poolName, this.connection);
    const config = _getPoolBaseConfig(poolName, this.connection);

    const calls = [
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("L_ETH", [])
      },
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("L_LUSDDebt", [])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const [collateral, debt] = multicallResult.map(res => {
      // does'nt count different contracts and method names because they all have the same return type - BigNumber
      const decoded = troveManager.interface.decodeFunctionResult("L_ETH", res);
      return decimalify(BigNumber.from(decoded.toString()));
    });

    return new Trove(config, collateral, debt);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTroveBeforeRedistribution} */
  async getTroveBeforeRedistribution(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution> {
    address ??= _requireAddress(this.connection);
    const { troveManager } = _getPoolContracts(poolName, this.connection);
    const { multicall } = _getSharedContracts(this.connection);

    const config = _getPoolBaseConfig(poolName, this.connection);

    const calls = [
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("Troves", [address])
      },
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("rewardSnapshots", [address])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const trove = troveManager.interface.decodeFunctionResult(
      "Troves",
      multicallResult[0]
    ) as unknown as Awaited<ReturnType<TroveManager["Troves"]>>;
    const snapshot = troveManager.interface.decodeFunctionResult(
      "rewardSnapshots",
      multicallResult[1]
    ) as unknown as Awaited<ReturnType<TroveManager["rewardSnapshots"]>>;

    if (trove.status === BackendTroveStatus.active) {
      const res = new TroveWithPendingRedistribution(
        config,
        address,
        userTroveStatusFrom(trove.status),
        decimalify(BigNumber.from(trove.coll.toString())),
        decimalify(BigNumber.from(trove.debt.toString())),
        decimalify(BigNumber.from(trove.stake.toString())),
        new Trove(
          config,
          decimalify(BigNumber.from(snapshot.ETH.toString())),
          decimalify(BigNumber.from(snapshot.LUSDDebt.toString()))
        )
      );
      return res;
    } else {
      return new TroveWithPendingRedistribution(config, address, userTroveStatusFrom(trove.status));
    }
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTrove} */
  async getTrove(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove> {
    const [trove, totalRedistributed] = await Promise.all([
      this.getTroveBeforeRedistribution(poolName, address, overrides),
      this.getTotalRedistributed(poolName, overrides)
    ]);

    return trove.applyRedistribution(totalRedistributed);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getNumberOfTroves} */
  async getNumberOfTroves(poolName: string, overrides?: EthersCallOverrides): Promise<number> {
    const { troveManager } = _getPoolContracts(poolName, this.connection);

    return (await troveManager.getTroveOwnersCount({ ...overrides })).toNumber();
  }

  async getAllPoolsInfo(overrides?: EthersCallOverrides): Promise<Record<string, PoolInfo>> {
    const pools = _getPoolsContracts(this.connection);
    const poolsInfos: Record<string, PoolInfo> = {};

    await Promise.all(
      Object.keys(pools).map(async pool => {
        const borrowingTokenName = _splitPoolName(pool).borrowingTokenName;
        const [totalLusdDeposit, lqtyIssuanceOneDay, price, total] = await Promise.all([
          this.getLUSDInStabilityPool(borrowingTokenName, overrides),
          this.getRemainingStabilityPoolLQTYReward(borrowingTokenName, overrides).then(rem => {
            const { issuanceFactor } = this.connection.borrowingOptionContracts[borrowingTokenName];
            console.log("issuanceFactor", issuanceFactor.toString());
            const yearlyIssuanceFraction = parseFloat(
              ethers.utils.formatUnits(DECIMAL_PRECISION.sub(decPow(issuanceFactor, 525600)), 18)
            );
            const dailyIssuanceFraction = Decimal.from(1 - yearlyIssuanceFraction ** (1 / 365));
            return rem.mul(dailyIssuanceFraction);
          }),
          this.getPrice(pool, overrides),
          this.getTotal(pool, overrides)
        ]);
        poolsInfos[pool] = new PoolInfo(totalLusdDeposit, lqtyIssuanceOneDay, price, total);
      })
    );

    return poolsInfos;
  }

  async getAllPoolsInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, PoolInfoByUser>> {
    const pools = _getPoolsContracts(this.connection);
    const poolsInfos: Record<string, PoolInfoByUser> = {};
    await Promise.all(
      Object.keys(pools).map(async pool => {
        const [trove, { assetBalance, assetAllowanceToBO }] = await Promise.all([
          this.getTrove(pool, address, overrides),
          this.getAssetInfoByUser(pool, address, overrides)
        ]);

        poolsInfos[pool] = new PoolInfoByUser(trove, assetBalance, assetAllowanceToBO);
      })
    );

    return poolsInfos;
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getPrice} */
  async getPrice(poolName: string, overrides?: EthersCallOverrides): Promise<Price> {
    if (!this.poolPrices[poolName]) {
      console.log("PRICE IS NOT RECORDED");

      const { priceFeed } = _getPoolContracts(poolName, this.connection);

      const price = await priceFeed.callStatic.fetchPrice({ ...overrides }).then(decimalify);

      return new Price(price, this.connection.poolContracts[poolName].hasReversedOracle);
    } else {
      console.log("PRICE IS RECORDED", this.poolPrices[poolName]);
      return this.poolPrices[poolName];
    }
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotal} */
  async getTotal(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    const { multicall } = _getSharedContracts(this.connection);
    const { activePool, defaultPool } = _getPoolContracts(poolName, this.connection);
    const config = _getPoolBaseConfig(poolName, this.connection);
    overrides ??= _getOverride(overrides);

    const calls = [
      {
        target: activePool.address,
        callData: activePool.interface.encodeFunctionData("getETH", [])
      },
      {
        target: activePool.address,
        callData: activePool.interface.encodeFunctionData("getLUSDDebt", [])
      },
      {
        target: defaultPool.address,
        callData: defaultPool.interface.encodeFunctionData("getETH", [])
      },
      {
        target: defaultPool.address,
        callData: defaultPool.interface.encodeFunctionData("getLUSDDebt", [])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const [activeCollateral, activeDebt, liquidatedCollateral, closedDebt] = multicallResult.map(
      res => {
        // does'nt count different contracts and method names because they all have the same return type - BigNumber
        const decoded = activePool.interface.decodeFunctionResult("getETH", res);
        return decimalify(BigNumber.from(decoded.toString()));
      }
    );

    const activePoolTrove = new Trove(config, activeCollateral, activeDebt);
    const defaultPoolTrove = new Trove(config, liquidatedCollateral, closedDebt);

    return activePoolTrove.add(defaultPoolTrove);
  }

  async getClaimedSPEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    // address ??= _requireAddress(this.connection);
    // const { stabilityPool: sp } = _getPoolsContracts(this.connection);
    //
    // const ethGainWithdrawnFilter = sp.filters.ETHGainWithdrawn(address, null, null);
    // const lqtyPaidFilter = sp.filters.LQTYPaidToDepositor(address, null);

    return new PendingReferralRewards({});
  }

  async getPendingEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    // address ??= _requireAddress(this.connection);
    // const { stabilityPool: sp } = _getSharedContracts(this.connection);
    // const tokens = Object.entries(_getPoolsContracts(this.connection)).map(([name, contract]) => ({
    //   name,
    //   address: contract.collateralToken.address
    // }));
    //
    // const [totalPoolRewards, userTotalPendingReward, lqtyReward] = await Promise.all([
    //   sp.getETH(overrides).then(b => decimalify(b)),
    //   sp.getDepositorETHGain(address, overrides).then(b => decimalify(b)),
    //   sp.getDepositorLQTYGain(address, overrides).then(b => decimalify(b))
    // ]);
    //
    // const o = [
    //   ...(await Promise.all(
    //     tokens.map(async ({ address, name }) => {
    //       const tokenTotalPoolRewards = await sp
    //         .getPoolAssetBalance(address, overrides)
    //         .then(b => decimalify(b));
    //
    //       return [
    //         name,
    //         totalPoolRewards.isZero
    //           ? Decimal.ZERO
    //           : userTotalPendingReward.mul(tokenTotalPoolRewards).div(totalPoolRewards)
    //       ] as [string, Decimal];
    //     })
    //   )),
    //   ["LQTY", lqtyReward] as [string, Decimal]
    // ];

    return new PendingReferralRewards({});
  }

  async getAllPendingReferralRewards(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    const { referralCenter, multicall } = _getSharedContracts(this.connection);
    const pools = _getPoolsContracts(this.connection);
    overrides ??= _getOverride(overrides);
    address ??= _requireAddress(this.connection);

    const collateralOptions = Object.keys(pools)
      .map(_splitPoolName)
      .filter((value, i, array) => {
        return (
          array.indexOf(array.find(v => v.collateralTokenName === value.collateralTokenName)!) === i
        );
      })
      .map(({ collateralTokenName, borrowingTokenName }) =>
        _getPoolName(collateralTokenName, borrowingTokenName)
      );

    const poolNameOrBorrowingTokenNames = [
      ..._getBorrowingOptions(this.connection).map(v => v.optionName),
      ...collateralOptions
    ];

    const calls = poolNameOrBorrowingTokenNames.map(poolNameOrBorrowingTokenName => {
      let decimals: number;
      let tokenAddress: string;

      const isBorrowingToken = Boolean(
        this.connection.borrowingOptionContracts[poolNameOrBorrowingTokenName]
      );

      if (isBorrowingToken) {
        decimals = 18;
        const { borrowingToken } = _getBorrowingOptionContracts(
          poolNameOrBorrowingTokenName,
          this.connection
        );

        tokenAddress = borrowingToken.address;
      } else {
        const { collateralToken } = _getPoolContracts(poolNameOrBorrowingTokenName, this.connection);
        const config = _getPoolBaseConfig(poolNameOrBorrowingTokenName, this.connection);

        decimals = config.collateralTokenDecimals;
        tokenAddress = collateralToken.address;
      }

      return {
        poolNameOrBorrowingTokenName,
        tokenAddress,
        decimals,
        target: referralCenter.address,
        callData: referralCenter.interface.encodeFunctionData("pendingRewards", [
          address,
          tokenAddress
        ])
      };
    });

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const decodedData = calls.map(({ decimals, poolNameOrBorrowingTokenName }, i) => {
      const res = multicallResult[i];
      const decodedRes = referralCenter.interface.decodeFunctionResult("pendingRewards", res);
      return [
        poolNameOrBorrowingTokenName,
        decimalify(toBase18(BigNumber.from(decodedRes.toString()), 18))
      ];
    }) as [string, Decimal][];

    const entries = decodedData.map(([key, value]) =>
      _isPoolName(key) ? [_splitPoolName(key).collateralTokenName, value] : [key, value]
    );

    return new PendingReferralRewards(Object.fromEntries(entries));
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getStabilityDeposit} */
  async getStabilityDeposit(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<StabilityDeposit> {
    overrides ??= _getOverride(overrides);
    address ??= _requireAddress(this.connection);
    const { stabilityPool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);
    const { multicall } = _getSharedContracts(this.connection);

    const spCalls = [
      stabilityPool.interface.encodeFunctionData("deposits", [address]),
      stabilityPool.interface.encodeFunctionData("getCompoundedLUSDDeposit", [address]),
      stabilityPool.interface.encodeFunctionData("getDepositorETHGain", [address]),
      stabilityPool.interface.encodeFunctionData("getDepositorLQTYGain", [address]),
      stabilityPool.interface.encodeFunctionData("getETH", [])
    ];

    const multiCallData = spCalls.map(call => ({ target: stabilityPool.address, callData: call }));

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(
      multiCallData,
      overrides
    );

    const decode = <TOut>(funcName: string, data: string) => {
      return stabilityPool.interface.decodeFunctionResult(funcName, data) as any as TOut;
    };

    const { frontEndTag, initialValue } = decode<Awaited<ReturnType<StabilityPool["deposits"]>>>(
      "deposits",
      multicallResult[0]
    );
    const currentLUSD = decode<Awaited<ReturnType<StabilityPool["getCompoundedLUSDDeposit"]>>>(
      "getCompoundedLUSDDeposit",
      multicallResult[1]
    );
    const collateralGain = decode<Awaited<ReturnType<StabilityPool["getDepositorETHGain"]>>>(
      "getDepositorETHGain",
      multicallResult[2]
    );
    const lqtyReward = decode<Awaited<ReturnType<StabilityPool["getDepositorLQTYGain"]>>>(
      "getDepositorLQTYGain",
      multicallResult[3]
    );
    const totalCollateralReward = decode<Awaited<ReturnType<StabilityPool["getETH"]>>>(
      "getETH",
      multicallResult[4]
    );

    return new StabilityDeposit(
      decimalify(initialValue),
      decimalify(BigNumber.from(currentLUSD.toString())),
      decimalify(BigNumber.from(collateralGain.toString())),
      decimalify(BigNumber.from(lqtyReward.toString())),
      decimalify(BigNumber.from(totalCollateralReward.toString())),
      frontEndTag
    );
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getRemainingStabilityPoolLQTYReward} */
  async getRemainingStabilityPoolLQTYReward(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    const { communityIssuance } = _getSharedContracts(this.connection);
    const { borrowingToken } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    const issuanceCap =
      this.connection.borrowingOptionContracts[borrowingTokenName].totalStabilityPoolLQTYReward;
    const totalLQTYIssued = decimalify(
      await communityIssuance.totalLQTYIssued(borrowingToken.address, { ...overrides })
    );

    // totalLQTYIssued approaches but never reaches issuanceCap
    return issuanceCap.sub(totalLQTYIssued);
  }

  getUserReferrer(address?: string, overrides?: EthersCallOverrides): Promise<string> {
    address ??= _requireAddress(this.connection);
    const { referralCenter } = _getSharedContracts(this.connection);
    return referralCenter.referrers(address, { ...overrides });
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDInStabilityPool} */
  getLUSDInStabilityPool(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    const { stabilityPool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return stabilityPool.getTotalLUSDDeposits({ ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDSupply} */
  getLUSDSupply(borrowingTokenName: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    const { borrowingToken } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return borrowingToken.totalSupply({ ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  getLUSDBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { borrowingToken } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return borrowingToken.balanceOf(address, { ...overrides }).then(decimalify);
  }

  async getAssetInfoByUser(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<{
    assetBalance: Decimal;
    assetAllowanceToBO: Decimal;
  }> {
    overrides ??= _getOverride(overrides);
    address ??= _requireAddress(this.connection);
    const { collateralToken, borrowerOperations } = _getPoolContracts(poolName, this.connection);
    const config = _getPoolBaseConfig(poolName, this.connection);

    const { multicall } = _getSharedContracts(this.connection);

    const calls = [
      {
        target: collateralToken.address,
        callData: collateralToken.interface.encodeFunctionData("balanceOf", [address])
      },
      {
        target: collateralToken.address,
        callData: collateralToken.interface.encodeFunctionData("allowance", [
          address,
          borrowerOperations.address
        ])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const [balance, allowance] = multicallResult.map((res, i) => {
      const methodName = i == 0 ? "balanceOf" : "allowance";
      const decoded = BigNumber.from(
        collateralToken.interface.decodeFunctionResult(methodName, res).toString()
      );
      return decimalify(toBase18(decoded, config.collateralTokenDecimals));
    });

    return {
      assetBalance: balance,
      assetAllowanceToBO: allowance
    };
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  async getAssetBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    // TODO: remove this method as it no longer needed
    return Decimal.ZERO;

    // return collateralToken
    //   .balanceOf(address, { ...overrides })
    //   .then(v => toBase18(v, config.collateralTokenDecimals))
    //   .then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  async getAssetAllowance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    // TODO: remove this method as it no longer needed
    return Decimal.ZERO;
    // return collateralToken
    //   .allowance(address, borrowerOperations.address, { ...overrides })
    //   .then(v => toBase18(v, config.collateralTokenDecimals))
    //   .then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  async getAssetSymbol(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    const { collateralToken } = _getPoolContracts(poolName, this.connection);
    // TODO: remove assetSymbolEntirely
    return "PLUG"; // collateralToken.symbol({ ...overrides });
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLQTYBalance} */
  getLQTYBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { lqtyToken } = _getSharedContracts(this.connection);
    return lqtyToken.balanceOf(address, { ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getUniTokenBalance} */
  getUniTokenBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { uniToken } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return uniToken.balanceOf(address, { ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getUniTokenAllowance} */
  getUniTokenAllowance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { unipool, uniToken } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return uniToken.allowance(address, unipool.address, { ...overrides }).then(decimalify);
  }

  /** @internal */
  async _getRemainingLiquidityMiningLQTYRewardCalculator(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number) => Decimal> {
    const { unipool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    const [totalSupply, rewardRate, periodFinish, lastUpdateTime] = await Promise.all([
      unipool.totalSupply({ ...overrides }),
      unipool.rewardRate({ ...overrides }).then(decimalify),
      unipool.periodFinish({ ...overrides }).then(numberify),
      unipool.lastUpdateTime({ ...overrides }).then(numberify)
    ]);

    return (blockTimestamp: number) =>
      rewardRate.mul(
        Math.max(0, periodFinish - (totalSupply.isZero() ? lastUpdateTime : blockTimestamp))
      );
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getRemainingLiquidityMiningLQTYReward} */
  async getRemainingLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    const [calculateRemainingLQTY, blockTimestamp] = await Promise.all([
      this._getRemainingLiquidityMiningLQTYRewardCalculator(borrowingTokenName, overrides),
      this._getBlockTimestamp(overrides?.blockTag)
    ]);

    return calculateRemainingLQTY(blockTimestamp);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLiquidityMiningStake} */
  getLiquidityMiningStake(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { unipool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return unipool.balanceOf(address, { ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalStakedUniTokens} */
  getTotalStakedUniTokens(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    const { unipool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return unipool.totalSupply({ ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLiquidityMiningLQTYReward} */
  getLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { unipool } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);

    return unipool.earned(address, { ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getCollateralSurplusBalance} */
  getCollateralSurplusBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    address ??= _requireAddress(this.connection);
    const { collSurplusPool } = _getPoolContracts(poolName, this.connection);

    return collSurplusPool.getCollateral(address, { ...overrides }).then(decimalify);
  }

  /** @internal */
  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution[]>;

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.(getTroves:2)} */
  getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]>;

  async getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]> {
    const { multiTroveGetter } = _getPoolContracts(poolName, this.connection);
    const config = _getPoolBaseConfig(poolName, this.connection);

    expectPositiveInt(params, "first");
    expectPositiveInt(params, "startingAt");

    if (!validSortingOptions.includes(params.sortedBy)) {
      throw new Error(
        `sortedBy must be one of: ${validSortingOptions.map(x => `"${x}"`).join(", ")}`
      );
    }

    const [totalRedistributed, backendTroves] = await Promise.all([
      params.beforeRedistribution
        ? undefined
        : this.getTotalRedistributed(poolName, { ...overrides }),
      multiTroveGetter.getMultipleSortedTroves(
        params.sortedBy === "descendingCollateralRatio"
          ? params.startingAt ?? 0
          : -((params.startingAt ?? 0) + 1),
        params.first,
        { ...overrides }
      )
    ]);

    const troves = mapBackendTroves(config, backendTroves);

    if (totalRedistributed) {
      return troves.map(trove => trove.applyRedistribution(totalRedistributed));
    } else {
      return troves;
    }
  }

  /** @internal */
  _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return _getBlockTimestamp(this.connection, blockTag);
  }

  /** @internal */
  async _getFeesFactory(
    poolName: string,
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    const { troveManager } = _getPoolContracts(poolName, this.connection);
    const config = _getPoolBaseConfig(poolName, this.connection);
    const { multicall } = _getSharedContracts(this.connection);
    overrides ??= _getOverride(overrides);

    const calls = [
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("lastFeeOperationTime", [])
      },
      {
        target: troveManager.address,
        callData: troveManager.interface.encodeFunctionData("baseRate", [])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const lastFeeOperationTime = troveManager.interface.decodeFunctionResult(
      "lastFeeOperationTime",
      multicallResult[0]
    );
    const baseRateWithoutDecay = troveManager.interface.decodeFunctionResult(
      "baseRate",
      multicallResult[1]
    );

    return (blockTimestamp, recoveryMode) =>
      new Fees(
        config.minimumBorrowingRate,
        decimalify(BigNumber.from(baseRateWithoutDecay.toString())),
        MINUTE_DECAY_FACTOR,
        BETA,
        convertToDate(BigNumber.from(lastFeeOperationTime.toString()).toNumber()),
        convertToDate(blockTimestamp),
        recoveryMode
      );
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getFees} */
  async getFees(poolName: string, overrides?: EthersCallOverrides): Promise<Fees> {
    const [createFees, total, price, blockTimestamp] = await Promise.all([
      this._getFeesFactory(poolName, overrides),
      this.getTotal(poolName, overrides),
      this.getPrice(poolName, overrides),
      this._getBlockTimestamp(overrides?.blockTag)
    ]);

    return createFees(blockTimestamp, total.collateralRatioIsBelowCritical(price));
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLQTYStake} */
  async getLQTYStake(address?: string, overrides?: EthersCallOverrides): Promise<LQTYStake> {
    overrides ??= _getOverride(overrides);
    address ??= _requireAddress(this.connection);
    const { lqtyStaking, multicall } = _getSharedContracts(this.connection);

    const calls = [
      {
        target: lqtyStaking.address,
        callData: lqtyStaking.interface.encodeFunctionData("stakes", [address])
      },
      {
        target: lqtyStaking.address,
        callData: lqtyStaking.interface.encodeFunctionData("getPendingETHGain", [address])
      },
      {
        target: lqtyStaking.address,
        callData: lqtyStaking.interface.encodeFunctionData("getPendingLUSDGain", [address])
      }
    ];

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const [stakedLQTY, collateralGain, lusdGain] = multicallResult.map(res => {
      // does'nt count different contracts and method names because they all have the same return type - BigNumber
      const decoded = lqtyStaking.interface.decodeFunctionResult("stakes", res);
      return decimalify(BigNumber.from(decoded.toString()));
    });

    return new LQTYStake(stakedLQTY, collateralGain, lusdGain);
  }

  async getPoolNameWithLowestCR(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    const { redemptionManager } = _getBorrowingOptionContracts(borrowingTokenName, this.connection);
    const tm = await redemptionManager.callStatic.getPoolWithLowestCR(overrides);
    const allPools = _getPoolsContracts(this.connection);

    const cmpAddress = (a: string, b: string) => getAddress(a) === getAddress(b);

    const poolName = Object.entries(allPools).find(
      ([_, contracts]) =>
        cmpAddress(contracts.collateralToken.address, tm.collateralToken) &&
        cmpAddress(contracts.borrowingToken.address, tm.borrowingToken)
    )?.[0];

    // if(!poolName) throw new Error('Proper TM wasnt found');

    return poolName ?? "unknown";
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalStakedLQTY} */
  async getTotalStakedLQTY(overrides?: EthersCallOverrides): Promise<Decimal> {
    const { lqtyStaking } = _getSharedContracts(this.connection);

    return lqtyStaking.totalLQTYStaked({ ...overrides }).then(decimalify);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getFrontendStatus} */
  async getFrontendStatus(
    borrowingName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<FrontendStatus> {
    address ??= _requireFrontendAddress(this.connection);
    const { stabilityPool } = _getBorrowingOptionContracts(borrowingName, this.connection);

    const { registered, kickbackRate } = await stabilityPool.frontEnds(address, { ...overrides });

    return registered
      ? { status: "registered", kickbackRate: decimalify(kickbackRate) }
      : { status: "unregistered" };
  }

  async getUserReferrals(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<ReferralsWithDate> {
    address ??= _requireAddress(this.connection);
    const referralCenter = _getSharedContracts(this.connection).referralCenter;

    const eventFilters = referralCenter.filters.AddReward(null, address, null, null, null, null);

    const eventsFiltered = await referralCenter
      .queryFilter(eventFilters)
      .then(events => events.map(e => e.args));

    const result: ReferralWithDateType = {};

    for (const event of eventsFiltered) {
      if (typeof event?.[2] === "string" && typeof event?.[3] === "string") {
        if (!result[event[2]]) {
          result[event[2]] = {
            expireDate: new Date(
              (await referralCenter.registrationTimestamp(event[2] ?? ""))
                .add(365 * 24 * 60 * 60)
                .mul(1000)
                .toNumber()
            ),
            total: {
              [event[3]]: event?.[4] ?? ethers.constants.Zero
            }
          };
        } else {
          if (!result[event[2]].total[event[3]]) {
            result[event[2]].total[event[3]] = event?.[4] ?? ethers.constants.Zero;
          } else {
            result[event[2]].total[event[3]] = result[event[2]].total[event[3]].add(
              event?.[4] ?? ethers.constants.Zero
            );
          }
        }
      }
    }

    return new ReferralsWithDate(result);
  }

  async getClaimedEarnings(address?: string, overrides?: EthersCallOverrides): Promise<Referrals> {
    address ??= _requireAddress(this.connection);
    const referralCenter = _getSharedContracts(this.connection).referralCenter;

    const eventFilters = referralCenter.filters.ClaimReward(null, address, null, null);

    const eventsFiltered = [
      await referralCenter.queryFilter(eventFilters).then(events => events.map(e => e.args))
    ];

    const result: ReferralsType = {};

    for (const events of eventsFiltered) {
      for (const event of events) {
        if (typeof event?.[1] === "string" && typeof event?.[2] === "string") {
          if (!result[event[1]]) {
            result[event[1]] = {
              [event[2]]: event?.[3] ?? ethers.constants.Zero
            };
          } else {
            if (!result[event[1]][event[2]]) {
              result[event[1]][event[2]] = event?.[3] ?? ethers.constants.Zero;
            } else {
              result[event[1]][event[2]] = result[event[1]][event[2]].add(
                event?.[3] ?? ethers.constants.Zero
              );
            }
          }
        }
      }
    }

    return new Referrals(result);
  }

  async getReferrerEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewardsWithDate> {
    const referrer = await this.getUserReferrer(address);

    const { referralCenter } = _getSharedContracts(this.connection);

    const borrowingOptions = _getBorrowingOptions(this.connection);

    const pools = Object.entries(_getPoolsContracts(this.connection)).map(([poolName, contract]) => {
      const config = this.connection.poolContracts[poolName].baseConfiguration;

      return {
        poolName: poolName,
        address: contract.collateralToken.address,
        decimals: config.collateralTokenDecimals
      };
    });

    const events = await referralCenter.queryFilter(
      referralCenter.filters.AddReward(null, referrer, address),
      undefined,
      overrides?.blockTag
    );

    const result: PendingRewardsType = {};
    for (const event of events) {
      const tokenAddress = event.args?.[3];
      const amount = event.args?.[4];
      let { decimals, poolName } = pools.find(pool => pool.address === tokenAddress) ?? {
        poolName: "",
        address: "",
        decimals: 0
      };
      const foundBorrowingOpt = borrowingOptions.find(v => v.tokenAddress === tokenAddress);

      if (foundBorrowingOpt) {
        poolName = foundBorrowingOpt.optionName;
        decimals = 18;
      }

      if (poolName && amount) {
        const decimal = decimalify(toBase18(amount, 18));
        result[poolName] = result[poolName] ? result[poolName].add(decimal) : decimal;
      }
    }

    return new PendingReferralRewardsWithDate(
      result,
      new Date(
        (await referralCenter.registrationTimestamp(address ?? ""))
          .add(365 * 24 * 60 * 60)
          .mul(1000)
          .toNumber()
      )
    );
  }

  async getPendingEarningAmount(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this.getAllPendingReferralRewards(address, overrides);
  }

  async getSPInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, SPInfoByUser>> {
    const user = address ?? _requireAddress(this.connection);
    const borrowingOptions = _getBorrowingOptions(this.connection).map(o => o.optionName);
    const { multicall } = _getSharedContracts(this.connection);

    const res: Record<string, SPInfoByUser> = {};

    await Promise.all(
      borrowingOptions.map(async option => {
        const { stabilityPool: sp } = _getBorrowingOptionContracts(option, this.connection);
        const tokens = Object.entries(_getPoolsContracts(this.connection))
          .filter(([, contract]) => contract.stabilityPool.address === sp.address)
          .map(([name, contract]) => ({
            name,
            address: contract.collateralToken.address
          }));

        const [poolSize, deposit, balance, supply] = await Promise.all([
          this.getLUSDInStabilityPool(option, overrides),
          this.getStabilityDeposit(option, user, overrides),
          this.getLUSDBalance(option, user, overrides),
          this.getLUSDSupply(option, overrides)
        ]);

        const userTotalPendingReward = deposit.collateralGain;
        const totalPoolRewards = deposit.totalCollateralRewards;

        let lqtyReward = Decimal.ZERO;

        if (!deposit.isEmpty) {
          lqtyReward = await sp.callStatic.withdrawFromSP(0, false, overrides).then(decimalify);
        }

        const calls = tokens.map(token => {
          return {
            target: sp.address,
            callData: sp.interface.encodeFunctionData("getPoolAssetBalance", [token.address])
          };
        });

        const { returnData: multicallResult } = await multicall.callStatic.aggregate(
          calls,
          overrides
        );

        const result = tokens.map(({ address: tokenAddress, name }, i) => {
          const answer = sp.interface.decodeFunctionResult(
            "getPoolAssetBalance",
            multicallResult[i]
          );
          const decAnswer = decimalify(BigNumber.from(answer.toString()));
          return [
            name,
            decAnswer.isZero
              ? Decimal.ZERO
              : userTotalPendingReward.mul(decAnswer).div(totalPoolRewards)
          ];
        }) as [string, Decimal][];

        const o = [...result, ["ZRO", lqtyReward] as [string, Decimal]];

        console.log("OOOOO", o);

        res[option] = new SPInfoByUser(
          option === "USDZ"
            ? new Price(Decimal.ONE, false)
            : await this.getPrice(`USDC/${option}`, overrides),
          poolSize,
          deposit.currentLUSD,
          new PendingReferralRewards(Object.fromEntries(o)),
          balance,
          supply
        );
      })
    );

    return res;
  }

  async getAllPoolPrices(overrides?: EthersCallOverrides): Promise<Record<string, Price>> {
    overrides ??= _getOverride(overrides);

    const { multicall } = _getSharedContracts(this.connection);

    const pools = _getPoolsContracts(this.connection);

    const functionName = "fetchPrice";

    const calls = Object.entries(pools).map(([_, { priceFeed }]) => {
      return {
        target: priceFeed.address,
        callData: priceFeed.interface.encodeFunctionData(functionName, [])
      };
    });

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const result = Object.entries(pools).map(([poolName, { priceFeed }], i) => {
      const config = _getPoolConfig(poolName, this.connection);
      const answer = priceFeed.interface.decodeFunctionResult(functionName, multicallResult[i]);
      const decimalPrice = decimalify(BigNumber.from(answer.toString()));
      return [poolName, new Price(decimalPrice, config.hasReversedOracle)] as [string, Price];
    });

    return Object.fromEntries(result);
  }

  async getAllPrices(overrides?: EthersCallOverrides): Promise<Record<string, Price>> {
    overrides ??= _getOverride(overrides);

    const contracts = _getFeedsContracts(this.connection);
    const { multicall } = _getSharedContracts(this.connection);

    const tokenAndContracts = Object.entries(contracts);
    const functionName = "latestRoundData";

    const calls = tokenAndContracts.map(([, { feed, decimals }]) => {
      return { target: feed.address, callData: feed.interface.encodeFunctionData(functionName, []) };
    });

    const { returnData: multicallResult } = await multicall.callStatic.aggregate(calls, overrides);

    const result = tokenAndContracts.map(([token, { feed, decimals }], i) => {
      const { answer } = feed.interface.decodeFunctionResult(functionName, multicallResult[i]);
      const decimalPrice = decimalify(toBase18(answer, decimals));
      return [token, new Price(decimalPrice, false)] as [string, Price];
    });

    try {
      result.push(["LQTY", new Price((await fetchLqtyPrice()).lqtyPriceUSD, false)]);
    } catch (e) {
      result.push(["LQTY", this.prices["LQTY"] ?? new Price(Decimal.ZERO, false)]);
    }

    return Object.fromEntries(result);
  }

  async getApproximateStakingAPY(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    const { lqtyStaking } = _getSharedContracts(this.connection);

    const allPools = _getPoolsContracts(this.connection);

    const currentBlock = await this.connection.provider.getBlock(overrides?.blockTag ?? "latest");
    const aproximateBlocksPerDay = 7100;

    const prevMonthBlock = currentBlock.number - aproximateBlocksPerDay * 30;

    // TODO: use multicall
    const usersStake = (await this.getLQTYStake(address)).stakedLQTY;
    const totalStake = await this.getTotalStakedLQTY();

    const usersShareRatePercent = totalStake.mul(usersStake).div(100);

    const lqtyPriceInUSD = this.prices["LQTY"].get();

    const usersStakeInUSD = usersStake.mul(lqtyPriceInUSD);

    const [cEvents, bEvents] = await Promise.all([
      lqtyStaking.queryFilter(lqtyStaking.filters.F_ETHUpdated(null, null), prevMonthBlock),
      lqtyStaking.queryFilter(lqtyStaking.filters.F_LUSDUpdated(null, null), prevMonthBlock)
    ]);

    const totalApproximateMonthRewardInUSD = [...cEvents, ...bEvents].reduce((prev, curr) => {
      const [tokenAddress, amount] = curr.args as [string, BigNumber];

      const pool = Object.entries(allPools).find(
        ([_, val]) =>
          val.borrowingToken.address === tokenAddress || val.collateralToken.address === tokenAddress
      );

      let tokenName: string;

      if (!pool) return prev;

      if (pool[1].collateralToken.address === tokenAddress) {
        tokenName = _splitPoolName(pool[0]).collateralTokenName;
      } else {
        tokenName = _splitPoolName(pool[0]).borrowingTokenName;
      }

      const tokenUsdPrice =
        tokenName === "USDZ"
          ? Decimal.ONE
          : this.prices[tokenName === "ETHZ" ? "WETH" : tokenName].get();
      return Decimal.from(formatUnits(amount)).mul(tokenUsdPrice);
    }, Decimal.ZERO);

    const usersApproximateMonthRewardInUSD = totalApproximateMonthRewardInUSD
      .div(100)
      .mul(usersShareRatePercent);

    const usersApproximateAnnualReward = usersApproximateMonthRewardInUSD.mul(12);

    // % difference formula | a - b | / ((a + b ) / 2)
    return (
      usersApproximateAnnualReward.gt(usersStakeInUSD)
        ? usersApproximateAnnualReward.sub(usersStakeInUSD)
        : usersStakeInUSD.sub(usersApproximateAnnualReward)
    ).div(usersStakeInUSD.add(usersApproximateAnnualReward).div(2));
  }

  async getAllRedemptionFees(
    overrides: EthersCallOverrides
  ): Promise<Record<string, RedemptionFee>> {
    const options = _getBorrowingOptions(this.connection).map(({ optionName }) => optionName);
    const poolContracts = _getPoolsContracts(this.connection);

    const result = await Promise.all(
      options.map(async o => {
        const { redemptionManager, borrowingToken } = _getBorrowingOptionContracts(
          o,
          this.connection
        );
        const poolWithLowestCR = await redemptionManager.callStatic.getPoolWithLowestCR();

        const poolName = Object.entries(poolContracts).find(
          ([, value]) =>
            getAddress(value.collateralToken.address) ===
              getAddress(poolWithLowestCR.collateralToken) &&
            getAddress(value.borrowingToken.address) === getAddress(borrowingToken.address)
        )?.[0];

        if (!poolName)
          throw new Error(
            `Pool is for ${poolWithLowestCR.collateralToken}/${poolWithLowestCR.collateralToken} is not found`
          );

        const fees = await this.getFees(poolName);
        const total = await this.getTotal(poolName);
        const amount = Decimal.from(o === "USDZ" ? 1000 : 10);
        return [
          o,
          new RedemptionFee(
            Decimal.min(
              fees.redemptionRate(amount.div(total.debt)).add(Decimal.from(0.001)),
              Decimal.ONE
            )
          )
        ] as [string, RedemptionFee];
      })
    );

    return Object.fromEntries(result);
  }
}

type Resolved<T> = T extends Promise<infer U> ? U : T;
type BackendTroves = Resolved<ReturnType<MultiTroveGetter["getMultipleSortedTroves"]>>;

const mapBackendTroves = (
  baseConfig: PoolBaseConfig,
  troves: BackendTroves
): TroveWithPendingRedistribution[] =>
  troves.map(
    trove =>
      new TroveWithPendingRedistribution(
        baseConfig,
        trove.owner,
        "open", // These Troves are coming from the SortedTroves list, so they must be open
        decimalify(trove.coll),
        decimalify(trove.debt),
        decimalify(trove.stake),
        new Trove(baseConfig, decimalify(trove.snapshotETH), decimalify(trove.snapshotLUSDDebt))
      )
  );

/**
 * Variant of {@link ReadableEthersLiquity} that exposes a {@link @liquity/lib-base#LiquityStore}.
 *
 * @public
 */
export interface ReadableEthersLiquityWithStore<T extends LiquityStore = LiquityStore>
  extends ReadableEthersLiquity {
  /** An object that implements LiquityStore. */
  readonly store: T;
}

class _BlockPolledReadableEthersLiquity
  implements ReadableEthersLiquityWithStore<BlockPolledLiquityStore>
{
  readonly connection: EthersLiquityConnection;
  readonly store: BlockPolledLiquityStore;

  private _isCacheDisabled = false;

  private readonly _readable: ReadableEthersLiquity;

  public get poolPrices() {
    return this.store.state.poolPrices;
  }

  public get prices() {
    return this.store.state.prices;
  }

  constructor(readable: ReadableEthersLiquity) {
    console.log("_BlockPolledReadableEthersLiquity constructor");

    const store = new BlockPolledLiquityStore(readable);

    this.store = store;
    this.connection = readable.connection;
    this._readable = readable;
  }

  public async useWithoutCache<T>(func: () => Promise<T>): Promise<T> {
    try {
      this._isCacheDisabled = true;
      return await func();
    } finally {
      this._isCacheDisabled = false;
    }
  }

  private _blockHit(overrides?: EthersCallOverrides): boolean {
    return (
      !this._isCacheDisabled &&
      (!overrides ||
        overrides.blockTag === undefined ||
        overrides.blockTag === this.store.state.blockTag)
    );
  }

  private _poolHit(poolName: string, overrides?: EthersCallOverrides): boolean {
    return this._blockHit(overrides) && poolName === this.store.state.selectedPoolName;
  }

  private _borrowingTokenNameHit(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): boolean {
    return (
      this._blockHit(overrides) &&
      _isPoolName(this.store.state.selectedPoolName) &&
      _splitPoolName(this.store.state.selectedPoolName).borrowingTokenName === borrowingTokenName
    );
  }

  private _borrowingTokenNameHitAndUserHit(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): boolean {
    return (
      this._borrowingTokenNameHit(borrowingTokenName, overrides) && this._userHit(address, overrides)
    );
  }

  private _poolHitAndUserHit(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): boolean {
    return this._poolHit(poolName, overrides) && this._userHit(address, overrides);
  }

  private _userHit(address?: string, overrides?: EthersCallOverrides): boolean {
    return (
      this._blockHit(overrides) &&
      (address === undefined || address === this.store.connection.userAddress)
    );
  }

  private _frontendHit(address?: string, overrides?: EthersCallOverrides): boolean {
    return (
      this._blockHit(overrides) &&
      (address === undefined || address === this.store.connection.frontendTag)
    );
  }

  hasStore(store?: EthersLiquityStoreOption): boolean {
    return store === undefined || store === "blockPolled";
  }

  async getUserReferrer(address?: string, overrides?: EthersCallOverrides): Promise<string> {
    return this._blockHit(overrides)
      ? this.store.state.referrer
      : this._readable.getUserReferrer(address, overrides);
  }

  async getTotalRedistributed(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    return this._poolHit(poolName, overrides)
      ? this.store.state.totalRedistributed
      : this._readable.getTotalRedistributed(poolName, overrides);
  }

  async getAllRedemptionFees(
    overrides: EthersCallOverrides
  ): Promise<Record<string, RedemptionFee>> {
    return this._blockHit(overrides)
      ? this.store.state.redemptionFees
      : this._readable.getAllRedemptionFees(overrides);
  }

  async getClaimedSPEarnings(address?: string, overrides?: EthersCallOverrides) {
    return this._userHit(address, overrides)
      ? this.store.state.claimedSPEarnings
      : this._readable.getClaimedSPEarnings(address, overrides);
  }

  async getPendingEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._userHit(address, overrides)
      ? this.store.state.pendingEarnings
      : this._readable.getPendingEarnings(address, overrides);
  }

  async getApproximateStakingAPY(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._userHit(address, overrides)
      ? this.store.state.approximateStakingAPY
      : this._readable.getApproximateStakingAPY(address, overrides);
  }

  async getAllPoolPrices(overrides: EthersCallOverrides): Promise<Record<string, Price>> {
    return this._blockHit(overrides)
      ? this.store.state.poolPrices
      : this._readable.getAllPoolPrices(overrides);
  }

  async getAllPrices(overrides: EthersCallOverrides): Promise<Record<string, Price>> {
    return this._blockHit(overrides)
      ? this.store.state.prices
      : this._readable.getAllPrices(overrides);
  }

  async getTroveBeforeRedistribution(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution> {
    return this._poolHit(poolName, overrides)
      ? this.store.state.troveBeforeRedistribution
      : this._readable.getTroveBeforeRedistribution(poolName, address, overrides);
  }

  async getPoolNameWithLowestCR(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.poolWithLowestCR
      : this._readable.getPoolNameWithLowestCR(borrowingTokenName, overrides);
  }

  async getTrove(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.trove
      : this._readable.getTrove(poolName, address, overrides);
  }

  async getAllPoolsInfo(overrides?: EthersCallOverrides): Promise<Record<string, PoolInfo>> {
    return this._blockHit(overrides)
      ? this.store.state.allPoolsInfo
      : this._readable.getAllPoolsInfo(overrides);
  }

  async getUserReferrals(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<ReferralsWithDate> {
    return this._userHit(address, overrides)
      ? this.store.state.referrals
      : this._readable.getUserReferrals(address, overrides);
  }

  async getClaimedEarnings(address?: string, overrides?: EthersCallOverrides): Promise<Referrals> {
    return this._userHit(address, overrides)
      ? this.store.state.claimedEarnings
      : this._readable.getClaimedEarnings(address, overrides);
  }

  async getAllPoolsInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, PoolInfoByUser>> {
    return this._userHit(address, overrides)
      ? this.store.state.allPoolsInfoByUser
      : this._readable.getAllPoolsInfoByUser(address, overrides);
  }

  async getSPInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, SPInfoByUser>> {
    return this._userHit(address, overrides)
      ? this.store.state.spInfoByUser
      : this._readable.getSPInfoByUser(address, overrides);
  }

  async getNumberOfTroves(poolName: string, overrides?: EthersCallOverrides): Promise<number> {
    return this._poolHit(poolName, overrides)
      ? this.store.state.numberOfTroves
      : this._readable.getNumberOfTroves(poolName, overrides);
  }

  async getPrice(poolName: string, _?: EthersCallOverrides): Promise<Price> {
    const price = this.store.state.poolPrices[poolName] ?? new Price(Decimal.ZERO, false);
    console.log("GET PRICE FOR POOL", price.get().toString());
    return price;
  }

  async getTotal(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    return this._poolHit(poolName, overrides)
      ? this.store.state.total
      : this._readable.getTotal(poolName, overrides);
  }

  async getAllPendingReferralRewards(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._userHit(address, overrides)
      ? this.store.state.pendingReferralRewards
      : this._readable.getAllPendingReferralRewards(address, overrides);
  }

  async getStabilityDeposit(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<StabilityDeposit> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.stabilityDeposit
      : this._readable.getStabilityDeposit(borrowingTokenName, address, overrides);
  }

  async getRemainingStabilityPoolLQTYReward(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.remainingStabilityPoolLQTYReward
      : this._readable.getRemainingStabilityPoolLQTYReward(borrowingTokenName, overrides);
  }

  async getLUSDInStabilityPool(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.lusdInStabilityPool
      : this._readable.getLUSDInStabilityPool(borrowingTokenName, overrides);
  }

  async getLUSDSupply(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.lusdSupply
      : this._readable.getLUSDSupply(borrowingTokenName, overrides);
  }

  async getLUSDBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.lusdBalance
      : this._readable.getLUSDBalance(borrowingTokenName, address, overrides);
  }

  async getLQTYBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._userHit(address, overrides)
      ? this.store.state.lqtyBalance
      : this._readable.getLQTYBalance(address, overrides);
  }

  async getAssetInfoByUser(poolName: string, address?: string, overrides?: EthersCallOverrides) {
    return this._readable.getAssetInfoByUser(poolName, address, overrides);
  }

  async getAssetBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.assetBalance
      : this._readable.getAssetBalance(poolName, address, overrides);
  }

  async getAssetAllowance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.assetTokenAllowance
      : this._readable.getAssetAllowance(poolName, address, overrides);
  }

  async getAssetSymbol(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.assetSymbol
      : this._readable.getAssetSymbol(poolName, address, overrides);
  }

  async getUniTokenBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.uniTokenBalance
      : this._readable.getUniTokenBalance(borrowingTokenName, address, overrides);
  }

  async getUniTokenAllowance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.uniTokenAllowance
      : this._readable.getUniTokenAllowance(borrowingTokenName, address, overrides);
  }

  async getRemainingLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.remainingLiquidityMiningLQTYReward
      : this._readable.getRemainingLiquidityMiningLQTYReward(borrowingTokenName, overrides);
  }

  async getPendingEarningAmount(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._userHit(address, overrides)
      ? this.store.state.pendingEarningAmount
      : this._readable.getPendingEarningAmount(address, overrides);
  }

  async getReferrerEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewardsWithDate> {
    return this._userHit(address, overrides)
      ? this.store.state.referrerEarnings
      : this._readable.getReferrerEarnings(address, overrides);
  }

  async getLiquidityMiningStake(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.liquidityMiningStake
      : this._readable.getLiquidityMiningStake(borrowingTokenName, address, overrides);
  }

  async getTotalStakedUniTokens(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHit(borrowingTokenName, overrides)
      ? this.store.state.totalStakedUniTokens
      : this._readable.getTotalStakedUniTokens(borrowingTokenName, overrides);
  }

  async getLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._borrowingTokenNameHitAndUserHit(borrowingTokenName, address, overrides)
      ? this.store.state.liquidityMiningLQTYReward
      : this._readable.getLiquidityMiningLQTYReward(borrowingTokenName, address, overrides);
  }

  async getCollateralSurplusBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.collateralSurplusBalance
      : this._readable.getCollateralSurplusBalance(poolName, address, overrides);
  }

  async _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return this._blockHit({ blockTag })
      ? this.store.state.blockTimestamp
      : this._readable._getBlockTimestamp(blockTag);
  }

  async _getFeesFactory(
    poolName: string,
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    return this._poolHit(poolName, overrides)
      ? this.store.state._feesFactory
      : this._readable._getFeesFactory(poolName, overrides);
  }

  async getFees(poolName: string, overrides?: EthersCallOverrides): Promise<Fees> {
    return this._poolHit(poolName, overrides)
      ? this.store.state.fees
      : this._readable.getFees(poolName, overrides);
  }

  async getLQTYStake(address?: string, overrides?: EthersCallOverrides): Promise<LQTYStake> {
    return this._userHit(address, overrides)
      ? this.store.state.lqtyStake
      : this._readable.getLQTYStake(address, overrides);
  }

  async getTotalStakedLQTY(overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._blockHit(overrides)
      ? this.store.state.totalStakedLQTY
      : this._readable.getTotalStakedLQTY(overrides);
  }

  async getFrontendStatus(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<FrontendStatus> {
    return this._poolHitAndUserHit(poolName, address, overrides)
      ? this.store.state.frontend
      : this._readable.getFrontendStatus(poolName, address, overrides);
  }

  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution[]>;

  getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]>;

  getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]> {
    return this._readable.getTroves(poolName, params, overrides);
  }

  _getRemainingLiquidityMiningLQTYRewardCalculator(): Promise<(blockTimestamp: number) => Decimal> {
    throw new Error("Method not implemented.");
  }
}

export const isBlockPooledReadableStore = (
  store: ReadableEthersLiquity | _BlockPolledReadableEthersLiquity
): store is _BlockPolledReadableEthersLiquity => {
  return Boolean((store as _BlockPolledReadableEthersLiquity).useWithoutCache);
};
