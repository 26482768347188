import React, {useEffect} from "react";
import BgLoader from "../../assets/background/bg-loader.png";
import { Flex } from "@chakra-ui/react";
import { Text } from "../Text";
import { Button } from "../Button";
import { useAccount, useSwitchNetwork } from "wagmi";
import { SUPPORTED_NETWORKS } from "../../constants/networks";
import { useWeb3Modal } from "@web3modal/react";
import {useNavigate} from "react-router-dom";

export enum LoaderType {
  UNSUPPORTED_NETWORK = 'unsupported_network',
  UNSUPPORTED_MAINNET = 'unsupported_mainnet',
}

type LoaderProps = {
  type?: LoaderType;
}

const loaderLabel: Record<LoaderType, string> = {
  [LoaderType.UNSUPPORTED_MAINNET]: "Unsupported Mainnet",
  [LoaderType.UNSUPPORTED_NETWORK]: "Unsupported Network",
};

export const Loader: React.FC<LoaderProps> = ({ type }) => {
  const { switchNetworkAsync } = useSwitchNetwork();
  const { isConnected } = useAccount();
  const { open, isOpen } = useWeb3Modal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected && !isOpen && !type) {
      navigate("/");
    }
  }, [isConnected, isOpen, navigate, type]);

  return (
    <Flex
      direction="column"
      height="100vh"
      bgImage={BgLoader}
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      alignItems="center"
      pt="100px"
      gap="30px"
      px="16px"
    >
      <div dangerouslySetInnerHTML={{ __html: `<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_5l6XPs.json"  background="transparent" speed="2" style="width: 336px; height: 336px;" loop autoplay></lottie-player>`}}></div>
      {type && (
        <Text color="text.600" fontSize="32px" textAlign="center">
          {loaderLabel[type]}
        </Text>
      )}
      {!type && !isConnected && !isOpen && <Button maxW="343px" w="100%" onClick={() => open({ route: "ConnectWallet" })}>Connect Wallet</Button>}
      {type === LoaderType.UNSUPPORTED_NETWORK && <Button maxW="343px" w="100%" onClick={() => switchNetworkAsync?.(SUPPORTED_NETWORKS[0].id)}>Switch Network</Button>}
    </Flex>
  );
};
