import React from "react";
import {Flex, Image, useConst, useHighlight} from "@chakra-ui/react";
import { Text } from "../Text";
import { Button } from "../Button";
import BorrowIcon from "../../assets/landing/borrow-icon.svg";
import EarnIcon from "../../assets/landing/earn-icon.svg";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { SwiperWithPaginator } from "../Swiper";
import { SwiperSlide } from "swiper/react";
import {useNavigate} from "react-router-dom";

type LandingCardProps = {
  icon: string;
  title: string;
  labels: string[];
  gradient: string;
  onButtonClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
};

export const LandingCards: React.FC = () => {
  const navigate = useNavigate();
  const infos = useConst(() => [
    {
      icon: BorrowIcon,
      title: "Borrow",
      labels: ["0% Interest Rate", "90% Loan-to-Value", "Most Collateral Assets"],
      gradient: "linear-gradient(141.14deg, #D7FAFF 1.21%, #F7FFEC 98.03%)",
      onClick: () => navigate("/app/borrow")
    },
    {
      icon: EarnIcon,
      title: "Earn",
      labels: ["Yields up to ~10% APY", "Earn on USD or ETH", "Safe  "],
      gradient: "linear-gradient(140.46deg, #D7ECFF 0%, #F0E2FF 98.83%)",
      onClick: () => navigate("/app/earn")
    }
  ]);
  const { isMobileOrTablet } = useMatchBreakpoints();

  return isMobileOrTablet ? (
    <SwiperWithPaginator paginatorType="dot" posLength={infos.length} pageSize={1}>
      {infos.map(({ title, gradient, icon, labels, onClick }) => (
        <SwiperSlide key={title}>
          <Flex width="100%" alignItems="center" justifyContent="center" mt="70px">
            <LandingCard
              title={title}
              gradient={gradient}
              icon={icon}
              labels={labels}
              onButtonClick={onClick}
            />
          </Flex>
        </SwiperSlide>
      ))}
    </SwiperWithPaginator>
  ) : (
    <Flex mt="88px" justifyContent="center" gap="30px">
      {infos.map(({ title, gradient, icon, labels, onClick }) => (
        <LandingCard
          key={title}
          title={title}
          gradient={gradient}
          icon={icon}
          labels={labels}
          onButtonClick={onClick}
        />
      ))}
    </Flex>
  );
};

export const LandingCard: React.FC<LandingCardProps> = ({
  gradient,
  labels,
  title,
  icon,
  onButtonClick
}) => {
  return (
    <Flex
      position="relative"
      direction="column"
      pb="35px"
      alignItems="center"
      bg={gradient}
      borderRadius="20px"
      w="100%"
      maxW="625px"
      filter="drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.16))"
      mx="16px"
    >
      <Image src={icon} alt={title} position="absolute" top="-50px" />
      <Text fontSize="24px" fontWeight={600} mb="16px" mt="70px">
        {title}
      </Text>
      <Flex
        direction="column"
        bg="rgba(255,255,255,0.5)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
        py="24px"
        gap="24px"
        alignItems="center"
        mb="35px"
        w="100%"
      >
        {labels.map(label => (
          <Text key={label} fontSize="20px" fontWeight={500} color="text.200">
            {label}
          </Text>
        ))}
      </Flex>
      <Button maxW="280px" width="100%" mx="80px" onClick={onButtonClick}>
        {title}
      </Button>
    </Flex>
  );
};
