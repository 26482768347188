import { AddressZero } from "@ethersproject/constants";

export type LiquityFrontendConfig = {
  frontendTag: string;
  infuraApiKey?: string;
  testnetOnly?: boolean;
};

export const getConfig = (): LiquityFrontendConfig => {
  return {
    frontendTag: AddressZero,
    infuraApiKey: undefined,
    testnetOnly: undefined
  };
};
