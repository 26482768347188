import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Flex,
  Link,
  ListItem,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader,
  Text,
  ModalOverlay,
  UnorderedList, useDisclosure,
  useToast
} from "@chakra-ui/react";
import { InputGroup, Option } from "../components/InputGroup";
import { AppPage } from "../types/AppPage";
import TransactionsBlock, { TransactionItemProps } from "../components/TransactionsBlock";
import { LearnAnd } from "../components/LearnAnd";
import {
  LiquityStoreUpdate,
  useLiquityReducer,
  useLiquitySelector,
  useLiquityStore
} from "@liquity/lib-react";
import {
  Decimal,
  Decimalish,
  LiquityStoreState,
  PoolBaseConfig,
  Price,
  Trove,
  UserTrove
} from "@liquity/lib-base";
import { useLiquity, useLiquityPool } from "../hooks/LiquityContext";
import { selectForTroveChangeValidation, validateTroveChange } from "../helpers/validateTroveChange";
import { TransactionState, useTransactionFunction } from "../hooks/useTransactionFunction";
import { useStableTroveChange } from "../hooks/useStableTroveChange";
import { NavLink } from "react-router-dom";
import { learnMoreLink } from "../constants/links";
import { constants, ethers } from "ethers";
import { useReferrer } from "../hooks/useReferrer";
import { _getPoolName, _splitPoolName } from "@liquity/lib-ethers/dist/src/contracts";
import { ArrowIcon } from "../components/ArrowIcon";
import { useAccount } from "wagmi";
import { boolean } from "hardhat/internal/core/params/argumentTypes";
import { matchErrorMessage } from "../helpers/regex";
import {Button} from "../components/Button";

const select = (state: LiquityStoreState) => ({
  price: state.price,
  prices: state.prices,
  selectedPoolName: state.selectedPoolName,
  accountBalance: state.accountBalance,
  allPoolsInfo: state.allPoolsInfo,
  allPoolsInfoByUser: state.allPoolsInfoByUser,
  spInfoByUser: state.spInfoByUser,
  fees: state.fees,
  total: state.total,
  numberOfTroves: state.numberOfTroves,
  validationContext: selectForTroveChangeValidation(state),
  allowance: state.allPoolsInfoByUser[state.selectedPoolName].assetAllowanceToBO,
  lusdBalance: state.lusdBalance
});

const headers: string[] = ["Loan", "Collateral", "Collateral Ratio (%)", "Liquidation Price", ""];

const init =
  (baseConfig: PoolBaseConfig) =>
  ({ trove }: LiquityStoreState) => {
    return {
      original: new UserTrove(
        baseConfig,
        trove.ownerAddress,
        trove.status,
        trove.collateral,
        trove.debt
      ),
      edited: new Trove(baseConfig, trove.collateral, trove.debt),
      changePending: false,
      debtDirty: false,
      addedMinimumDebt: false
    };
  };

type TroveManagerState = ReturnType<ReturnType<typeof init>>;
type TroveManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" | "addMinimumDebt" | "removeMinimumDebt" }
  | { type: "setCollateral" | "setDebt"; newValue: Decimalish };

export const feeFrom = (original: Trove, edited: Trove, borrowingRate: Decimal): Decimal => {
  const change = original.whatChanged(edited, borrowingRate);

  console.log("FEE FROM", change);

  if (change && change.type !== "invalidCreation" && change.params.borrowLUSD) {
    return change.params.borrowLUSD.mul(borrowingRate);
  } else {
    return Decimal.ZERO;
  }
};

const EMPTY_TROVE = (config: PoolBaseConfig) => new Trove(config, Decimal.ZERO, Decimal.ZERO);

export const Borrow = () => {
  const toast = useToast();
  const { liquity } = useLiquity();
  const store = useLiquityStore();

  const allPools = useMemo(() => liquity.getAllPools(), [liquity]);

  const allCollateralOptions = useMemo(() => liquity.getAllCollateralOptions(), [liquity]);
  const allBorrowingOptions = useMemo(
    () =>
      liquity.getAllBorrowingOptions().sort((a, b) => (a[1].optionName > b[1].optionName ? -1 : 1)),
    [liquity]
  );
  const [isFull, setIsFull] = useState(false);

  const {
    selectedPoolName,
    prices,
    accountBalance,
    allPoolsInfo,
    allPoolsInfoByUser,
    fees,
    total,
    numberOfTroves,
    validationContext,
    spInfoByUser,
    allowance
  } = useLiquitySelector(select);
  const referrer = useReferrer();

  const {
    pool: {
      contractsConfig: { baseConfiguration }
    }
  } = useLiquityPool(selectedPoolName);

  const reduce = useCallback(
    (state: TroveManagerState, action: TroveManagerAction): TroveManagerState => {
      const { original, edited, changePending, debtDirty, addedMinimumDebt } = state;

      switch (action.type) {
        case "startChange": {
          return { ...state, changePending: true };
        }

        case "finishChange":
          return { ...state, changePending: false };

        case "setCollateral": {
          const newCollateral = Decimal.from(action.newValue);

          const newState = {
            ...state,
            edited: edited.setBaseConfig(baseConfiguration).setCollateral(newCollateral)
          };

          if (!debtDirty) {
            if (edited.setBaseConfig(baseConfiguration).isEmpty && newCollateral.nonZero) {
              return addMinimumDebt(newState);
            }
            if (addedMinimumDebt && newCollateral.isZero) {
              return removeMinimumDebt(newState);
            }
          }

          return newState;
        }

        case "setDebt":
          return {
            ...state,
            edited: edited.setBaseConfig(baseConfiguration).setDebt(action.newValue),
            debtDirty: true
          };

        case "addMinimumDebt":
          return {
            ...state,
            edited: edited
              .setBaseConfig(baseConfiguration)
              .setDebt(baseConfiguration.borrowingTokenMinimumDebt),
            addedMinimumDebt: true
          };

        case "removeMinimumDebt":
          return {
            ...state,
            edited: edited.setBaseConfig(baseConfiguration).setDebt(0),
            addedMinimumDebt: false
          };

        case "revert":
          return {
            ...state,
            edited: new Trove(
              baseConfiguration,
              original.setBaseConfig(baseConfiguration).collateral,
              original.setBaseConfig(baseConfiguration).debt
            ),
            debtDirty: false,
            addedMinimumDebt: false
          };

        case "updateStore": {
          const {
            newState: { trove },
            stateChange: { troveBeforeRedistribution: changeCommitted }
          } = action;

          const newState = {
            ...state,
            original: new UserTrove(
              baseConfiguration,
              trove.ownerAddress,
              trove.status,
              trove.collateral,
              trove.debt
            )
          };

          if (changePending && changeCommitted) {
            return finishChange(revert(newState));
          }

          const change = original
            .setBaseConfig(baseConfiguration)
            .whatChanged(edited.setBaseConfig(baseConfiguration));

          if (
            (change?.type === "creation" && !trove.isEmpty) ||
            (change?.type === "invalidCreation" && !trove.isEmpty) ||
            (change?.type === "closure" && trove.isEmpty)
          ) {
            return revert(newState);
          }

          return { ...newState, edited: trove.setBaseConfig(baseConfiguration).apply(change) };
        }
      }
    },
    [baseConfiguration]
  );

  const reduceWith = useCallback(
    (action: TroveManagerAction) =>
      (state: TroveManagerState): TroveManagerState =>
        reduce(state, action),
    [reduce]
  );

  const addMinimumDebt = useMemo(() => reduceWith({ type: "addMinimumDebt" }), [reduceWith]);
  const removeMinimumDebt = useMemo(() => reduceWith({ type: "removeMinimumDebt" }), [reduceWith]);
  const finishChange = useMemo(() => reduceWith({ type: "finishChange" }), [reduceWith]);
  const revert = useMemo(() => reduceWith({ type: "revert" }), [reduceWith]);

  const [{ original, edited }, dispatch] = useLiquityReducer(reduce, init(baseConfiguration));

  const [borrowingOptions, setBorrowingOptions] = useState<Option[]>(() =>
    allBorrowingOptions.map(([_, bOption]) => ({
      selectOption: bOption.optionName,
      symbol: bOption.optionName,
      price: new Price(Decimal.ZERO, false),
      upTo: Decimal.from(0),
      apy: Decimal.ZERO,
      poolSize: Decimal.ZERO
    }))
  );

  const [collateralOptions, setCollateralOptions] = useState<Option[]>(() => [
    {
      selectOption: "ETH",
      symbol: "ETH",
      upTo: Decimal.ZERO,
      price: prices["WETH"] ?? new Price(Decimal.ZERO, false),
      apy: Decimal.ZERO,
      poolSize: Decimal.ZERO
    },
    ...allCollateralOptions.map(p => ({
      selectOption: p,
      symbol: p,
      upTo: Decimal.ZERO,
      price: prices[p] ?? new Price(Decimal.ZERO, false),
      apy: Decimal.ZERO,
      poolSize: Decimal.ZERO
    }))
  ]);

  const getAvailableCollateralOptions = (borrowingOption: Option) => {
    const availablePools = allPools
      .map(v => v[0])
      .map(_splitPoolName)
      .filter(v => borrowingOption.selectOption === v.borrowingTokenName);

    const res = collateralOptions.filter(v =>
      availablePools.find(ap => {
        if (ap.collateralTokenName === "WETH") {
          return ap.collateralTokenName === v.selectOption || v.selectOption === "ETH";
        }
        return ap.collateralTokenName === v.selectOption;
      })
    );

    return res;
  };

  const [selectedBorrowingOption, setSelectedBorrowingOption] = useState<Option>(
    () => borrowingOptions[0]
  );

  const [availableCollateralOptions, setAvailableCollateralOptions] = useState<Option[]>(() =>
    getAvailableCollateralOptions(selectedBorrowingOption)
  );

  const updateAvailableCollateralOptions = (borrowingOption: Option) => {
    const newOptions = getAvailableCollateralOptions(borrowingOption);
    setAvailableCollateralOptions(newOptions);
    return newOptions;
  };

  const getCurrentCollateralOption = () =>
    availableCollateralOptions.filter(
      p => p.selectOption === _splitPoolName(selectedPoolName).collateralTokenName
    )[0];

  const [selectedCollateralOption, setSelectedCollateralOption] = useState<Option>(
    () => availableCollateralOptions[0]
  );

  const positions: TransactionItemProps[] = Object.keys(allPoolsInfoByUser)
    .filter(key => !allPoolsInfoByUser[key]?.trove.collateral.isZero)
    .map(key => ({
      first: {
        value: allPoolsInfoByUser[key].trove.debt.toNumber(2),
        symbol: _splitPoolName(key).borrowingTokenName
      },
      second: {
        value: allPoolsInfoByUser[key].trove.collateral.toNumber(2),
        symbol: _splitPoolName(key).collateralTokenName
      },
      third: {
        value: allPoolsInfoByUser[key].trove
          .collateralRatio(allPoolsInfo[key].price)
          .mul(100)
          .toNumber(2),
        symbol: "%"
      },
      fourth: {
        value: allPoolsInfoByUser[key].trove
          .liquidationPrice(allPoolsInfo[key].price)
          .get()
          .mul(
            _splitPoolName(key).borrowingTokenName === "ETHZ" &&
              _splitPoolName(key).collateralTokenName !== "USDC"
              ? prices["WETH"]?.price ?? Decimal.ONE
              : Decimal.ONE
          )
          .toNumber(2),
        symbol: "$"
      }
    }))
    .sort(({ third: { value: a } }, { third: { value: b } }) => a - b);

  const [selectedTxItem, setSelectedTxItem] = useState<TransactionItemProps | undefined>(undefined);

  useEffect(() => {
    if (!selectedPoolName) return;
    setSelectedCollateralOption(getCurrentCollateralOption());
    // setAmount2(baseConfiguration.borrowingTokenMinimumDebt.toNumber());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBorrowingOption.selectOption]);

  const onFirstInputChange = (option: Option) => {
    if (!selectedBorrowingOption) return;

    const poolName = _getPoolName(
      option.selectOption === "ETH" ? "WETH" : option.selectOption,
      selectedBorrowingOption.selectOption
    );

    setSelectedCollateralOption(option);
    // setAmount1(0);
    // setAmount2(0);

    store.update({
      selectedPoolName: poolName
    });
  };

  const onSecondInputChange = (option: Option) => {
    const newSelectedCollateralOption = selectedTxItem
      ? availableCollateralOptions.find(o => o.selectOption === selectedTxItem.second.symbol)
      : updateAvailableCollateralOptions(option)[0];

    if (!newSelectedCollateralOption) return;

    const poolName = _getPoolName(
      newSelectedCollateralOption.selectOption === "ETH"
        ? "WETH"
        : newSelectedCollateralOption.selectOption,
      option.selectOption
    );

    setSelectedBorrowingOption(option);
    // setAmount1(0);
    // setAmount2(0);

    store.update({
      selectedPoolName: poolName
    });
  };

  const updateOptionField = <T extends keyof Option, K extends Option[T]>(
    poolName: string,
    prop: T,
    value: K
  ) => {
    const opt = availableCollateralOptions.find(
      opt => opt.selectOption === _splitPoolName(poolName).collateralTokenName
    )!;
    if (!opt) return;
    opt[prop] = value;
    setCollateralOptions(prev => [...prev]);
  };

  useEffect(() => {
    updateOptionField(
      selectedPoolName,
      "price",
      prices[_splitPoolName(selectedPoolName).collateralTokenName]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoolName, selectedBorrowingOption, prices]);

  useEffect(() => {
    const ethOpt = collateralOptions.find(o => o.selectOption === "ETH");
    if (ethOpt) {
      ethOpt.price = prices["WETH"];
      ethOpt.upTo = accountBalance;
    }
    setCollateralOptions(prev => [...prev]);
  }, [accountBalance, prices]);

  useEffect(() => {
    updateOptionField(selectedPoolName, "upTo", allPoolsInfoByUser[selectedPoolName].assetBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoolName, selectedBorrowingOption, allPoolsInfoByUser[selectedPoolName].assetBalance]);

  const onRepayLoanClick = (txIndex: number) => {
    setIsFull(false);
    const position = positions.find((_, i) => i === txIndex);
    setSelectedTxItem(position);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const [amount1, setAmount1] = useState<number>(0);
  const [amount2, setAmount2] = useState<number>(0);

  const borrowingRate = fees.borrowingRate();
  const maxBorrowingRate = borrowingRate.add(0.005);

  const [validChange] = validateTroveChange(
    original ?? EMPTY_TROVE(baseConfiguration),
    edited,
    borrowingRate,
    {
      price: allPoolsInfo[selectedPoolName]?.price ?? new Price(Decimal.ZERO, false),
      assetBalance: allPoolsInfoByUser[selectedPoolName]?.assetBalance ?? Decimal.ZERO,
      lusdBalance: spInfoByUser[selectedBorrowingOption.selectOption].balance ?? Decimal.ZERO,
      assetSymbol:
        (selectedCollateralOption?.selectOption === "ETH"
          ? "WETH"
          : selectedCollateralOption?.selectOption) ?? "WETH",
      numberOfTroves,
      total
    }
  );

  const stableTroveChange = useStableTroveChange(validChange);
  const troveChangeToUse = original ? validChange : stableTroveChange;
  console.log("CHANGE:", troveChangeToUse);

  const borrowingFeeDecayToleranceMinutes = 60;

  const [approve, approveState, setApproveState] = useTransactionFunction(
    "approve",
    liquity.send.collateralTokenApproveToBO.bind(liquity.send, selectedPoolName, undefined)
  );

  const [sendTransaction, state, setTxState] = useTransactionFunction(
    `trove-${troveChangeToUse?.type}`,
    () =>
      selectedTxItem && edited.collateral.isZero && edited.debt.isZero
        ? liquity.send.closeTrove.call(
            liquity.send,
            selectedPoolName,
            selectedCollateralOption.selectOption === "ETH",
            undefined
          )
        : troveChangeToUse && troveChangeToUse?.type === "creation"
        ? liquity.send.openTrove.call(
            liquity.send,
            referrer,
            selectedPoolName,
            troveChangeToUse.params,
            {
              maxBorrowingRate,
              borrowingFeeDecayToleranceMinutes
            },
            selectedCollateralOption.selectOption === "ETH",
            undefined
          )
        : liquity.send.adjustTrove.call(
            liquity.send,
            selectedPoolName,
            troveChangeToUse?.params ?? { borrowLUSD: Decimal.from(0) },
            {
              maxBorrowingRate,
              borrowingFeeDecayToleranceMinutes
            },
            selectedCollateralOption.selectOption === "ETH",
            selectedCollateralOption.selectOption === "ETH",
            undefined
          )
  );

  useEffect(() => {
    if (approveState.type === "confirmed" && approveState.id === "approve") {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Transaction confirmed.",
        description: "Asset approved.",
        status: "success",
        duration: 9000,
        isClosable: true
      });
      onClose();
      store.update({
        assetTokenAllowance: Decimal.from(amount1)
      });
      setApproveState({ type: "idle" });
    }
    if (approveState.type === "failed" && approveState.id === "approve") {
      const matchedError = matchErrorMessage(approveState.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setApproveState({ type: "idle" });
    }
    if (
      state.type === "failed" &&
      (state.id === "trove-creation" ||
        state.id === "trove-closure" ||
        state.id === "trove-adjustment")
    ) {
      const matchedError = matchErrorMessage(state.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setTxState({ type: "idle" });
    }
    if (
      state.type === "confirmed" &&
      (state.id === "trove-creation" ||
        state.id === "trove-closure" ||
        state.id === "trove-adjustment")
    ) {
      const title = selectedTxItem ? "Repayment confirmed." : "Loan confirmed.";
      const message = selectedTxItem
        ? `You have successfully repaid loan.`
        : `${Decimal.from(amount2).prettify(2)} ${
            selectedBorrowingOption.selectOption
          } were added to your wallet. ${Decimal.from(amount1).prettify(2)} ${
            selectedCollateralOption.selectOption
          } was locked up as a collateral.`;
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: title,
        description: message,
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setTxState({ type: "idle" });
      if (selectedTxItem) {
        setSelectedTxItem(undefined);
        setIsFull(false);
      }
    }
  }, [approveState, state]);

  useEffect(() => {
    console.log("!", original.collateral.toNumber(), amount1);
    dispatch({
      type: "setCollateral",
      newValue: isFull
        ? 0
        : original.collateral.toNumber() + (selectedTxItem ? -amount1 : amount1) < 0
        ? 0
        : original.collateral.toNumber() + (selectedTxItem ? -amount1 : amount1)
    });
  }, [amount1, selectedTxItem, original, isFull, dispatch]);

  useEffect(() => {
    dispatch({
      type: "setDebt",
      newValue: isFull
        ? 0
        : original.debt.toNumber() + (selectedTxItem ? -amount2 : amount2) < 0
        ? 0
        : original.debt.toNumber() + (selectedTxItem ? -amount2 : amount2)
    });
  }, [amount2, selectedTxItem, original, isFull, dispatch]);

  useEffect(() => {
    const borrowingOption = borrowingOptions.find(o => o.selectOption === "ETHZ");
    if (borrowingOption) {
      borrowingOption.price = new Price(prices["WETH"].price, false);
      setBorrowingOptions(prev => [...prev]);
    }
  }, [allPoolsInfo]);

  useEffect(() => {
    if (selectedTxItem) {
      const borrowingOption = borrowingOptions.find(
        o => o.selectOption === selectedBorrowingOption.selectOption
      );
      if (borrowingOption) {
        borrowingOption.upTo = Decimal.from(selectedTxItem.first.value ?? 0);
        setBorrowingOptions(prev => [...prev]);
      }
    } else if (
      allPoolsInfo[selectedPoolName] &&
      !allPoolsInfo[selectedPoolName].price.price.isZero
    ) {
      const wouldTriggerRecoveryModeFunc = (amount2: number) => { 
        const total = allPoolsInfo[selectedPoolName].total;

        const newTotalColl = total.collateral.add(Decimal.from(amount1));
        const newTotalDebt = total.debt.add(Decimal.from(amount2));


        const ratio = newTotalColl.mulDiv(allPoolsInfo[selectedPoolName].price.price, newTotalDebt);

        const wouldTriggerRecoveryMode = ratio.lt(baseConfiguration.criticalCollateralRatio);


        const upToLeft = allPoolsInfo[selectedPoolName].price.price
          .mul(original.collateral.add(amount1))
          .div(
            wouldTriggerRecoveryMode
              ? baseConfiguration.criticalCollateralRatio
              : baseConfiguration.minimumCollateralRatio
          );
  
        const upToRight = baseConfiguration.borrowingTokenLiquidationReserve.add(
          Decimal.ZERO // feeFrom(original, edited, borrowingRate),
        );

        return {
          upTo: upToLeft.gte(upToRight.add(original.debt))
            ? upToLeft.sub(upToRight).sub(original.debt)
            : Decimal.ZERO,
          wouldTriggerRecoveryMode
        } 
      }

      let res = wouldTriggerRecoveryModeFunc((Decimal.from(amount2).gt(selectedBorrowingOption.upTo) ? selectedBorrowingOption.upTo : Decimal.from(amount2)).toNumber());
      res = wouldTriggerRecoveryModeFunc(res.upTo.toNumber());
      

      // let upTo = res.upTo;

      // let i = 0;

      // do {
      //   i++;
      //   res = wouldTriggerRecoveryModeFunc(res.upTo.toNumber());
      //   console.log('ITER', {i, w: res.wouldTriggerRecoveryMode })
      // } while(res.wouldTriggerRecoveryMode)
    
      // console.log('ABOBA', {
      //   a2: amount2,
      //   sUpTo: selectedBorrowingOption.upTo.toNumber(),
      //   upTo: res.upTo.toNumber()
      // });


      const borrowingOption = borrowingOptions.find(
        o => o.selectOption === selectedBorrowingOption.selectOption
      );
      if (borrowingOption) {
        borrowingOption.upTo = res.upTo;
        setBorrowingOptions(prev => [...prev]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseConfiguration,
    selectedTxItem,
    allPoolsInfo,
    selectedPoolName,
    original.collateral,
    original.debt,
    amount1,
    amount2,
    selectedBorrowingOption
  ]);

  const onBorrowClick = async () => {
    try {
      if (
        selectedTxItem &&
        validationContext.numberOfTroves === 1 &&
        edited.collateral.isZero &&
        edited.debt.isZero
      ) {
        throw new Error(
          "You're not allowed to close your Trove when there are no other Troves in the system."
        );
      } else if (!edited.collateral.isZero || !edited.debt.isZero) {
        if (edited.debt.eq(original.debt) && edited.collateral.eq(original.collateral)) {
          throw new Error("You should update your position to make transaction.");
        }
        if (baseConfiguration.borrowingTokenMinimumDebt.gt(edited.debt)) {
          const minDebt =
            baseConfiguration.borrowingTokenMinimumDebt.toNumber() +
            (selectedBorrowingOption.selectOption === "USDZ" ? 100 : 1);

          throw new Error(
            selectedTxItem
              ? `Error: Remaining balance on partial loan cannot fall below ${minDebt} ${selectedBorrowingOption.selectOption}.`
              : `Error: You cannot borrow less than ${minDebt} ${selectedBorrowingOption.selectOption}.`
          );
        }
        if (selectedBorrowingOption.upTo.lt(amount2)) {
          throw new Error("You cannot borrow more than allowed.");
        }
        if (selectedCollateralOption.upTo.lt(amount1)) {
          throw new Error("Not enough balance.");
        }
        if (edited.collateralRatio(selectedCollateralOption.price).toNumber() <= 1.1) {
          throw new Error("Collaterization ratio should be more than 110%.");
        }
      }
      if (selectedTxItem && spInfoByUser[selectedBorrowingOption.selectOption].balance.lt(amount2)) {
        throw new Error(
          `Cannot repay more than your ${selectedBorrowingOption.selectOption} balance.`
        );
      }
      if (
        selectedCollateralOption.selectOption !== "ETH" &&
        original.collateral.lt(edited.collateral) &&
        allowance.lt(edited.collateral.sub(original.collateral)) &&
        !selectedTxItem
      ) {
        // await approve();
        onOpen();
      } else {
        await sendTransaction();
      }
    } catch (e) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: e instanceof Error ? e.message : String(e),
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  const onFullChange = (checked: boolean) => {
    setIsFull(checked);
    if (checked) {
      setAmount1(selectedCollateralOption.upTo.toNumber());
      setAmount2(selectedBorrowingOption.upTo.toNumber());
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onApproveClick = async () => {
    try {
      await approve();
    } catch (e) {
      console.error("Approve error:", e);
    }
  }

  return (
    <Flex w="100%" direction="column">
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approval required</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please approve ERC20 transactions with this smart-contract before making a borrowing transaction.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='solid' onClick={onApproveClick} isDisabled={
              approveState.type === "waitingForApproval" || approveState.type === "waitingForConfirmation"
            }>{approveState.type === "waitingForApproval"
              ? "Confirm in Wallet"
              : approveState.type === "waitingForConfirmation"
                ? "Processing..."
                : "Approve"}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <InputGroup
        originalTrove={original}
        adjustedTrove={edited}
        borrowingRate={borrowingRate}
        page={AppPage.Borrow}
        isFull={isFull}
        onFullChange={onFullChange}
        firstInputOptions={availableCollateralOptions}
        secondInputOptions={borrowingOptions}
        secondWithoutIcons
        selectedTxItem={selectedTxItem}
        onFirstInputOptionChanged={onFirstInputChange}
        onSecondInputOptionChanged={onSecondInputChange}
        selectedCollateralOption={selectedCollateralOption}
        selectedBorrowingOption={selectedBorrowingOption}
        onChangeFirstAmount={v => setAmount1(v)}
        onChangeSecondAmount={v => setAmount2(v)}
        onButtonClick={onBorrowClick}
        onCancel={() => {
          setIsFull(false);
          setSelectedTxItem(undefined);
        }}
        buttonName={
          selectedTxItem
            ? "Repay Loan"
            : selectedCollateralOption?.selectOption !== "ETH" && allowance.lt(amount1)
            ? "Approve"
            : "Borrow"
        }
        state={state}
        min={
          baseConfiguration.borrowingTokenMinimumDebt.toNumber() +
          (selectedBorrowingOption.selectOption === "USDZ" ? 100 : 1)
        }
      />
      <TransactionsBlock
        page={AppPage.Borrow}
        heading="Your Outstanding Loans"
        headers={headers}
        values={positions}
        textToClick="Repay Loan"
        onClick={onRepayLoanClick}
      />
      <LearnAnd and="Borrow">
        <UnorderedList color="text.200" fontSize="16px" mt="15px" lineHeight="20px">
          <ListItem>
            The health of a loan is determined by its collateralization ratio (value of loan / value
            of collateral).
          </ListItem>
          <ListItem>
            Zero Protocol provides loans with maximum protection and will only liquidate loans that
            have dropped to a collateralization ratio below 110%.
          </ListItem>
          <ListItem>
            In volatile markets, users are encouraged to monitor their loan balance and increase the
            collateral if required.
          </ListItem>
          <ListItem>
            Zero Protocol discourages users to obtain risky loans and makes these loans subject to
            user redemption.
          </ListItem>
          <ListItem listStyleType="none" pt="30px">
            <Link
              as={NavLink}
              to={learnMoreLink}
              fontSize="16px"
              color="primary.100"
              justifySelf="flex-start"
              target="_blank"
              textUnderlineOffset="5px"
            >
              Learn more
            </Link>{" "}
            <ArrowIcon />
          </ListItem>
        </UnorderedList>
      </LearnAnd>
    </Flex>
  );
};
