import React from "react";
import { Decimal } from "@liquity/lib-base";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { Flex, Grid, GridItem } from "@chakra-ui/react";

export type ProtocolInfoItem = {
  token: string;
  price: Decimal;
  redemptionFee: Decimal;
};

export type ProtocolInfoProps = {
  items: ProtocolInfoItem[];
};

export const ProtocolInfo: React.FC<ProtocolInfoProps> = ({ items }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex w={isMobileOrTablet ? "100%" : "30%"}>
      <Flex
        direction="column"
        bg="white"
        borderRadius="20px"
        p="24px 12px"
        w={isMobileOrTablet ? "100%" : "auto"}
      >
        <Grid
          w="100%"
          templateColumns="repeat(4, 1fr)"
          fontSize="18px"
          columnGap="16px"
          rowGap="10px"
          justifyItems="center"
          color="text.500"
        >
          <GridItem>Token</GridItem>
          <GridItem>Price</GridItem>
          <GridItem colSpan={2} textAlign="center">
            Redemption Fee
          </GridItem>
          {items.map(({ token, price, redemptionFee }) => (
            <React.Fragment key={token}>
              <GridItem>{token}</GridItem>
              <GridItem color="text.200">${price.prettify(2)}</GridItem>
              <GridItem color="text.200" colSpan={2}>
                {redemptionFee.toNumber(2).toFixed(2)}%
              </GridItem>
            </React.Fragment>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};
