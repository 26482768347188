import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useRoutes, useSearchParams } from "react-router-dom";
import { routesConfig } from "../routes/config";
import { forageStore } from "../store/localforage";
import { isAddress, getAddress } from "ethers/lib/utils";
import { useToast } from "@chakra-ui/react";

export const RenderRoutes: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  const referral = useMemo(() => searchParams.get("ref"), [searchParams]);

  useEffect(() => {
    if (referral) {
      const decoded = atob(referral);
      console.log(referral, decoded);
      if (isAddress(decoded)) {
        forageStore.setItem("zloans_ref", getAddress(decoded)).then(() => {
          setSearchParams("");
          toast({
            variant: "subtle",
            position: "bottom-right",
            title: "Referred confirmed.",
            description:
              "Successfully updated referred address. Make one transaction (borrow / earn) to apply.",
            status: "success",
            duration: 9000,
            isClosable: true
          });
        });
      }
    }
  }, [referral]);

  const routes = useRoutes(routesConfig);

  return <>{routes}</>;
};
