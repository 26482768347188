import { useContext } from "react";

import { LiquityStore } from "@liquity/lib-base";

import { LiquityStoreContext, LiquityStoreContextType } from "../components/LiquityStoreProvider";

export const useLiquityStore = <T>(): LiquityStoreContextType<T> => {
  const store = useContext(LiquityStoreContext);

  if (!store) {
    throw new Error("You must provide a LiquityStore via LiquityStoreProvider");
  }

  return store as LiquityStoreContextType<T>;
};
