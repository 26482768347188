import React, { useEffect, useMemo, useState } from "react";
import { Flex, Image, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Text } from "../Text";
import Select from "react-select";
import { priceFormatter } from "../../helpers/formatters";
import { Option } from "./index";
import { ASSET_ICONS } from "../../constants/icons";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import { Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

type InputGroupItemProps = {
  label: string;
  options: Option[];
  selectedOption: Option;
  setSelectedOption: (option: Option) => void | Promise<void>;
  inputTextFormatter?: (text: string) => string;
  withoutIcons?: boolean;
  apy?: Decimal;
  defaultValue?: number;
  onInputChange: (n: number) => void | Promise<void>;
  min?: number;
  isSelectDisabled?: boolean;
  isInputDisabled?: boolean;
};

const optionToSelectOption = (
  option: Option,
  isDisabled: boolean,
  labelTextFormatter: InputGroupItemProps["inputTextFormatter"],
  withoutIcons = false
) => ({
  value: option.selectOption,
  label: (
    <Flex w="100%" h="100%" alignItems="center" justifyContent="center" gap="6px">
      {labelTextFormatter?.(option.selectOption) ?? option.selectOption}{" "}
      {!withoutIcons && (
        <Image
          src={ASSET_ICONS.get(option.selectOption.toLowerCase())}
          alt="asset"
          height="24px"
          sx={{
            filter: isDisabled ? "grayscale(1)" : "grayscale(0)"
          }}
        />
      )}
    </Flex>
  )
});

export const InputGroupItem: React.FC<InputGroupItemProps> = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
  withoutIcons,
  apy,
  defaultValue,
  onInputChange,
  inputTextFormatter,
  min,
  isSelectDisabled,
  isInputDisabled
}) => {
  const [inputted, setInputted] = useState<number | string>("");

  useEffect(() => {
    if (defaultValue || typeof apy !== "undefined") {
      if (defaultValue) {
        setInputted(defaultValue);
      } else if (apy) {
        setInputted(apy.toNumber());
      }
    }
  }, [defaultValue, apy]);

  useEffect(() => {
    if (isInputDisabled && selectedOption?.upTo) {
      setInputted(selectedOption.upTo.toNumber());
    }
  }, [isInputDisabled]);

  useEffect(() => {
    onInputChange(+inputted);
  }, [inputted]);

  return (
    <Flex direction="column" gap="8px">
      <Flex justifyContent="space-between">
        <Text color="text.300" fontSize="14px" fontWeight={500}>
          {label}
        </Text>
        <Text color="text.300" fontSize="14px" fontWeight={500}>
          {apy
            ? "Current APY"
            : `Up to ${selectedOption?.upTo.prettify(2)} ${selectedOption?.symbol}`}
        </Text>
      </Flex>
      <Flex gap="8px">
        <Select
          styles={{
            control: (provided, { isDisabled }) => {
              return {
                ...provided,
                height: "100%",
                width: "105px",
                borderRadius: "10px",
                border: isDisabled ? "none" : "1px solid #A1B6FF",
                background: isDisabled ? "#E9E9E9" : "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "12px",
                paddingBottom: "12px",
                fontSize: "18px"
              };
            },
            dropdownIndicator: (provided) => ({
              ...provided
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            indicatorSeparator: provided => ({
              ...provided,
              width: "0"
            }),
            indicatorsContainer: (provided, { isDisabled, options }) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: isDisabled || options.length === 1 ? "0" : "auto",
              height: isDisabled || options.length === 1 ? "0" : "auto"
            }),
            valueContainer: provided => ({
              ...provided,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            })
          }}
          options={options.map(o =>
            optionToSelectOption(o, isSelectDisabled ?? false, inputTextFormatter, withoutIcons)
          )}
          value={optionToSelectOption(
            selectedOption,
            isSelectDisabled ?? false,
            inputTextFormatter,
            withoutIcons
          )}
          isSearchable={false}
          components={{
            DropdownIndicator: ({ isFocused }) => <Image src={DropdownIcon} alt="indicator" style={{ rotate: isFocused && options.length > 1 ? "180deg" : "360deg" }} />
          }}
          onChange={e => {
            if (e) {
              const option = options.find(option => option.selectOption === e.value);
              if (option) {
                setSelectedOption(option);
              }
            }
          }}
          openMenuOnClick={options.length > 1}
          isDisabled={isSelectDisabled}
        />
        <InputGroup>
          <Input
            type="number"
            min={min ?? 0}
            max={selectedOption.upTo?.toNumber()}
            value={
              (apy?.infinite ? undefined : apy?.toNumber(1))?.toString() ??
              inputted === "" ? "" : Decimal.from(+inputted).toNumber(2).toString()
            }
            disabled={isInputDisabled || typeof apy !== "undefined"}
            onChange={e => {
              if (apy) {
                return;
              }
              const value = parseFloat(e.target.value);
              if (value) {
                if (value <= selectedOption?.upTo?.toNumber(2)) {
                  setInputted(value);
                } else {
                  setInputted(selectedOption.upTo?.toNumber(2));
                }
              } else {
                setInputted(0);
              }
            }}
            w="100%"
            textAlign="right"
            bg="white"
            borderRadius="10px"
            height="80px"
            fontSize="18px"
            color="text.200"
            p={selectedOption.symbol !== "USDZ" && !apy ? "14px 80px 40px 0" : "auto"}
            pr={selectedOption.symbol === "USDZ" ? "80px" : apy ? "40px" : "auto"}
            _disabled={{
              opacity: 1
            }}
          />
          <InputRightElement
            w="60px"
            pr="24px"
            children={
              <Flex direction="column" gap="4px" alignItems="flex-end" w="100%" pt="40px">
                <Text color="text.200" fontSize="18px">
                  {apy ? "%" : selectedOption.symbol}
                </Text>
                {selectedOption.symbol !== "USDZ" && !apy && (
                  <Text fontSize="14px" color="text.200" whiteSpace="nowrap">
                    ~
                    {priceFormatter.format(
                      (+inputted) *
                      (selectedOption.selectOption === "USDC"
                        ? 1
                        : selectedOption.price?.get().toNumber())
                    )}
                  </Text>
                )}
              </Flex>
            }
          />
        </InputGroup>
      </Flex>
    </Flex>
  );
};
