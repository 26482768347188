import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { Text } from "../Text";
import DocsIcon from "../../assets/icons/docs.svg";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";

type LearnAndProps = {
  and: string;
} & React.PropsWithChildren;

export const LearnAnd: React.FC<LearnAndProps> = ({ and, children }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex
      w="100%"
      px={isMobileOrTablet ? "16px" : "80px"}
      justifyContent="center"
      mt={isMobileOrTablet ? "96px" : "112px"}
    >
      <Flex
        direction="column"
        w="100%"
        bg="rgba(255,255,255,0.6)"
        alignItems="center"
        borderRadius="20px"
        p="24px 32px 40px 32px"
      >
        <Box position="relative">
          <Image
            src={DocsIcon}
            alt="docs"
            position="absolute"
            left={isMobileOrTablet ? "-115px" : "-130px"}
            top="-68px"
          />
          <Text w="100%" textAlign="center" color="text.100" fontSize="18px" fontWeight={600}>
            Learn & {and}
          </Text>
        </Box>
        {children}
      </Flex>
    </Flex>
  );
};
