import React, {useState} from "react";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import { Flex, Image, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export type ReferralAssetMenuProps = {
  selectedToken: string;
  setSelectedToken: (token: string) => void;
  tokens: string[];
};

export const ReferralAssetMenu: React.FC<ReferralAssetMenuProps> = ({
  selectedToken,
  setSelectedToken,
  tokens
}) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Menu onOpen={() => setIsOpened(true)} onClose={() => setIsOpened(false)}>
      <MenuButton bg="white" px={3} py={2} borderRadius="10px" border="1px solid #A1B6FF" _active={{
        outline: "1px solid #2684FF",
        outlineOffset: "0px"
      }}>
        <Flex gap="8px" alignItems="center" color="text.200" fontSize="18px">
          <span>{selectedToken}</span>
          <Image src={DropdownIcon} alt="indicator" style={{ rotate: isOpened ? "180deg" : "360deg" }} />
        </Flex>
      </MenuButton>
      <MenuList minW="80px" maxW="80px" p="20px" borderRadius="10px">
        {tokens.map(symbol => (
          <MenuItem
            key={symbol}
            maxW="80px"
            w="100%"
            alignItems="center"
            justifyContent="center"
            fontSize="14px"
            color="text.300"
            onClick={() => setSelectedToken(symbol)}
            mb="8px"
            _last={{
              mb: 0
            }}
            _hover={{
              color: "#FD7B1D",
              fontWeight: 500
            }}
            _focus={{
              color: "#FD7B1D",
              fontWeight: 500
            }}
            _active={{}}
            p={0}
          >
            {symbol}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
