import React from "react";
import { Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { Text } from "../Text";
import ZeroPercentSmall from "../../assets/landing/zero-percent-small.svg";
import ZeroPercentBig from "../../assets/landing/zero-percent-big.svg";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";

export const LandingHeading: React.FC = () => {
  const { zeroPercent, bottomFontSize, topFontSize, direction } = useBreakpointValue(
    [
      {
        zeroPercent: ZeroPercentSmall,
        topFontSize: "42px",
        bottomFontSize: "16px",
        direction: "column" as const
      },
      {
        zeroPercent: ZeroPercentSmall,
        topFontSize: "42px",
        bottomFontSize: "16px",
        direction: "column" as const
      },
      {
        zeroPercent: ZeroPercentBig,
        topFontSize: "96px",
        bottomFontSize: "24px",
        direction: "row" as const
      }
    ],
    { ssr: false }
  ) ?? {
    zeroPercent: ZeroPercentSmall,
    topFontSize: "42px",
    bottomFontSize: "16px",
    direction: "column" as const
  };
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex justifyContent="center" alignItems="center" gap="24px" direction={direction}>
      {isMobileOrTablet ? (
        <>
          <Flex gap="10px" mx="16px">
            <Image src={zeroPercent} alt="zero-percent" width="auto" />
            <Text fontSize={topFontSize} lineHeight={topFontSize}>
              interest loans
            </Text>
          </Flex>
          <Text fontSize={bottomFontSize} lineHeight={bottomFontSize} mx="16px">
            Borrow USD or ETH against your crypto.
          </Text>
        </>
      ) : (
        <>
          <Image src={zeroPercent} alt="zero-percent" width="auto" />
          <Flex direction="column">
            <Text fontSize={topFontSize} lineHeight={topFontSize}>
              interest loans
            </Text>
            <Text fontSize={bottomFontSize}>Borrow USD or ETH against your crypto.</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
