import { Text as ChText, TextProps } from "@chakra-ui/react";
import React from "react";

export const Text: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <ChText fontWeight={400} fontSize={24} color="text.400" {...props}>
      {children}
    </ChText>
  );
};
