import { AddressZero } from "@ethersproject/constants";

import {
  Decimal,
  LiquityStoreState,
  LiquityStoreBaseState,
  TroveWithPendingRedistribution,
  StabilityDeposit,
  LQTYStake,
  LiquityStore,
  Fees,
  defaultPoolBaseConfig,
  _emptyTrove,
  Decimalish,
  Referrals,
  PendingReferralRewards,
  Price, PendingReferralRewardsWithDate, ReferralsWithDate
} from "@liquity/lib-base";

import { decimalify, promiseAllValues } from "./_utils";
import { ReadableEthersLiquity, isBlockPooledReadableStore } from "./ReadableEthersLiquity";
import { EthersLiquityConnection, _getPoolBaseConfig, _getProvider } from "./EthersLiquityConnection";
import { EthersCallOverrides, EthersProvider } from "./types";
import { constants } from "ethers";
import { _getPoolName, _splitPoolName } from "./contracts";

/**
 * Extra state added to {@link @liquity/lib-base#LiquityStoreState} by
 * {@link BlockPolledLiquityStore}.
 *
 * @public
 */
export interface BlockPolledLiquityStoreExtraState {
  /**
   * Number of block that the store state was fetched from.
   *
   * @remarks
   * May be undefined when the store state is fetched for the first time.
   */
  blockTag?: number;

  /**
   * Timestamp of latest block (number of seconds since epoch).
   */
  blockTimestamp: number;

  /** @internal */
  _feesFactory: (blockTimestamp: number, recoveryMode: boolean) => Fees;
}

/**
 * The type of {@link BlockPolledLiquityStore}'s
 * {@link @liquity/lib-base#LiquityStore.state | state}.
 *
 * @public
 */
export type BlockPolledLiquityStoreState = LiquityStoreState<BlockPolledLiquityStoreExtraState>;

/**
 * Ethers-based {@link @liquity/lib-base#LiquityStore} that updates state whenever there's a new
 * block.
 *
 * @public
 */
export class BlockPolledLiquityStore extends LiquityStore<BlockPolledLiquityStoreExtraState> {
  readonly connection: EthersLiquityConnection;

  private readonly _readable: ReadableEthersLiquity;
  private readonly _provider: EthersProvider;

  private readonly defaultUserPoolStore = {
    assetBalance: Decimal.ZERO,
    assetTokenAllowance: Decimal.ZERO,
    assetSymbol: "",
    collateralSurplusBalance: Decimal.ZERO,
    troveBeforeRedistribution: new TroveWithPendingRedistribution(
      defaultPoolBaseConfig,
      AddressZero,
      "nonExistent"
    ),
    stabilityDeposit: new StabilityDeposit(
      Decimal.ZERO,
      Decimal.ZERO,
      Decimal.ZERO,
      Decimal.ZERO,
      Decimal.ZERO,
      AddressZero
    ),
    ownFrontend: { status: "unregistered" as const },
    poolWithLowestCR: undefined as any
  };

  private readonly defaultPoolStore = {
    _riskiestTroveBeforeRedistribution: new TroveWithPendingRedistribution(
      defaultPoolBaseConfig,
      AddressZero,
      "nonExistent"
    ),
    _feesFactory: (_: number, __: boolean) => Fees.empty(),
    total: _emptyTrove(defaultPoolBaseConfig),
    totalRedistributed: _emptyTrove(defaultPoolBaseConfig),
    price: new Price(Decimal.ZERO, false),
    numberOfTroves: 0
  };

  constructor(readable: ReadableEthersLiquity) {
    super();
    
    console.log('BlockPolledLiquityStore constructor', isBlockPooledReadableStore(readable));

    this.connection = readable.connection;
    this._readable = readable;
    this._provider = _getProvider(readable.connection);
  }

  private async _getRiskiestTroveBeforeRedistribution(
    poolName: string,
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution> {
    const config = _getPoolBaseConfig(poolName, this._readable.connection);

    const riskiestTroves = await this._readable.getTroves(
      poolName,
      { first: 1, sortedBy: "ascendingCollateralRatio", beforeRedistribution: true },
      overrides
    );

    if (riskiestTroves.length === 0) {
      return new TroveWithPendingRedistribution(config, AddressZero, "nonExistent");
    }

    return riskiestTroves[0];
  }

  private async _get(
    blockTag?: number
  ): Promise<[baseState: LiquityStoreBaseState, extraState: BlockPolledLiquityStoreExtraState]> {
    console.log('TRIGGERED STATE REFETCH');
    const { userAddress, frontendTag } = this.connection;

    const selectedPoolName = this.state?.selectedPoolName;

    let selectedCollateralName: string | undefined = undefined;
    let selectedBorrowingName: string | undefined = undefined;

    if (selectedPoolName) {
      const splitted = _splitPoolName(selectedPoolName);
      selectedCollateralName = splitted.collateralTokenName;
      selectedBorrowingName = splitted.borrowingTokenName;
    }

    console.log("STORE: selected collateral", selectedCollateralName);
    console.log("STORE: selected borrowing", selectedBorrowingName);

    const [
      poolPrices,
      prices
    ] = await Promise.all([
      this._readable.getAllPoolPrices({ blockTag }),
      this._readable.getAllPrices({ blockTag })
    ]);

    this._readable.poolPrices = poolPrices;
    this._readable.prices = prices;


    console.log('PRICES LOADED', { prices, poolPrices });

    const { blockTimestamp, _feesFactory, calculateRemainingLQTY, ...baseState } =
      await promiseAllValues({
        blockTimestamp: this._readable._getBlockTimestamp(blockTag),
        poolPrices,
        prices,
        ...(selectedBorrowingName
          ? {
              calculateRemainingLQTY:
                this._readable._getRemainingLiquidityMiningLQTYRewardCalculator(
                  selectedBorrowingName,
                  {
                    blockTag
                  }
                ),
              totalStakedUniTokens: this._readable.getTotalStakedUniTokens(selectedBorrowingName, {
                blockTag
              }),
              lusdSupply: this._readable.getLUSDSupply(selectedBorrowingName, { blockTag }),
              lusdInStabilityPool: this._readable.getLUSDInStabilityPool(selectedBorrowingName, {
                blockTag
              }),
              totalStakedLQTY: this._readable.getTotalStakedLQTY({ blockTag }),
              remainingStabilityPoolLQTYReward: this._readable.getRemainingStabilityPoolLQTYReward(
                selectedBorrowingName,
                {
                  blockTag
                }
              ),
              frontend: frontendTag
                ? this._readable.getFrontendStatus(selectedBorrowingName, frontendTag, { blockTag })
                : { status: "unregistered" as const }
            }
          : {
              calculateRemainingLQTY: (_: number) => Decimal.ZERO,
              totalStakedUniTokens: Decimal.ZERO,
              lusdSupply: Decimal.ZERO,
              lusdInStabilityPool: Decimal.ZERO,
              totalStakedLQTY: Decimal.ZERO,
              remainingStabilityPoolLQTYReward: Decimal.ZERO,
              frontend: { status: "unregistered" as const }
            }),
        allPoolsInfo: this._readable.getAllPoolsInfo({ blockTag }),
        redemptionFees: this._readable.getAllRedemptionFees({ blockTag }),
        selectedPoolName,
        selectedBorrowingName,
        ...(selectedPoolName
          ? {
              _riskiestTroveBeforeRedistribution: this._getRiskiestTroveBeforeRedistribution(
                selectedPoolName,
                { blockTag }
              ),
              _feesFactory: this._readable._getFeesFactory(selectedPoolName, { blockTag }),
              price: this._readable.getPrice(selectedPoolName, { blockTag }),
              numberOfTroves: this._readable.getNumberOfTroves(selectedPoolName, { blockTag }),
              totalRedistributed: this._readable.getTotalRedistributed(selectedPoolName, {
                blockTag
              }),
              total: this._readable.getTotal(selectedPoolName, { blockTag })
            }
          : this.defaultPoolStore),
        ...(userAddress
          ? {
              lqtyStake: this._readable.getLQTYStake(userAddress, {
                blockTag
              }),
              approximateStakingAPY: this._readable.getApproximateStakingAPY(userAddress, { blockTag }),
              accountBalance: this._provider.getBalance(userAddress, blockTag).then(decimalify),
              referrer: this._readable.getUserReferrer(userAddress, { blockTag }),
              referrerEarnings: this._readable.getReferrerEarnings(userAddress, { blockTag }),
              pendingEarningAmount: this._readable.getPendingEarningAmount(userAddress, {
                blockTag
              }),
              claimedSPEarnings: this._readable.getClaimedSPEarnings(userAddress, { blockTag }),
              pendingEarnings: this._readable.getPendingEarnings(userAddress, { blockTag }),
              referrals: this._readable.getUserReferrals(userAddress, { blockTag }),
              claimedEarnings: this._readable.getClaimedEarnings(userAddress, { blockTag }),
              lqtyBalance: this._readable.getLQTYBalance(userAddress, { blockTag }),
              ...(selectedBorrowingName
                ? {
                    uniTokenBalance: Decimal.ZERO,
                    uniTokenAllowance: Decimal.ZERO,
                    liquidityMiningStake: Decimal.ZERO,
                    liquidityMiningLQTYReward: Decimal.ZERO,
                    lusdBalance: this._readable.getLUSDBalance(selectedBorrowingName, userAddress, {
                      blockTag
                    })
                  }
                : {
                    uniTokenBalance: Decimal.ZERO,
                    uniTokenAllowance: Decimal.ZERO,
                    liquidityMiningStake: Decimal.ZERO,
                    liquidityMiningLQTYReward: Decimal.ZERO,
                    lusdBalance: Decimal.ZERO
                  }),
              pendingReferralRewards: new PendingReferralRewards({}),
              allPoolsInfoByUser: this._readable.getAllPoolsInfoByUser(userAddress, { blockTag }),
              spInfoByUser: this._readable.getSPInfoByUser(userAddress, { blockTag }),
              ...(selectedPoolName && selectedBorrowingName
                ? {
                    assetBalance: this._readable.getAssetBalance(selectedPoolName, userAddress, {
                      blockTag
                    }),
                    poolWithLowestCR: this._readable.getPoolNameWithLowestCR(selectedBorrowingName, {
                      blockTag
                    }),
                    assetTokenAllowance: this._readable.getAssetAllowance(
                      selectedPoolName,
                      userAddress,
                      { blockTag }
                    ),
                    assetSymbol: this._readable.getAssetSymbol(selectedPoolName, userAddress, {
                      blockTag
                    }),
                    collateralSurplusBalance: this._readable.getCollateralSurplusBalance(
                      selectedPoolName,
                      userAddress,
                      {
                        blockTag
                      }
                    ),
                    troveBeforeRedistribution: this._readable.getTroveBeforeRedistribution(
                      selectedPoolName,
                      userAddress,
                      {
                        blockTag
                      }
                    ),
                    stabilityDeposit: this._readable.getStabilityDeposit(
                      selectedBorrowingName,
                      userAddress,
                      { blockTag }
                    ),
                    ownFrontend: this._readable.getFrontendStatus(
                      selectedBorrowingName,
                      userAddress,
                      {
                        blockTag
                      }
                    )
                  }
                : this.defaultUserPoolStore)
            }
          : {
              accountBalance: Decimal.ZERO,
              lqtyStake: new LQTYStake(),
              referrer: constants.AddressZero,
              approximateStakingAPY: Decimal.ZERO,
              referrerEarnings: new PendingReferralRewardsWithDate({}, new Date()),
              pendingEarningAmount: new PendingReferralRewards({}),
              pendingEarnings: new PendingReferralRewards({}),
              claimedSPEarnings: new PendingReferralRewards({}),
              referrals: new ReferralsWithDate({}),
              claimedEarnings: new Referrals({}),
              lusdBalance: Decimal.ZERO,
              lqtyBalance: Decimal.ZERO,
              uniTokenBalance: Decimal.ZERO,
              uniTokenAllowance: Decimal.ZERO,
              liquidityMiningStake: Decimal.ZERO,
              liquidityMiningLQTYReward: Decimal.ZERO,
              allPoolsInfoByUser: {},
              spInfoByUser: {},
              pendingReferralRewards: new PendingReferralRewards({}),
              ...this.defaultUserPoolStore
            })
      });

    return [
      {
        ...baseState,
        _feesInNormalMode: _feesFactory(blockTimestamp, false),
        remainingLiquidityMiningLQTYReward: calculateRemainingLQTY(blockTimestamp)
      },
      {
        blockTag,
        blockTimestamp,
        _feesFactory
      }
    ];
  }

  /** @internal @override */
  protected _doStart(): () => void {
    this._get().then(state => {
      if (!this._loaded) {
        this._load(...state);
      } else {
        this._update(...state);
      }
    });

    const blockListener = async (blockTag: number) => {
      console.log('NEW BLOCK LOADED', blockTag);
      const state = await this._get(blockTag);

      if (this._loaded) {
        this._update(...state);
      } else {
        this._load(...state);
      }
    };

    this._provider.on("block", blockListener);

    return () => {
      this._provider.off("block", blockListener);
    };
  }

  /** @internal @override */
  protected _reduceExtra(
    oldState: BlockPolledLiquityStoreExtraState,
    stateUpdate: Partial<BlockPolledLiquityStoreExtraState>
  ): BlockPolledLiquityStoreExtraState {
    return {
      blockTag: stateUpdate.blockTag ?? oldState.blockTag,
      blockTimestamp: stateUpdate.blockTimestamp ?? oldState.blockTimestamp,
      _feesFactory: stateUpdate._feesFactory ?? oldState._feesFactory
    };
  }
}
