import React from "react";
import {
  Flex,
  Image,
  Link,
  ListItem,
  UnorderedList,
  useBreakpointValue,
  useConst
} from "@chakra-ui/react";
import { Text } from "../Text";
import Arrow from "../../assets/landing/arrow.svg";
import Question from "../../assets/landing/question.svg";
import { NavLink } from "react-router-dom";
import { docsLink } from "../../constants/links";
import { FaqAccordion } from "./FaqAccordion";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";

export const LandingFaq = () => {
  const faqItems = useConst<{ title: string; label: React.ReactNode }[]>(() => [
    {
      title: "What is Zero Protocol?",
      label:
        "Zero Protocol is a non-cusdotial fully decentralised borrowing protocol. The Protocol allows you to mint USDZ against the largest crypto collateral assets."
    },
    {
      title: "What is a stablecoin?",
      label:
        "USDZ is a stablecoin with a peg to $1 USD. USDZ is fully collateralised by crypto collateral assets."
    },
    {
      title: "How can I use Zero?",
      label:
        "Users directly interact with Zero Protocol using their self-hosted wallets such as MetaMask or others."
    },
    {
      title: "How can I get USDZ?",
      label:
        "Open a vault by depositing crypto collateral such as ETH, stETH or others and mint USDZ against it."
    },
    {
      title: "What can I do with USDZ?",
      label:
        "USDZ is an ERC20 token and can be integrated across the majority of off and on-chain applications. For example users can buy ETH on Uniswap using USDZ."
    },
    {
      title: "What is the Stability Pool?",
      label:
        "The Stability Pool is a pre-arranged pool of capital to facilitated loan liquidations in volatile markets.  Depositors in the Stability Pool earn the excess collateral in defaulted loans in addition to a token incentive. The Stability Pool ensures that the aggregate collateralization of the Protocol remains healthy."
    },
    {
      title: "Are there any risks?",
      label: (
        <UnorderedList>
          <ListItem>
            Liquidation risk: if the value of your collateral falls below a collateralisation ratio
            below 110%, the loan will go into default. The excess collateral will be distributed to
            the liquidator and the Stability Pool.
          </ListItem>
          <ListItem>
            Redemption risk: users may be subject to redemption if their loans are among the riskiest
            vaults across the protocol. Please monitor your loans in the user dashboard.
          </ListItem>
        </UnorderedList>
      )
    },
    {
      title: "Where can I learn more?",
      label: "The full documentation of how Zero Protocol works can be found here (link to Docs)."
    }
  ]);
  const { mx, direction } = useBreakpointValue(
    [
      { mx: "16px", direction: "column" as const },
      { mx: "16px", direction: "column" as const },
      { mx: "80px", direction: "row" as const }
    ],
    {
      ssr: false
    }
  ) ?? { mx: "16px", direction: "column" };
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex alignItems="center" justifyContent="center" id="faq">
      <Flex
        mt={isMobileOrTablet ? "191px" : "158px"}
        maxW="1280px"
        w="100%"
        bg="rgba(255,255,255,0.5)"
        borderRadius="16px"
        gap="60px"
        p="16px"
        mx={mx}
        direction={direction}
      >
        {isMobileOrTablet ? (
          <Flex direction="column" position="relative" alignItems="center" gap="12px">
            <Image
              src={Question}
              alt="question"
              position="absolute"
              w="125px"
              h="125px"
              top="-63px"
            />
            <Text fontSize="24px" fontWeight={500} color="text.200" mt="90px">
              FAQ
            </Text>
            <Flex gap="16px">
              <Text fontSize="14px">Read more in our Docs</Text>
              <Link as={NavLink} to={docsLink} target="_blank">
                <Image src={Arrow} alt="arrow" />
              </Link>
            </Flex>
          </Flex>
        ) : (
          <Flex direction="column" ml="44px" alignItems="center">
            <Flex direction="column" alignItems="center" mt="16px">
              <Text fontSize="24px" fontWeight={500} color="text.200">
                FAQ
              </Text>
              <Flex gap="16px">
                <Text fontSize="14px">Read more in our Docs</Text>
                <Link as={NavLink} to={docsLink} target="_blank">
                  <Image src={Arrow} alt="arrow" />
                </Link>
              </Flex>
            </Flex>
            <Image src={Question} alt="question" mt="100px" />
          </Flex>
        )}
        <FaqAccordion items={faqItems} />
      </Flex>
    </Flex>
  );
};
