import React from "react";
import { RouteObject, Navigate } from "react-router-dom";
import { AppRouteComponent } from "../components/AppRouteComponent";
import { Borrow } from "../pages/Borrow";
import { Earn } from "../pages/Earn";
import { Landing } from "../pages/Landing";
import { Dashboard } from "../pages/Dashboard";
import { Referral } from "../pages/Referral";

export const ROUTES = {
  Root: "/",
  Landing: "/",
  App: "/app",
  Borrow: "/app/borrow",
  Earn: "/app/earn",
  Protocol: "/app/protocol",
  Referral: "/app/referral"
} as const;

export const routesConfig: RouteObject[] = [
  {
    path: ROUTES.Landing,
    element: <Landing />
  },
  {
    path: ROUTES.App,
    element: <AppRouteComponent />,
    children: [
      {
        path: ROUTES.Borrow,
        element: <Borrow />
      },
      {
        path: ROUTES.Earn,
        element: <Earn />
      },
      {
        path: ROUTES.Protocol,
        element: <Dashboard />
      },
      {
        path: ROUTES.Referral,
        element: <Referral />
      },
      {
        path: "",
        element: <Navigate to={ROUTES.Borrow} />
      },
      {
        path: "*",
        element: <Navigate to={ROUTES.Borrow} />
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to={ROUTES.Landing} />
  }
];
