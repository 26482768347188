import React, { useEffect, useMemo, useState } from "react";
import { InputGroup, Option } from "../components/InputGroup";
import { TransactionItemProps } from "../components/TransactionsBlock";
import { Flex, Link, ListItem, UnorderedList, useToast } from "@chakra-ui/react";
import { AppPage } from "../types/AppPage";
import { LearnAnd } from "../components/LearnAnd";
import { Decimal, LiquityStoreState, Price, fetchLqtyPrice } from "@liquity/lib-base";

import { useLiquity } from "../hooks/LiquityContext";
import { useLiquitySelector, useLiquityStore } from "@liquity/lib-react";
import { useTransactionFunction } from "../hooks/useTransactionFunction";
import { useReferrer } from "../hooks/useReferrer";
import EarnTransactionBlock, {
  EarnTransactionBlockItem
} from "../components/TransactionsBlock/EarnTransactionBlock";
import { EarnInfoItem } from "../components/InputGroup/EarnInfo";
import { NavLink } from "react-router-dom";
import { learnMoreLink } from "../constants/links";
import { ArrowIcon } from "../components/ArrowIcon";
import { _splitPoolName } from "@liquity/lib-ethers/dist/src/contracts";
import { matchErrorMessage } from "../helpers/regex";

const select = ({
  selectedPoolName,
  lusdBalance,
  allPoolsInfo,
  pendingEarnings,
  lqtyBalance,
  totalStakedLQTY,
  lqtyStake,
  spInfoByUser,
  prices,
  approximateStakingAPY
}: LiquityStoreState) => ({
  selectedPoolName,
  lusdBalance,
  allPoolsInfo,
  pendingEarnings,
  lqtyBalance,
  totalStakedLQTY,
  lqtyStake,
  spInfoByUser,
  prices,
  approximateStakingAPY
});

export const Earn = () => {
  const toast = useToast();
  const store = useLiquityStore();
  const { liquity } = useLiquity();
  const allPools = useMemo(() => liquity.getAllPools(), [liquity]);
  const allBorrowingOptions = useMemo(() => liquity.getAllBorrowingOptions(), [liquity]);
  const {
    selectedPoolName,
    prices,
    allPoolsInfo,
    lqtyBalance,
    totalStakedLQTY,
    lqtyStake,
    spInfoByUser,
    approximateStakingAPY
  } = useLiquitySelector(select);
  const referrer = useReferrer();
  const [isStaking, setIsStaking] = useState(false);
  const [isFull, setIsFull] = useState(false);

  const lqtyPrice = prices["LQTY"]?.get() ?? Decimal.ZERO;

  const depositOptions: Option[] = [
    ...Object.entries(spInfoByUser)
      .map(([token, value]) => ({
        selectOption: token,
        symbol: token,
        price: value?.price ?? new Price(Decimal.ZERO, false),
        upTo: value?.balance ?? Decimal.from(0),
        apy: Decimal.ZERO,
        poolSize: Decimal.ZERO
      }))
      .sort((a, b) => (a.selectOption > b.selectOption ? -1 : 1)),
    {
      selectOption: "ZRO",
      price: new Price(lqtyPrice, false),
      symbol: "ZRO",
      upTo: lqtyBalance,
      apy: Decimal.from(0),
      poolSize: Decimal.from(0)
    }
  ];
  const [selectedDepositOption, setSelectedDepositOption] = useState<Option>(
    () => depositOptions[0]
  );

  useEffect(() => {
    const depositOption = depositOptions.find(
      d => d.selectOption === selectedDepositOption.selectOption
    );
    if (depositOption) {
      depositOption.upTo =
        depositOption.selectOption === "ZRO"
          ? lqtyBalance
          : spInfoByUser[selectedDepositOption.selectOption]?.balance ?? Decimal.ZERO;
      selectedDepositOption.upTo =
        depositOption.selectOption === "ZRO"
          ? lqtyBalance
          : spInfoByUser[selectedDepositOption.selectOption]?.balance ?? Decimal.ZERO;
    }
  }, [selectedDepositOption, spInfoByUser, lqtyBalance]);

  const [poolOptions, setPoolOptions] = useState<Option[]>(
    allPools.map(([p]) => ({
      selectOption: p,
      symbol: p,
      upTo: Decimal.ZERO,
      price: new Price(Decimal.ZERO, false),
      apy: Decimal.ZERO,
      poolSize: Decimal.ZERO
    }))
  );

  const [selectedTxItem, setSelectedTxItem] = useState<TransactionItemProps | undefined>(undefined);

  const getCurrentPoolOption = () => poolOptions.filter(p => p.selectOption === selectedPoolName)[0];

  const [selectedPoolOption, setSelectedPoolOption] = useState<Option>(() => poolOptions[0]);

  useEffect(() => {
    if (!selectedPoolName) return;
    setSelectedPoolOption(getCurrentPoolOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoolName]);

  // useEffect(() => {
  //   if (store.setStop) {
  //     store.setStop(store.start());
  //   } else {
  //     store.start();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedPoolOption]);

  const onSecondInputChange = (option: Option) => {
    setSelectedPoolOption(option);
    store.update({
      selectedPoolName: option.selectOption
    });
  };

  const [amount, setAmount] = useState<number>(0);

  const transactionId = "stability-deposit";

  const [deposit, state, setState] = useTransactionFunction(transactionId, () =>
    selectedTxItem
      ? isStaking
        ? liquity.send.unstakeLQTY.call(liquity.send, Decimal.from(amount), false)
        : liquity.send.withdrawLUSDFromStabilityPool.call(
            liquity.send,
            selectedDepositOption.selectOption,
            Decimal.from(amount),
            false
          )
      : isStaking
      ? liquity.send.stakeLQTY.call(liquity.send, referrer, Decimal.from(amount), false)
      : liquity.send.depositLUSDInStabilityPool.call(
          liquity.send,
          referrer,
          selectedDepositOption.selectOption,
          Decimal.from(amount),
          undefined,
          false
        )
  );

  const claimId = "stability-deposit-claim";

  const [claim, claimState, setClaimState] = useTransactionFunction(
    claimId,
    liquity.send.withdrawLUSDFromStabilityPool.bind(
      liquity.send,
      selectedDepositOption.selectOption,
      Decimal.ZERO,
      false
    )
  );

  const [claimStake, claimStakeState, setClaimStakeState] = useTransactionFunction(
    "claim-stake",
    liquity.send.unstakeLQTY.bind(liquity.send, 0, false)
  );

  useEffect(() => {
    if (state.type === "failed" && state.id === transactionId) {
      const matchedError = matchErrorMessage(state.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setState({ type: "idle" });
    }
    if (state.type === "confirmed" && state.id === transactionId) {
      const title = selectedTxItem
        ? isStaking
          ? "Unstake confirmed."
          : "Withdraw confirmed."
        : isStaking
        ? "Stake confirmed."
        : "Deposit confirmed.";
      const message = selectedTxItem
        ? `${Decimal.from(amount).prettify(2)} ${
            selectedDepositOption.selectOption
          } were added to your wallet.`
        : `Deposited ${Decimal.from(amount).prettify(2)} ${
            selectedDepositOption.selectOption
          } in the ${isStaking ? "Staking" : "Stability"} Pool.`;
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: title,
        description: message,
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setState({ type: "idle" });
      if (selectedTxItem) {
        setSelectedTxItem(undefined);
        setIsFull(false);
      }
    }
    if (claimState.type === "failed" && claimState.id === claimId) {
      const matchedError = matchErrorMessage(claimState.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setClaimState({ type: "idle" });
    }
    if (claimState.type === "confirmed" && claimState.id === claimId) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Claim confirmed.",
        description: "Rewards were added to your wallet.",
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setClaimState({ type: "idle" });
      if (selectedTxItem) {
        setSelectedTxItem(undefined);
        setIsFull(false);
      }
    }
    if (claimStakeState.type === "failed" && claimStakeState.id === "claim-stake") {
      const matchedError = matchErrorMessage(claimStakeState.error.message);
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: matchedError ?? "Something went wrong. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setClaimStakeState({ type: "idle" });
    }
    if (claimStakeState.type === "confirmed" && claimStakeState.id === "claim-stake") {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Claim confirmed.",
        description: "Rewards were added to your wallet.",
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setClaimStakeState({ type: "idle" });
      if (selectedTxItem) {
        setSelectedTxItem(undefined);
        setIsFull(false);
      }
    }
  }, [state, claimState, claimStakeState]);

  const onButtonClick = async () => {
    try {
      if (
        !isStaking &&
        !selectedTxItem &&
        spInfoByUser[selectedDepositOption.selectOption]?.balance.lt(amount)
      ) {
        throw new Error("Not enough balance.");
      }

      if (isStaking && (selectedTxItem ? selectedDepositOption.upTo : lqtyBalance).lt(amount)) {
        throw new Error("Not enough ZRO.");
      }

      if (amount < 0.01) {
        throw new Error(`Minimum amount is 0.01 ${selectedDepositOption.selectOption}.`);
      }
      await deposit();
    } catch (e) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: e instanceof Error ? e.message : String(e),
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  const onClaimClick = async (totalEarnings: Decimal) => {
    try {
      if (totalEarnings.isZero) {
        throw new Error("You don't have rewards to claim");
      }
      await claim();
    } catch (e) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: e instanceof Error ? e.message : String(e),
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  const onClaimStakeClick = async () => {
    try {
      if (lqtyStake.collateralGain.isZero && lqtyStake.lusdGain.isZero) {
        throw new Error("You don't have rewards to claim");
      }
      await claimStake();
    } catch (e) {
      toast({
        variant: "subtle",
        position: "bottom-right",
        title: "Error occurred.",
        description: e instanceof Error ? e.message : String(e),
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  const getApy = (token?: string) => {
    if (!token) {
      return Decimal.ZERO;
    }
    const poolInfo = Object.entries(allPoolsInfo).find(
      ([name]) => _splitPoolName(name).borrowingTokenName === token
    );
    if (poolInfo) {
      const [name, info] = poolInfo;
      return info.lqtyIssuanceOneDay.mul(lqtyPrice).mulDiv(365 * 100, allPoolsInfo[name].lusdPool);
    }
    return Decimal.ZERO;
  };

  const earnPools: EarnInfoItem[] = [
    ...Object.entries(spInfoByUser).map(([token, info]) => ({
      apy: getApy(token),
      poolSize: info.poolSize.mul(info.price.get()),
      token: token
    })),
    {
      apy: approximateStakingAPY,
      poolSize: totalStakedLQTY.mul(lqtyPrice),
      token: "ZRO"
    }
  ];

  const stakeEarnings = useMemo(() => {
    return {
      WETH: lqtyStake.collateralGain,
      USDZ: lqtyStake.lusdGain
    };
  }, [lqtyStake.collateralGain, lqtyStake.lusdGain]);
  const stakeTotalEarnings = useMemo(() => {
    return lqtyStake.lusdGain.add(
      lqtyStake.collateralGain.mul(allPoolsInfo["WETH"]?.price.price ?? 0)
    );
  }, [lqtyStake.collateralGain, lqtyStake.lusdGain, allPoolsInfo["WETH"]]);

  const earnTransactions: EarnTransactionBlockItem[] = useMemo(() => {
    return [
      ...Object.entries(spInfoByUser)
        .filter(([, info]) => !info.deposit.isZero)
        .map(([token, info]) => {
          const totalEarnings = Object.entries(info.rewards.referral)
            .map(([token, earning]) => {
              const price =
                token === "ZRO"
                  ? new Price(lqtyPrice, false)
                  : allPoolsInfo[token]?.price ?? Decimal.ZERO;
              return earning.mul(price.get());
            })
            .reduce((a, b) => a.add(b), Decimal.ZERO);
          return {
            symbol: token,
            deposit: info.deposit,
            apy: getApy(token) ?? Decimal.ZERO,
            earningByAsset: Object.entries(info.rewards.referral).reduce((a, [name, value]) => {
              a[_splitPoolName(name).collateralTokenName] = value;
              return a;
            }, {} as Record<string, Decimal>),
            totalEarnings,
            textToClick: "Withdraw",
            onClick: () => {
              setIsStaking(false);
              setSelectedTxItem({
                first: {
                  value: info.deposit.toNumber(),
                  symbol: token
                },
                second: {
                  symbol: token
                },
                third: {
                  value: Decimal.ZERO.toNumber(),
                  symbol: "%"
                }
              });
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            },
            onClaimClick: () => {
              setIsStaking(false);
              setSelectedTxItem(undefined);
              const option = depositOptions.find(o => o.selectOption === token);
              if (option) {
                setSelectedDepositOption(option);
              }
              onClaimClick(totalEarnings);
            }
          };
        })
        .sort(({ symbol: a }, { symbol: b }) => {
          if (a > b) {
            return -1;
          }

          return 1;
        }),
      ...(lqtyStake.isEmpty
        ? []
        : [
            {
              symbol: "ZRO",
              deposit: Decimal.from(lqtyStake.stakedLQTY ?? 0),
              apy: approximateStakingAPY,
              earningByAsset: stakeEarnings,
              totalEarnings: stakeTotalEarnings,
              textToClick: "Unstake",
              onClick: () => {
                setIsStaking(true);
                setSelectedTxItem({
                  first: { value: lqtyStake.stakedLQTY.toNumber(), symbol: "ZRO" },
                  second: {
                    symbol: "ZRO"
                  },
                  third: {
                    value: Decimal.ZERO.toNumber(),
                    symbol: "%"
                  }
                });
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              },
              onClaimClick: onClaimStakeClick
            }
          ])
    ];
  }, [
    spInfoByUser,
    allPoolsInfo,
    lqtyStake.stakedLQTY,
    stakeEarnings,
    stakeTotalEarnings,
    approximateStakingAPY,
    lqtyPrice
  ]);

  const onFullChange = (checked: boolean) => {
    setIsFull(checked);
    if (checked) {
      setAmount(selectedDepositOption.upTo.toNumber());
    }
  };

  return (
    <Flex w="100%" direction="column">
      <InputGroup
        page={AppPage.Earn}
        isFull={isFull}
        onFullChange={onFullChange}
        firstInputOptions={depositOptions}
        secondInputOptions={poolOptions}
        firstWithoutIcons
        selectedTxItem={selectedTxItem}
        selectedBorrowingOption={selectedPoolOption}
        selectedCollateralOption={selectedDepositOption}
        onFirstInputOptionChanged={option => {
          setSelectedDepositOption(option);
          setIsStaking(option.selectOption === "ZRO");
        }}
        onSecondInputOptionChanged={onSecondInputChange}
        onChangeFirstAmount={v => setAmount(v)}
        onChangeSecondAmount={() => undefined}
        onButtonClick={onButtonClick}
        onCancel={() => setSelectedTxItem(undefined)}
        buttonName={
          selectedTxItem ? (isStaking ? "Unstake" : "Withdraw") : isStaking ? "Stake" : "Earn"
        }
        state={state}
        earnInfoItems={earnPools}
        withoutSecond
      />
      <EarnTransactionBlock items={earnTransactions} />
      <LearnAnd and="Earn">
        <UnorderedList color="text.200" fontSize="16px" mt="15px" lineHeight="20px">
          <ListItem>Deposit USDZ or ETHZ in the stability pool to earn token rewards.</ListItem>
          <ListItem>
            The Stability Pool earns the excess collateral in loan liquidations in addition to ZRO
            token rewards.
          </ListItem>
          <ListItem>Stake ZRO to earn a portion of Protocol revenue.</ListItem>
          <ListItem listStyleType="none" pt="30px">
            <Link
              as={NavLink}
              to={learnMoreLink}
              fontSize="16px"
              color="primary.100"
              justifySelf="flex-start"
              target="_blank"
              textUnderlineOffset="5px"
            >
              Learn more
            </Link>{" "}
            <ArrowIcon />
          </ListItem>
        </UnorderedList>
      </LearnAnd>
    </Flex>
  );
};
