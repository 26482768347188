import React from "react";
import { NavLink } from "react-router-dom";
import { Text } from "../Text";
import { Link } from "@chakra-ui/react";

type HeaderLinkProps = {
  to: string;
} & React.PropsWithChildren;

export const HeaderLink: React.FC<HeaderLinkProps> = ({ children, to }) => {
  return (
    <Link
      as={NavLink}
      to={to}
      target="_blank"
      sx={{
        "&:hover": { textDecoration: "none" }
      }}
    >
      <Text fontWeight={600}>{children}</Text>
    </Link>
  );
};
