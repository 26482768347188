import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Flex } from "@chakra-ui/react";
import BgApp from "../assets/background/bg-app.png";
import { Footer } from "./Footer";
import { useLiquity } from "../hooks/LiquityContext";
import { LiquityStoreProvider, useLiquitySelector, useLiquityStore } from "@liquity/lib-react";
import useDebounce from "../hooks/useDebounce";

const OutletWithSelectedName: React.FC = () => {
  const store = useLiquityStore();
  const { selectedPoolName } = useLiquitySelector(({ selectedPoolName }) => ({
    selectedPoolName
  }));
  const debouncedSelectedPoolName = useDebounce(selectedPoolName, 500);

  useEffect(() => {
    store.update({
      selectedPoolName: "WETH/USDZ"
    });
  }, []);

  useEffect(() => {
    const stop = store.start();
    if (store.setStop) {
      store.setStop(stop);
    }
    return () => stop();
  }, [debouncedSelectedPoolName]);

  console.log(selectedPoolName);
  if (selectedPoolName) {
    return <Outlet />;
  }

  return null;
};

export const AppLayout: React.FC = () => {
  const { liquity } = useLiquity();

  return (
    <LiquityStoreProvider store={liquity.store}>
      <Flex direction="column" height="100%" bgImage={BgApp} bgSize="100% 100%" bgRepeat="no-repeat">
        <Header />
        <OutletWithSelectedName />
        <Footer />
      </Flex>
    </LiquityStoreProvider>
  );
};
