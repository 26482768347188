import { createContext, useContext } from "react";

export type LocalStorageItems = {
  zloans_isReferralsClosed: boolean;
};

export const localStorageContextInitial: LocalStorageItems = {
  zloans_isReferralsClosed: false
};

export const LocalStorageContext = createContext<LocalStorageItems>(localStorageContextInitial);

export const useLocalStorageContext = () => useContext(LocalStorageContext);
