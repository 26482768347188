import React from "react";
import { Flex, Image, Link } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

type SocialLinkProps = {
  icon: string;
  link: string;
};

export const SocialLink: React.FC<SocialLinkProps> = ({ link, icon }) => {
  return (
    <Link as={NavLink} to={link} target="_blank">
      <Flex
        w="46px"
        h="46px"
        alignItems="center"
        justifyContent="center"
        p="11px"
        bg="linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)"
        backdropFilter="blur(11px)"
        borderRadius="6px"
      >
        <Image src={icon} alt="social" />
      </Flex>
    </Link>
  );
};
