import React from "react";
import { Button as ChButton, ButtonProps, Box } from "@chakra-ui/react";

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ChButton
      variant="solid"
      colorScheme="primary"
      p="12px"
      borderRadius="16px"
      {...props}
      className="button"
    >
      <Box
        as="span"
        sx={{
          ".button:active &": {
            color: "primary.900"
          },
          ".button:focus &": {
            color: "primary.900"
          }
        }}
      >
        {children}
      </Box>
    </ChButton>
  );
};
