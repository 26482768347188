import { BlockTag } from "@ethersproject/abstract-provider";

import {
  CollateralGainTransferDetails,
  Decimal,
  Decimalish,
  FailedReceipt,
  Fees,
  FrontendStatus,
  LiquidationDetails,
  LiquityStore,
  LQTYStake,
  PendingReferralRewards, PendingReferralRewardsWithDate,
  PoolInfo,
  PoolInfoByUser,
  Price,
  RedemptionDetails,
  RedemptionFee,
  Referrals, ReferralsWithDate,
  SPInfoByUser,
  StabilityDeposit,
  StabilityDepositChangeDetails,
  StabilityPoolGainsWithdrawalDetails,
  TransactableLiquity,
  TransactionFailedError,
  Trove,
  TroveAdjustmentDetails,
  TroveAdjustmentParams,
  TroveClosureDetails,
  TroveCreationDetails,
  TroveCreationParams,
  TroveListingParams,
  TroveWithPendingRedistribution,
  UserTrove
} from "@liquity/lib-base";

import {
  EthersLiquityConnection,
  EthersLiquityConnectionOptionalParams,
  EthersLiquityPoolContracts,
  EthersLiquityStoreOption,
  _connect,
  _getBorrowingOptions,
  _getCollateralOptions,
  _usingStore
} from "./EthersLiquityConnection";

import {
  EthersCallOverrides,
  EthersProvider,
  EthersSigner,
  EthersTransactionOverrides,
  EthersTransactionReceipt
} from "./types";

import {
  BorrowingOperationOptionalParams,
  PopulatableEthersLiquity,
  SentEthersLiquityTransaction
} from "./PopulatableEthersLiquity";
import { ReadableEthersLiquity, ReadableEthersLiquityWithStore } from "./ReadableEthersLiquity";
import { SendableEthersLiquity } from "./SendableEthersLiquity";
import { BlockPolledLiquityStore } from "./BlockPolledLiquityStore";

/**
 * Thrown by {@link EthersLiquity} in case of transaction failure.
 *
 * @public
 */
export class EthersTransactionFailedError extends TransactionFailedError<
  FailedReceipt<EthersTransactionReceipt>
> {
  constructor(message: string, failedReceipt: FailedReceipt<EthersTransactionReceipt>) {
    super("EthersTransactionFailedError", message, failedReceipt);
  }
}

const waitForSuccess = async <T>(tx: SentEthersLiquityTransaction<T>) => {
  const receipt = await tx.waitForReceipt();

  if (receipt.status !== "succeeded") {
    throw new EthersTransactionFailedError("Transaction failed", receipt);
  }

  return receipt.details;
};

/**
 * Convenience class that combines multiple interfaces of the library in one object.
 *
 * @public
 */
export class EthersLiquity implements ReadableEthersLiquity, TransactableLiquity {
  /** Information about the connection to the Liquity protocol. */
  readonly connection: EthersLiquityConnection;

  /** Can be used to create populated (unsigned) transactions. */
  readonly populate: PopulatableEthersLiquity;

  /** Can be used to send transactions without waiting for them to be mined. */
  readonly send: SendableEthersLiquity;

  private _readable: ReadableEthersLiquity;

  public get poolPrices() {
    return this._readable.poolPrices;
  }

  public get prices() {
    return this._readable.prices;
  }

  /** @internal */
  constructor(readable: ReadableEthersLiquity) {
    this._readable = readable;
    this.connection = readable.connection;
    this.populate = new PopulatableEthersLiquity(readable);
    this.send = new SendableEthersLiquity(this.populate);
  }

  /** @internal */
  static _from(
    connection: EthersLiquityConnection & { useStore: "blockPolled" }
  ): EthersLiquityWithStore<BlockPolledLiquityStore>;

  /** @internal */
  static _from(connection: EthersLiquityConnection): EthersLiquity;

  /** @internal */
  static _from(connection: EthersLiquityConnection): EthersLiquity {
    if (_usingStore(connection)) {
      return new _EthersLiquityWithStore(ReadableEthersLiquity._from(connection));
    } else {
      return new EthersLiquity(ReadableEthersLiquity._from(connection));
    }
  }

  /** @internal */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams: EthersLiquityConnectionOptionalParams & { useStore: "blockPolled" }
  ): Promise<EthersLiquityWithStore<BlockPolledLiquityStore>>;

  /**
   * Connect to the Liquity protocol and create an `EthersLiquity` object.
   *
   * @param signerOrProvider - Ethers `Signer` or `Provider` to use for connecting to the Ethereum
   *                           network.
   * @param optionalParams - Optional parameters that can be used to customize the connection.
   */
  static connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersLiquityConnectionOptionalParams
  ): Promise<EthersLiquity>;

  static async connect(
    signerOrProvider: EthersSigner | EthersProvider,
    optionalParams?: EthersLiquityConnectionOptionalParams
  ): Promise<EthersLiquity> {
    return EthersLiquity._from(await _connect(signerOrProvider, optionalParams));
  }

  /**
   * Check whether this `EthersLiquity` is an {@link EthersLiquityWithStore}.
   */
  hasStore(): this is EthersLiquityWithStore;

  /**
   * Check whether this `EthersLiquity` is an
   * {@link EthersLiquityWithStore}\<{@link BlockPolledLiquityStore}\>.
   */
  hasStore(store: "blockPolled"): this is EthersLiquityWithStore<BlockPolledLiquityStore>;

  hasStore(): boolean {
    return false;
  }

  getAllPools(): [string, EthersLiquityPoolContracts][] {
    return Object.entries(this.connection.poolContracts);
  }

  getAllCollateralOptions() {
    return _getCollateralOptions(this.connection);
  }

  getAllBorrowingOptions() {
    return Object.entries(_getBorrowingOptions(this.connection));
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalRedistributed} */
  getTotalRedistributed(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    return this._readable.getTotalRedistributed(poolName, overrides);
  }

  getReferrerEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewardsWithDate> {
    return this._readable.getReferrerEarnings(address, overrides);
  }

  async getAllRedemptionFees(
    overrides: EthersCallOverrides
  ): Promise<Record<string, RedemptionFee>> {
    return this._readable.getAllRedemptionFees(overrides);
  }

  async getClaimedSPEarnings(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._readable.getPendingEarnings(address, overrides);
  }

  getPendingEarnings(address?: string, overrides?: EthersCallOverrides) {
    return this._readable.getPendingEarnings(address, overrides);
  }

  async getAllPrices(overrides: EthersCallOverrides): Promise<Record<string, Price>> {
    return this._readable.getAllPrices(overrides);
  }

  async getAllPoolPrices(overrides: EthersCallOverrides): Promise<Record<string, Price>> {
    return this._readable.getAllPoolPrices(overrides);
  }

  async getPendingEarningAmount(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._readable.getPendingEarningAmount(address, overrides);
  }

  async getSPInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, SPInfoByUser>> {
    return this._readable.getSPInfoByUser(address, overrides);
  }

  getUserReferrals(address?: string, overrides?: EthersCallOverrides): Promise<ReferralsWithDate> {
    return this._readable.getUserReferrals(address, overrides);
  }
  getClaimedEarnings(address?: string, overrides?: EthersCallOverrides): Promise<Referrals> {
    return this._readable.getClaimedEarnings(address, overrides);
  }

  async getApproximateStakingAPY(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getApproximateStakingAPY(address, overrides);
  }

  getAllPoolsInfo(overrides?: EthersCallOverrides): Promise<Record<string, PoolInfo>> {
    return this._readable.getAllPoolsInfo(overrides);
  }
  getAllPoolsInfoByUser(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Record<string, PoolInfoByUser>> {
    return this._readable.getAllPoolsInfoByUser(address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTroveBeforeRedistribution} */
  getTroveBeforeRedistribution(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution> {
    return this._readable.getTroveBeforeRedistribution(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTrove} */
  getTrove(poolName: string, address?: string, overrides?: EthersCallOverrides): Promise<UserTrove> {
    return this._readable.getTrove(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getNumberOfTroves} */
  getNumberOfTroves(poolName: string, overrides?: EthersCallOverrides): Promise<number> {
    return this._readable.getNumberOfTroves(poolName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getPrice} */
  getPrice(poolName: string, overrides?: EthersCallOverrides): Promise<Price> {
    return this._readable.getPrice(poolName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotal} */
  getTotal(poolName: string, overrides?: EthersCallOverrides): Promise<Trove> {
    return this._readable.getTotal(poolName, overrides);
  }

  getAllPendingReferralRewards(
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<PendingReferralRewards> {
    return this._readable.getAllPendingReferralRewards(address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getStabilityDeposit} */
  getStabilityDeposit(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<StabilityDeposit> {
    return this._readable.getStabilityDeposit(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getRemainingStabilityPoolLQTYReward} */
  getRemainingStabilityPoolLQTYReward(
    poolName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getRemainingStabilityPoolLQTYReward(poolName, overrides);
  }

  getUserReferrer(address?: string, overrides?: EthersCallOverrides): Promise<string> {
    return this._readable.getUserReferrer(address, overrides);
  }
  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDInStabilityPool} */
  getLUSDInStabilityPool(poolName: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._readable.getLUSDInStabilityPool(poolName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  getLUSDSupply(borrowingTokenName: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._readable.getLUSDSupply(borrowingTokenName, overrides);
  }
  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  getLUSDBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getLUSDBalance(borrowingTokenName, address, overrides);
  }

  getAssetInfoByUser(poolName: string, address?: string, overrides?: EthersCallOverrides) {
    return this._readable.getAssetInfoByUser(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  getAssetBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getAssetBalance(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLUSDBalance} */
  getAssetAllowance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getAssetAllowance(poolName, address, overrides);
  }

  getAssetSymbol(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    return this._readable.getAssetSymbol(poolName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLQTYBalance} */
  getLQTYBalance(address?: string, overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._readable.getLQTYBalance(address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getUniTokenBalance} */
  getUniTokenBalance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getUniTokenBalance(borrowingTokenName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getUniTokenAllowance} */
  getUniTokenAllowance(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getUniTokenAllowance(borrowingTokenName, address, overrides);
  }

  /** @internal */
  _getRemainingLiquidityMiningLQTYRewardCalculator(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number) => Decimal> {
    return this._readable._getRemainingLiquidityMiningLQTYRewardCalculator(
      borrowingTokenName,
      overrides
    );
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getRemainingLiquidityMiningLQTYReward} */
  getRemainingLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getRemainingLiquidityMiningLQTYReward(borrowingTokenName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLiquidityMiningStake} */
  getLiquidityMiningStake(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getLiquidityMiningStake(borrowingTokenName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalStakedUniTokens} */
  getTotalStakedUniTokens(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getTotalStakedUniTokens(borrowingTokenName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLiquidityMiningLQTYReward} */
  getLiquidityMiningLQTYReward(
    borrowingTokenName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getLiquidityMiningLQTYReward(borrowingTokenName, address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getCollateralSurplusBalance} */
  getCollateralSurplusBalance(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<Decimal> {
    return this._readable.getCollateralSurplusBalance(poolName, address, overrides);
  }

  getPoolNameWithLowestCR(
    borrowingTokenName: string,
    overrides?: EthersCallOverrides
  ): Promise<string> {
    return this._readable.getPoolNameWithLowestCR(borrowingTokenName, overrides);
  }

  /** @internal */
  getTroves(
    poolName: string,
    params: TroveListingParams & { beforeRedistribution: true },
    overrides?: EthersCallOverrides
  ): Promise<TroveWithPendingRedistribution[]>;

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.(getTroves:2)} */
  getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]>;

  getTroves(
    poolName: string,
    params: TroveListingParams,
    overrides?: EthersCallOverrides
  ): Promise<UserTrove[]> {
    return this._readable.getTroves(poolName, params, overrides);
  }

  /** @internal */
  _getBlockTimestamp(blockTag?: BlockTag): Promise<number> {
    return this._readable._getBlockTimestamp(blockTag);
  }

  /** @internal */
  _getFeesFactory(
    poolName: string,
    overrides?: EthersCallOverrides
  ): Promise<(blockTimestamp: number, recoveryMode: boolean) => Fees> {
    return this._readable._getFeesFactory(poolName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getFees} */
  getFees(poolName: string, overrides?: EthersCallOverrides): Promise<Fees> {
    return this._readable.getFees(poolName, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getLQTYStake} */
  getLQTYStake(address?: string, overrides?: EthersCallOverrides): Promise<LQTYStake> {
    return this._readable.getLQTYStake(address, overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getTotalStakedLQTY} */
  getTotalStakedLQTY(overrides?: EthersCallOverrides): Promise<Decimal> {
    return this._readable.getTotalStakedLQTY(overrides);
  }

  /** {@inheritDoc @liquity/lib-base#ReadableLiquity.getFrontendStatus} */
  getFrontendStatus(
    poolName: string,
    address?: string,
    overrides?: EthersCallOverrides
  ): Promise<FrontendStatus> {
    return this._readable.getFrontendStatus(poolName, address, overrides);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.openTrove}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  openTrove(
    referrer: string,
    poolName: string,
    params: TroveCreationParams<Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveCreationDetails> {
    return this.send
      .openTrove(referrer, poolName, params, maxBorrowingRateOrOptionalParams, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.closeTrove}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  closeTrove(
    poolName: string,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveClosureDetails> {
    return this.send.closeTrove(poolName, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.adjustTrove}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  adjustTrove(
    poolName: string,
    params: TroveAdjustmentParams<Decimalish>,
    maxBorrowingRateOrOptionalParams?: Decimalish | BorrowingOperationOptionalParams,
    unwrapETH = false,
    withETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveAdjustmentDetails> {
    return this.send
      .adjustTrove(poolName, params, maxBorrowingRateOrOptionalParams, unwrapETH, withETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.depositCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  depositCollateral(
    poolName: string,
    amount: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveAdjustmentDetails> {
    return this.send.depositCollateral(poolName, amount, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.withdrawCollateral}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawCollateral(
    poolName: string,
    amount: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveAdjustmentDetails> {
    return this.send.withdrawCollateral(poolName, amount, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.borrowLUSD}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  borrowLUSD(
    poolName: string,
    amount: Decimalish,
    maxBorrowingRate?: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveAdjustmentDetails> {
    return this.send
      .borrowLUSD(poolName, amount, maxBorrowingRate, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.repayLUSD}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  repayLUSD(
    poolName: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<TroveAdjustmentDetails> {
    return this.send.repayLUSD(poolName, amount, overrides).then(waitForSuccess);
  }

  /** @internal */
  setPrice(
    poolName: string,
    price: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.setPrice(poolName, price, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.liquidate}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  liquidate(
    referrer: string,
    poolName: string,
    address: string | string[],
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<LiquidationDetails> {
    return this.send
      .liquidate(referrer, poolName, address, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.liquidateUpTo}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  liquidateUpTo(
    referrer: string,
    poolName: string,
    maximumNumberOfTrovesToLiquidate: number,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<LiquidationDetails> {
    return this.send
      .liquidateUpTo(referrer, poolName, maximumNumberOfTrovesToLiquidate, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.depositLUSDInStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  depositLUSDInStabilityPool(
    referrer: string,
    borrowingTokenName: string,
    amount: Decimalish,
    frontendTag?: string,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityDepositChangeDetails> {
    return this.send
      .depositLUSDInStabilityPool(
        referrer,
        borrowingTokenName,
        amount,
        frontendTag,
        unwrapETH,
        overrides
      )
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.withdrawLUSDFromStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawLUSDFromStabilityPool(
    borrowingTokenName: string,
    amount: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityDepositChangeDetails> {
    return this.send
      .withdrawLUSDFromStabilityPool(borrowingTokenName, amount, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.withdrawGainsFromStabilityPool}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawGainsFromStabilityPool(
    borrowingTokenName: string,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<StabilityPoolGainsWithdrawalDetails> {
    return this.send
      .withdrawGainsFromStabilityPool(borrowingTokenName, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.sendLUSD}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  sendBorrowingToken(
    borrowingTokenName: string,
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send
      .sendBorrowingToken(borrowingTokenName, toAddress, amount, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.sendLQTY}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  sendLQTY(
    toAddress: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.sendLQTY(toAddress, amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.redeemLUSD}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  redeemLUSD(
    referrer: string,
    borrowingTokenName: string,
    amount: Decimalish,
    maxRedemptionRate?: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<RedemptionDetails> {
    return this.send
      .redeemLUSD(referrer, borrowingTokenName, amount, maxRedemptionRate, unwrapETH, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.claimCollateralSurplus}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  claimCollateralSurplus(
    poolName: string,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.claimCollateralSurplus(poolName, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.stakeLQTY}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  stakeLQTY(
    referrer: string,
    amount: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.stakeLQTY(referrer, amount, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.unstakeLQTY}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  unstakeLQTY(
    amount: Decimalish,
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.unstakeLQTY(amount, unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.withdrawGainsFromStaking}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawGainsFromStaking(
    unwrapETH = false,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.withdrawGainsFromStaking(unwrapETH, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.registerFrontend}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  registerFrontend(
    borrowingTokenName: string,
    kickbackRate: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send
      .registerFrontend(borrowingTokenName, kickbackRate, overrides)
      .then(waitForSuccess);
  }

  /** @internal */
  _mintUniToken(
    borrowingTokenName: string,
    amount: Decimalish,
    address?: string,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send
      ._mintUniToken(borrowingTokenName, amount, address, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.approveUniTokens}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  approveUniTokens(
    borrowingTokenName: string,
    allowance?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.approveUniTokens(borrowingTokenName, allowance, overrides).then(waitForSuccess);
  }

  collateralTokenApproveToBO(
    poolName: string,
    allowance?: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.collateralTokenApproveToBO(poolName, allowance, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.stakeUniTokens}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  stakeUniTokens(
    borrowingTokenName: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.stakeUniTokens(borrowingTokenName, amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.unstakeUniTokens}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  unstakeUniTokens(
    borrowingTokenName: string,
    amount: Decimalish,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.unstakeUniTokens(borrowingTokenName, amount, overrides).then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.withdrawLQTYRewardFromLiquidityMining}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  withdrawLQTYRewardFromLiquidityMining(
    borrowingTokenName: string,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send
      .withdrawLQTYRewardFromLiquidityMining(borrowingTokenName, overrides)
      .then(waitForSuccess);
  }

  /**
   * {@inheritDoc @liquity/lib-base#TransactableLiquity.exitLiquidityMining}
   *
   * @throws
   * Throws {@link EthersTransactionFailedError} in case of transaction failure.
   * Throws {@link EthersTransactionCancelledError} if the transaction is cancelled or replaced.
   */
  exitLiquidityMining(
    borrowingTokenName: string,
    overrides?: EthersTransactionOverrides
  ): Promise<void> {
    return this.send.exitLiquidityMining(borrowingTokenName, overrides).then(waitForSuccess);
  }
}

/**
 * Variant of {@link EthersLiquity} that exposes a {@link @liquity/lib-base#LiquityStore}.
 *
 * @public
 */
export interface EthersLiquityWithStore<T extends LiquityStore = LiquityStore>
  extends EthersLiquity {
  /** An object that implements LiquityStore. */
  readonly store: T;
}

class _EthersLiquityWithStore<T extends LiquityStore = LiquityStore>
  extends EthersLiquity
  implements EthersLiquityWithStore<T>
{
  readonly store: T;

  constructor(readable: ReadableEthersLiquityWithStore<T>) {
    super(readable);

    this.store = readable.store;
  }

  hasStore(store?: EthersLiquityStoreOption): boolean {
    return store === undefined || store === this.connection.useStore;
  }
}
