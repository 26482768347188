import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Image
} from "@chakra-ui/react";
import FaqChevronDown from "../../assets/landing/faq-chevron-down.svg";
import FaqChevronUp from "../../assets/landing/faq-chevron-up.svg";

type FaqAccordionProps = {
  items: {
    title: string;
    label: React.ReactNode;
  }[];
};

export const FaqAccordion: React.FC<FaqAccordionProps> = ({ items }) => {
  return (
    <Accordion allowToggle w="100%">
      {items.map(({ title, label }) => (
        <AccordionItem
          key={title}
          border="none"
          my="8px"
          p="16px 8px"
          bg="rgba(255,255,255,0.8)"
          borderRadius="10px"
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  sx={{
                    "&:hover": { backgroundColor: "transparent !important" }
                  }}
                >
                  <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight={500}>
                    {title}
                  </Box>
                  <Image src={isExpanded ? FaqChevronUp : FaqChevronDown} alt="chevron" />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{label}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};
