import { useBreakpointValue } from "@chakra-ui/react";

export const useMatchBreakpoints = () =>
  useBreakpointValue(
    [
      {
        isMobileOrTablet: true,
        isDesktop: false
      },
      {
        isMobileOrTablet: true,
        isDesktop: false
      },
      {
        isMobileOrTablet: true,
        isDesktop: false
      },
      {
        isMobileOrTablet: true,
        isDesktop: false
      },
      {
        isMobileOrTablet: false,
        isDesktop: true
      }
    ],
    {
      ssr: false
    }
  ) ?? { isMobileOrTablet: false, isDesktop: false };
