import { useEffect, useState } from "react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { utils, constants } from "ethers";
import { forageStore } from "../store/localforage";

const select = ({ referrer }: LiquityStoreState) => referrer;

export const useReferrer = () => {
  const [referrer, setReferrer] = useState<string>(constants.AddressZero);
  const onChainReferrer = useLiquitySelector(select);

  useEffect(() => {
    if (!onChainReferrer || onChainReferrer === constants.AddressZero) {
      forageStore.getItem<string>("zloans_ref").then(fromStorage => {
        if (fromStorage && utils.isAddress(fromStorage)) {
          setReferrer(utils.getAddress(fromStorage));
        }
      });
    } else {
      setReferrer(onChainReferrer);
    }
  }, [onChainReferrer]);

  return referrer;
};
