import stethIcon from "../assets/icons/assets/steth.svg";
import ethIcon from "../assets/icons/assets/eth.svg";
import wbtcIcon from "../assets/icons/assets/wbtc.svg";
import usdcIcon from "../assets/icons/assets/usdc.svg";

export const ASSET_ICONS = new Map([
  ["steth", stethIcon],
  ["weth", ethIcon],
  ["eth", ethIcon],
  ["wbtc", wbtcIcon],
  ["usdc", usdcIcon]
]);
