import { LiquityStore } from "@liquity/lib-base";
import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from "react";


export type LiquityStoreContextType<T = unknown> = LiquityStore<T> & { setStop?: Dispatch<SetStateAction<StopFuncType>> }

export const LiquityStoreContext = createContext<LiquityStoreContextType | undefined>(undefined);


type LiquityStoreProviderProps = {
  store: LiquityStore;
  loader?: React.ReactNode;
};

type StopFuncType = ()=>void;

export const LiquityStoreProvider: React.FC<LiquityStoreProviderProps> = ({
  store,
  loader,
  children
}) => {
  const [loadedStore, setLoadedStore] = useState<LiquityStoreContextType>();

  const [stop, setStop] = useState<StopFuncType>(()=>{
    return () => {return;};
  });

  useEffect(() => {
    store.onLoaded = () => setLoadedStore(store);
    setStop(store.start());

    return () => {
      store.onLoaded = undefined;
      setLoadedStore(undefined);
      stop?.();
    };
  }, [store]);

  
  if (!loadedStore) {
    return <>{loader}</>;
  }

  loadedStore.setStop = () => {
    stop?.();
    return setStop;
  }

  return <LiquityStoreContext.Provider value={loadedStore}>{children}</LiquityStoreContext.Provider>;
};
