export const docsLink = "https://gitbook.io";
export const blogLink = "https://twitter.com";
export const whitepaperLink = "https://twitter.com";
export const codebaseLink = "https://github.com/RedDuck-Software/zero-loans";
export const documentationLink = "https://gitbook.io";
export const disclaimerLink = "https://twitter.com";
export const privacyPolicyLink = "https://gitbook.io";
export const linkedinLink = "https://linkedin.com";
export const telegramLink = "https://t.me";
export const twitterLink = "https://twitter.com";
export const youtubeLink = "https://youtube.com";
export const learnMoreLink = "https://twitter.com";
