import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Text } from "../Text";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { SwiperWithPaginator } from "../Swiper";
import { SwiperSlide } from "swiper/react";
import { TransactionVertical } from "./TransactionVertical";
import { AppPage } from "../../types/AppPage";
import { Decimal } from "@liquity/lib-base";

export type TransactionItemElement = {
  value?: number;
  symbol: string;
};

export type TransactionItemProps = {
  first: TransactionItemElement;
  second: TransactionItemElement;
  third: TransactionItemElement;
  fourth?: TransactionItemElement;
};

type TransactionsBlockProps = {
  page: AppPage;
  heading: string;
  headers: string[];
  values: TransactionItemProps[];
  textToClick: string;
  onClick: (txIndex: number) => void | Promise<void>;
};

const TransactionsBlock: React.FC<TransactionsBlockProps> = ({
  page,
  heading,
  headers,
  values,
  textToClick,
  onClick
}) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex w="100%" direction="column" alignItems="center" mt={isMobileOrTablet ? "56px" : "64px"}>
      <Text color="text.100" fontSize="18px" fontWeight={600}>
        {heading}
      </Text>
      {isMobileOrTablet ? (
        values.length > 0 ? (
          <SwiperWithPaginator paginatorType="dot" posLength={values.length} pageSize={1}>
            {values.map((v, index) => (
              <SwiperSlide key={index}>
                <TransactionVertical
                  index={index}
                  first={{ header: headers[0], value: v.first }}
                  second={{ header: headers[1], value: v.second }}
                  third={{ header: headers[2], value: v.third }}
                  fourth={{ header: headers[3], value: v.fourth }}
                  textToClick={textToClick}
                  onClick={onClick}
                />
              </SwiperSlide>
            ))}
          </SwiperWithPaginator>
        ) : (
          <Flex
            w="100%"
            mt="16px"
            px="16px"
            bg="rgba(255,255,255,0.5)"
            alignItems="center"
            justifyContent="center"
            h="47px"
          >
            You have no outstanding {page === AppPage.Borrow ? "loans" : "deposits"}
          </Flex>
        )
      ) : (
        <Grid templateRows="1fr" gap="8px" mt="24px">
          {values.length > 0 && (
            <GridItem>
              <Grid templateColumns={`repeat(${headers.length}, 1fr)`} gap="12px">
                {headers.map(header => (
                  <GridItem key={header}>
                    <Text textAlign="center" color="text.100" fontSize="18px" fontWeight={500}>
                      {header}
                    </Text>
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          )}
          {values.length === 0 && (
            <GridItem w="100%" position="relative">
              <Box
                position="absolute"
                bg="rgba(255,255,255,0.4)"
                top={0}
                zIndex={0}
                left="-500%"
                w="1000%"
                h="100%"
              ></Box>
              <Grid templateColumns="repeat(1, 1fr)" py="13px" gap="20px">
                <GridItem zIndex={1}>
                  <Text w="100%" textAlign="center" color="text.200" fontSize="18px">
                    You have no outstanding {page === AppPage.Borrow ? "loans" : "deposits"}
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {values.map((value, index) => (
            <GridItem key={index} w="100%" position="relative">
              <Box
                position="absolute"
                bg="rgba(255,255,255,0.4)"
                top={0}
                zIndex={0}
                left="-500%"
                w="1000%"
                h="100%"
              ></Box>
              <Grid templateColumns={`repeat(${headers.length}, 1fr)`} py="13px" gap="20px">
                <GridItem zIndex={1}>
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    {Decimal.from(value.first.value ?? 0).prettify(2)} {value.first.symbol}
                  </Text>
                </GridItem>
                <GridItem zIndex={1}>
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    {Decimal.from(value.second.value ?? 0).prettify(2)} {value.second.symbol}
                  </Text>
                </GridItem>
                <GridItem zIndex={1}>
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    {value.third.value && Decimal.from(value.third.value ?? 0).prettify(2)}
                    {value.third.symbol}
                  </Text>
                </GridItem>
                {value.fourth && (
                  <GridItem zIndex={1}>
                    <Text textAlign="center" color="text.200" fontSize="18px">
                      {value.fourth.symbol}
                      {value.fourth.value && Decimal.from(value.fourth.value).prettify(2)}
                    </Text>
                  </GridItem>
                )}
                <GridItem zIndex={1}>
                  <Text
                    textAlign="center"
                    color="primary.100"
                    fontSize="18px"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px"
                      }
                    }}
                    onClick={() => onClick(index)}
                  >
                    {textToClick}
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

export default TransactionsBlock;
