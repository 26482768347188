import React, { useState } from "react";
import { useLocalStorageContext } from "../../hooks/useLocalStorageContext";
import { Flex, Image, Link, ScaleFade, useBreakpointValue } from "@chakra-ui/react";
import { Text } from "../Text";
import { NavLink } from "react-router-dom";
import { docsLink } from "../../constants/links";
import { CloseIcon } from "@chakra-ui/icons";
import ReferralIcon from "../../assets/landing/referral.svg";

export const LandingReferral = () => {
  const localContext = useLocalStorageContext();
  const [isClosed, setIsClosed] = useState<boolean>(localContext.zloans_isReferralsClosed);
  const { px, height } = useBreakpointValue(
    [
      { px: "16px", height: "330px" },
      { px: "16px", height: "330px" },
      { px: "80px", height: "200px" }
    ],
    {
      ssr: false
    }
  ) ?? { px: "16px", height: "330px" };

  return (
    <ScaleFade in={!isClosed} unmountOnExit={true}>
      <Flex alignItems="center" justifyContent="center">
        <Flex
          position="relative"
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          bg="white"
          boxShadow="4px 4px 4px rgba(141, 166, 255, 0.2), inset 4px 4px 9px rgba(141, 166, 255, 0.25)"
          borderRadius="20px"
          height={height}
          mt="100px"
          mx={px}
          px="16px"
          w="100%"
          maxW="1280px"
        >
          <CloseIcon
            position="absolute"
            color="link.400"
            width="15px"
            height="15px"
            cursor="pointer"
            right="16px"
            top="16px"
            onClick={() => {
              localContext.zloans_isReferralsClosed = true;
              localStorage.setItem("zloans_isReferralsClosed", "true");
              setIsClosed(true);
            }}
          />
          <Text fontSize="18px" textAlign="center" color="text.200" maxW="544px">
            Refer someone to us and you both get rewarded with 5% of all fees they generate on Zero
            Protocol for one year.
          </Text>
          <Link as={NavLink} color="link.400" to="/app/referral" target="_blank" fontSize="18px">
            Sign up Referral
          </Link>
          <Image src={ReferralIcon} alt="referral" position="absolute" bottom="-60px" left="20px" />
        </Flex>
      </Flex>
    </ScaleFade>
  );
};
