import React from "react";
import { Flex } from "@chakra-ui/react";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";

export const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return isMobileOrTablet ? (
    <Flex
      flexDirection="column-reverse"
      px="16px"
      gap="56px"
      alignItems="center"
      bg="rgba(255,255,255,0.4)"
      py="32px"
      w="100%"
    >
      {children}
    </Flex>
  ) : (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      py="56px"
      px="80px"
      bg="rgba(255,255,255,0.4)"
    >
      {children}
    </Flex>
  );
};
