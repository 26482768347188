import React, { useEffect, useMemo } from "react";
import { Wrapper } from "./Wrapper";
import { AppPage } from "../../types/AppPage";
import { Checkbox, Flex } from "@chakra-ui/react";
import { PoolInfo } from "./PoolInfo";
import { InputGroupItem } from "./InputGroupItem";
import { Button } from "../Button";
import { TransactionItemProps } from "../TransactionsBlock";
import { Text } from "../Text";
import { Decimal, LiquityStoreState, Price, Trove } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { TransactionState } from "../../hooks/useTransactionFunction";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { EarnInfo, EarnInfoItem } from "./EarnInfo";
import { ProtocolInfo, ProtocolInfoItem } from "./ProtocolInfo";
import { feeFrom } from "../../pages/Borrow";
import { BalanceInfo, BalanceInfoItem } from "./BalanceInfo";

export type Option = {
  selectOption: string;
  upTo: Decimal;
  symbol: string;
  price: Price;
  apy: Decimal;
  poolSize: Decimal;
};

type InputGroupProps = {
  page: AppPage;

  adjustedTrove?: Trove;
  originalTrove?: Trove;
  borrowingRate?: Decimal;

  isFull?: boolean;
  onFullChange?: (checked: boolean) => void;

  firstInputOptions: Option[];
  secondInputOptions: Option[];
  firstWithoutIcons?: boolean;
  secondWithoutIcons?: boolean;
  withoutSecond?: boolean;
  selectedTxItem?: TransactionItemProps;
  onFirstInputOptionChanged?: (o: Option) => void;
  onSecondInputOptionChanged?: (o: Option) => void;

  selectedCollateralOption: Option;
  selectedBorrowingOption: Option;

  onButtonClick: () => void | Promise<void>;
  onChangeFirstAmount: (n: number) => void | Promise<void>;
  onChangeSecondAmount: (n: number) => void | Promise<void>;
  onCancel: () => void | Promise<void>;
  buttonName: string;
  min?: number;
  state: TransactionState;
  earnInfoItems?: EarnInfoItem[];
  protocolInfoItems?: ProtocolInfoItem[];
};

export const InputGroup: React.FC<InputGroupProps> = ({
  page,
  isFull,
  onFullChange,
  firstInputOptions,
  secondInputOptions,
  firstWithoutIcons,
  secondWithoutIcons,
  selectedTxItem,
  onFirstInputOptionChanged,
  onSecondInputOptionChanged,
  selectedBorrowingOption,
  selectedCollateralOption,
  onButtonClick,
  onChangeFirstAmount,
  onChangeSecondAmount,
  onCancel,
  buttonName,
  min,
  adjustedTrove,
  originalTrove,
  borrowingRate,
  state,
  earnInfoItems,
  withoutSecond,
  protocolInfoItems
}) => {
  const { allPoolsInfoByUser, allPoolsInfo, selectedPoolName, prices, spInfoByUser } =
    useLiquitySelector(
      ({ allPoolsInfoByUser, allPoolsInfo, selectedPoolName, prices, spInfoByUser }) => ({
        allPoolsInfoByUser,
        allPoolsInfo,
        selectedPoolName,
        prices,
        spInfoByUser
      })
    );
  const { isMobileOrTablet } = useMatchBreakpoints();

  const isBorrow = page === AppPage.Borrow;
  const isEarn = page === AppPage.Earn;
  const isProtocol = page === AppPage.Protocol;

  useEffect(() => {
    if (selectedTxItem) {
      onFirstInputOptionChanged?.(
        firstInputOptions.find(o => {
          return (
            o.selectOption.toLowerCase() ===
            (isBorrow
              ? selectedTxItem.second.symbol.toLowerCase()
              : selectedTxItem.first.symbol.toLowerCase())
          );
        }) ?? firstInputOptions[0]
      );
      onSecondInputOptionChanged?.(
        secondInputOptions.find(
          o =>
            o.selectOption.toLowerCase() ===
            (isBorrow
              ? selectedTxItem.first.symbol.toLowerCase()
              : selectedTxItem.second.symbol.toLowerCase())
        ) ?? secondInputOptions[0]
      );
    }
  }, [selectedTxItem, isBorrow]);

  const defaultV = useMemo(() => {
    // if (selectedTxItem) {
    //   return selectedTxItem.first.value;
    // }
    // if (isBorrow) {
    //   return !allPoolsInfoByUser[selectedCollateralOption?.selectOption] ||
    //     allPoolsInfoByUser[selectedCollateralOption?.selectOption].trove.debt.isZero
    //     ? min
    //     : allPoolsInfoByUser[selectedCollateralOption?.selectOption].trove.debt.toNumber();
    // }
    return undefined;
  }, [selectedTxItem, isBorrow, min, allPoolsInfoByUser[selectedCollateralOption?.selectOption]]);

  if (!selectedCollateralOption) {
    return null;
  }

  const price =
    (selectedBorrowingOption.selectOption === "ETHZ"
      ? allPoolsInfo?.[selectedPoolName]?.price
      : selectedCollateralOption.price) ?? new Price(Decimal.ONE, false);

  const balanceInfoItems = ["USDZ", "ETHZ"]
    .map(v =>
      spInfoByUser[v]
        ? {
          token: v,
          balance: spInfoByUser[v].balance
        }
        : undefined
    )
    .filter(v => v !== undefined) as BalanceInfoItem[];

  return (
    <Wrapper>
      {isBorrow && isMobileOrTablet && (
        <BalanceInfo items={balanceInfoItems} />
      )}
      {isBorrow && (
        <PoolInfo
          currentPrice={selectedCollateralOption.price.get()}
          original={originalTrove}
          adjusted={adjustedTrove}
          price={price}
          usdPrice={
            selectedBorrowingOption.selectOption === "ETHZ" &&
            selectedCollateralOption.selectOption !== "USDC"
              ? prices["WETH"]?.price ?? Decimal.ONE
              : Decimal.ONE
          }
          feeToken={selectedBorrowingOption.selectOption}
          fee={
            originalTrove && adjustedTrove && borrowingRate
              ? feeFrom(originalTrove, adjustedTrove, borrowingRate)
              : Decimal.ZERO
          }
          minLoanValue={min}
        />
      )}
      {isEarn && earnInfoItems && <EarnInfo items={earnInfoItems} />}
      {isProtocol && protocolInfoItems && <ProtocolInfo items={protocolInfoItems} />}
      <Flex direction="column" gap="30px" maxW="406px" w="100%" mx="auto">
        {selectedTxItem && (
          <Text color="text.300" fontSize="24px" fontWeight={500} textAlign="center">
            {isBorrow ? "Loan Repayment" : "Withdraw"}
          </Text>
        )}
        {isProtocol && (
          <Text color="text.100" fontSize="18px" fontWeight={600} textAlign="center">
            Redeem Stablecoin
          </Text>
        )}
        <InputGroupItem
          label={
            selectedTxItem && isBorrow
              ? "Collateral to be withdrawn"
              : isBorrow
              ? "Collateral"
              : selectedTxItem && isEarn
              ? "Withdraw"
              : isEarn
              ? "Deposit"
              : "Redeem"
          }
          options={firstInputOptions}
          selectedOption={
            selectedTxItem
              ? {
                  ...selectedCollateralOption,
                  upTo: Decimal.from(
                    (isBorrow ? selectedTxItem.second.value : selectedTxItem.first.value) ?? 0
                  )
                }
              : selectedCollateralOption
          }
          setSelectedOption={o => onFirstInputOptionChanged?.(o)}
          withoutIcons={firstWithoutIcons}
          isInputDisabled={isFull}
          defaultValue={
            selectedTxItem
              ? isBorrow
                ? selectedTxItem?.second.value
                : selectedTxItem?.first.value
              : isBorrow
              ? allPoolsInfoByUser[selectedCollateralOption?.selectOption]?.trove?.collateral?.gt(
                  selectedCollateralOption.upTo
                )
                ? selectedCollateralOption.upTo.toNumber()
                : allPoolsInfoByUser[
                    selectedCollateralOption?.selectOption
                  ]?.trove?.collateral.toNumber() || 0
              : undefined
          }
          onInputChange={v => onChangeFirstAmount(v)}
          isSelectDisabled={
            !!(isBorrow ? selectedTxItem?.second.value : selectedTxItem?.first.value)
          }
        />
        {/*<InputGroupItem*/}
        {/*  label={isBorrow ? "Borrowing" : "Pool"}*/}
        {/*  options={secondInputOptions}*/}
        {/*  selectedOption={selectedBorrowingOption}*/}
        {/*  setSelectedOption={o => onSecondInputOptionChanged?.(o)}*/}
        {/*  withoutIcons={secondWithoutIcons}*/}
        {/*  min={isBorrow ? min : undefined}*/}
        {/*  apy={isBorrow ? undefined : selectedBorrowingOption?.apy ?? Decimal.ZERO}*/}
        {/*  defaultValue={*/}
        {/*    selectedTxItem?.first.value ?? isBorrow*/}
        {/*      ? allPoolsInfoByUser[selectedCollateralOption.selectOption]?.trove.debt.isZero*/}
        {/*        ? min*/}
        {/*        : allPoolsInfoByUser[selectedCollateralOption.selectOption]?.trove.debt.toNumber()*/}
        {/*      : undefined*/}
        {/*  }*/}
        {/*  onInputChange={v => onChangeSecondAmount(v)}*/}
        {/*  isSelectDisabled={!!selectedTxItem?.first.value}*/}
        {/*/>*/}
        {!withoutSecond && (
          <InputGroupItem
            label={
              selectedTxItem && isBorrow ? "Loan to be repaid" : isBorrow ? "Borrowing" : "Pool"
            }
            options={secondInputOptions}
            selectedOption={selectedBorrowingOption}
            setSelectedOption={o => onSecondInputOptionChanged?.(o)}
            withoutIcons={secondWithoutIcons}
            isInputDisabled={isFull}
            min={isBorrow ? min : undefined}
            apy={isBorrow ? undefined : selectedBorrowingOption?.apy ?? Decimal.ZERO}
            defaultValue={defaultV}
            onInputChange={v => onChangeSecondAmount(v)}
            isSelectDisabled={!!selectedTxItem?.first.value}
          />
        )}
        <Flex w="100%" gap="8px">
          <Button
            w="100%"
            h="48px"
            onClick={onButtonClick}
            isDisabled={
              state.type === "waitingForApproval" || state.type === "waitingForConfirmation"
            }
          >
            {state.type === "waitingForApproval"
              ? "Confirm in Wallet"
              : state.type === "waitingForConfirmation"
              ? "Processing..."
              : buttonName}
          </Button>
          {selectedTxItem && (
            <Checkbox
              size="lg"
              colorScheme="primary"
              checked={isFull}
              onChange={e => {
                if (onFullChange) {
                  onFullChange(e.target.checked);
                }
              }}
            >
              Full
            </Checkbox>
          )}
        </Flex>
        {selectedTxItem && (
          <Text
            color="text.200"
            fontSize="18px"
            textAlign="center"
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineOffset: "5px"
              }
            }}
            onClick={onCancel}
          >
            Cancel Transaction
          </Text>
        )}
      </Flex>
      {isBorrow && !isMobileOrTablet && (
        <BalanceInfo items={balanceInfoItems} />
      )}
      {(isEarn || isProtocol) && !isMobileOrTablet && <Flex w="30%" />}
    </Wrapper>
  );
};
