import { useMemo } from "react";

export const separateArrayByElCount = <T,>(array: T[], size: number): T[][] => {
  const subarray = [];
  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size);
  }
  return subarray;
};

export function useSeparatedDataArray<T>(array: T[], size: number): T[][] {
  return useMemo<T[][]>(() => {
    return separateArrayByElCount(array, size);
  }, [array, size]);
}
