import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { zeroLoansTheme } from "./theme";
import { LocalStorageContext, LocalStorageItems } from "./hooks/useLocalStorageContext";
import { useRef } from "react";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { SUPPORTED_NETWORKS } from "./constants/networks";
import { RenderRoutes } from "./components/RenderRoutes";
import { sepolia } from 'wagmi/chains'

const providerUrl = 'https://rpc.notadegen.com/eth/sepolia';

const SUPPORTED_CHAINS = [
  {
    ...sepolia,
    rpcUrls: {
      ...sepolia.rpcUrls,
      default: {
        http: [providerUrl],
      },
      public: {
        http: [providerUrl],
      },
    },
  } as const,
];

export const App = () => {
  const localStorageContextValue = useRef<LocalStorageItems>({
    zloans_isReferralsClosed: localStorage.getItem("zloans_isReferralsClosed") === "true"
  });

  const projectId = "c742df5b084fb47ef2b3b8da15632369";
  const { provider } = configureChains(SUPPORTED_CHAINS, [w3mProvider({ projectId })]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({
      projectId,
      chains: SUPPORTED_CHAINS
    }),
    provider
  });
  const ethereumClient = new EthereumClient(wagmiClient, SUPPORTED_CHAINS);

  return (
    <>
      <LocalStorageContext.Provider value={localStorageContextValue.current}>
        <ChakraProvider theme={zeroLoansTheme}>
          <WagmiConfig client={wagmiClient}>
            <BrowserRouter>
              <RenderRoutes />
            </BrowserRouter>
          </WagmiConfig>
        </ChakraProvider>
      </LocalStorageContext.Provider>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "#E87900",
          "--w3m-background-color": "#E87900"
        }}
        // themeColor="orange"
        // themeBackground="themeColor"
        defaultChain={SUPPORTED_CHAINS[0]}
      />
    </>
  );
};
