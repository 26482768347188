import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import { IStyledTab } from "./types";

export const NavTabTitle = styled(Box)<IStyledTab>`
  position: relative;
  font-size: ${({ fontSize }) => fontSize || "24px"};
  font-weight: ${({ active }) => (active ? 500 : 400)};
  cursor: pointer;
  white-space: nowrap;
  color: ${({ active }) => (active ? "#3D3E3F" : "#8D8D8D")};
  transition: all 0.3s ease;
  padding-left: ${({ indicator, active }) => (indicator && active ? "15px" : "0")};
  padding-bottom: 10px;

  &:hover {
    color: #3d3e3f;
  }

  &:after {
    content: "";
    position: absolute;
    left: ${({ indicator }) => (indicator ? "15px" : "50%")};
    top: calc(100% - 5px);
    transform: translateX(${({ indicator }) => (indicator ? "0" : "-50%")});

    background-color: #b2ddfc;
    height: 8px;
    border-radius: 4px;
    width: ${({ active, indicator }) => (active ? (indicator ? "50%" : "100%") : "0")};
    transition: all 0.3s ease;
  }

  &:before {
    content: "";
    opacity: ${({ indicator, active }) => (indicator && active ? "1" : "0")};
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
    transform: translateY(-50%);

    width: 6px;
    height: 8px;
    background-color: #b2ddfc;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
`;
