import { useLocation } from "react-router-dom";
import { ROUTES } from "../routes/config";

export const useIsApp = () => {
  const { pathname } = useLocation();
  const [isApp, isBorrow, isEarn, isProtocol, isReferral]: Array<"true" | undefined> = [
    pathname.includes(ROUTES.App) ? "true" : undefined,
    pathname.includes(ROUTES.Borrow) ? "true" : undefined,
    pathname.includes(ROUTES.Earn) ? "true" : undefined,
    pathname.includes(ROUTES.Protocol) ? "true" : undefined,
    pathname.includes(ROUTES.Referral) ? "true" : undefined
  ];

  return { isApp, isBorrow, isEarn, isProtocol, isReferral };
};
