import React from "react";
import { AppLayout } from "./AppLayout";
import { LiquityProvider } from "../hooks/LiquityContext";
import { Loader, LoaderType } from "./Loader";
import { TransactionProvider } from "../hooks/useTransactionFunction";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Flex } from "@chakra-ui/react";
import BgLoader from "../assets/background/bg-loader.png";
import { Text } from "./Text";
import { Button } from "./Button";

export const Fallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Flex
      direction="column"
      height="100vh"
      bgImage={BgLoader}
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      alignItems="center"
      pt="100px"
      gap="30px"
      px="16px"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: `<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_5l6XPs.json"  background="transparent" speed="2" style="width: 336px; height: 336px;" loop autoplay></lottie-player>`
        }}
      ></div>
      <Text color="text.600" fontSize="32px" textAlign="center">
        Something went wrong. Please try again.
      </Text>
      <Button maxW="343px" w="100%" onClick={resetErrorBoundary}>
        Retry
      </Button>
    </Flex>
  );
};

export const AppRouteComponent: React.FC = () => (
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Fallback}>
      <LiquityProvider
        loader={<Loader />}
        unsupportedMainnetFallback={<Loader type={LoaderType.UNSUPPORTED_MAINNET} />}
        unsupportedNetworkFallback={() => <Loader type={LoaderType.UNSUPPORTED_NETWORK} />}
      >
        <TransactionProvider>
          <AppLayout />
        </TransactionProvider>
      </LiquityProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
