import React from "react";
import { Decimal } from "@liquity/lib-base";
import { Text } from "../Text";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import EarnAssetMenu from "./EarnAssetMenu";
import { SwiperWithPaginator } from "../Swiper";
import { SwiperSlide } from "swiper/react";
import { AppPage } from "../../types/AppPage";

export type EarnTransactionBlockItem = {
  deposit: Decimal;
  symbol: string;
  apy: Decimal;
  earningByAsset: Record<string, Decimal>;
  totalEarnings: Decimal;
  textToClick: string;
  onClick: () => void | Promise<void>;
  onClaimClick: () => void | Promise<void>;
};

export type EarnTransactionBlockProps = {
  items: EarnTransactionBlockItem[];
};

const EarnTransactionVertical: React.FC<EarnTransactionBlockProps> = ({ items }) => {
  return (
    <SwiperWithPaginator paginatorType="dot" posLength={items.length} pageSize={1}>
      {items.map(v => (
        <SwiperSlide key={v.symbol}>
          <Flex direction="column" gap="8px" mt="8px" w="100%">
            <Flex
              w="100%"
              px="16px"
              bg="rgba(255,255,255,0.5)"
              alignItems="center"
              justifyContent="space-between"
              h="47px"
            >
              <Text color="text.100" fontSize="18px" fontWeight={500}>
                Deposit
              </Text>
              <Text color="text.200" fontSize="18px">
                {v.deposit.prettify(2)} {v.symbol}
              </Text>
            </Flex>
            <Flex
              w="100%"
              px="16px"
              bg="rgba(255,255,255,0.5)"
              alignItems="center"
              justifyContent="space-between"
              h="47px"
            >
              <Text color="text.100" fontSize="18px" fontWeight={500}>
                APY (%)
              </Text>
              <Text color="text.200" fontSize="18px">
                {v.symbol === "ZRO" ? "" : `${v.apy.toNumber(2).toFixed(2)}%`}
              </Text>
            </Flex>
            <Flex
              w="100%"
              px="16px"
              bg="rgba(255,255,255,0.5)"
              alignItems="center"
              justifyContent="space-between"
              h="47px"
            >
              <Text color="text.100" fontSize="18px" fontWeight={500}>
                Earnings by asset
              </Text>
              <Flex color="text.200" fontSize="18px">
                <EarnAssetMenu
                  items={Object.entries(v.earningByAsset).map(([symbol, value]) => ({
                    symbol,
                    value
                  }))}
                />
              </Flex>
            </Flex>
            <Flex
              w="100%"
              px="16px"
              bg="rgba(255,255,255,0.5)"
              alignItems="center"
              justifyContent="space-between"
              h="47px"
            >
              <Text color="text.100" fontSize="18px" fontWeight={500}>
                Total earnings
              </Text>
              <Text color="text.200" fontSize="18px">
                ${v.totalEarnings.prettify(2)}
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <Text
                w="50%"
                textAlign="center"
                color="primary.100"
                fontSize="18px"
                fontWeight={500}
                mt="16px"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                    textUnderlineOffset: "5px"
                  }
                }}
                onClick={v.onClaimClick}
              >
                Claim Rewards
              </Text>
              <Text
                w="50%"
                textAlign="center"
                color="primary.100"
                fontSize="18px"
                fontWeight={500}
                mt="16px"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                    textUnderlineOffset: "5px"
                  }
                }}
                onClick={() => v.onClick()}
              >
                {v.textToClick}
              </Text>
            </Flex>
          </Flex>
        </SwiperSlide>
      ))}
    </SwiperWithPaginator>
  );
};

const EarnTransactionBlock: React.FC<EarnTransactionBlockProps> = ({ items }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex w="100%" direction="column" alignItems="center" mt={isMobileOrTablet ? "56px" : "64px"}>
      <Text color="text.100" fontSize="18px" fontWeight={600}>
        Your Deposits
      </Text>
      {isMobileOrTablet ? (
        items.length > 0 ? (
          <EarnTransactionVertical items={items} />
        ) : (
          <Flex
            w="100%"
            mt="16px"
            px="16px"
            bg="rgba(255,255,255,0.5)"
            alignItems="center"
            justifyContent="center"
            h="47px"
          >
            You have no outstanding deposits
          </Flex>
        )
      ) : (
        <Grid templateRows="1fr" gap="8px" mt="24px">
          {items.length > 0 && (
            <GridItem>
              <Grid templateColumns="repeat(6, 1fr)" gap="12px">
                <GridItem>
                  <Text textAlign="center" color="text.100" fontSize="18px" fontWeight={500}>
                    Deposit
                  </Text>
                </GridItem>
                <GridItem>
                  <Text textAlign="center" color="text.100" fontSize="18px" fontWeight={500}>
                    APY (%)
                  </Text>
                </GridItem>
                <GridItem>
                  <Text textAlign="center" color="text.100" fontSize="18px" fontWeight={500}>
                    Earnings by asset
                  </Text>
                </GridItem>
                <GridItem>
                  <Text textAlign="center" color="text.100" fontSize="18px" fontWeight={500}>
                    Total earnings
                  </Text>
                </GridItem>
                <GridItem />
                <GridItem />
              </Grid>
            </GridItem>
          )}
          {items.length === 0 && (
            <GridItem w="100%" position="relative">
              <Box
                position="absolute"
                bg="rgba(255,255,255,0.4)"
                top={0}
                zIndex={0}
                left="-500%"
                w="1000%"
                h="100%"
              ></Box>
              <Grid templateColumns="repeat(1, 1fr)" py="13px" gap="20px">
                <GridItem zIndex={1}>
                  <Text w="100%" textAlign="center" color="text.200" fontSize="18px">
                    You have no outstanding deposits
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {items.map(v => (
            <GridItem key={v.symbol} w="100%" position="relative">
              <Box
                position="absolute"
                bg="rgba(255,255,255,0.4)"
                top={0}
                zIndex={0}
                left="-500%"
                w="1000%"
                h="100%"
              ></Box>
              <Grid templateColumns="repeat(6, 1fr)" py="13px" gap="20px">
                <GridItem zIndex={1} display="flex" alignItems="center" justifyContent="center">
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    {v.deposit.prettify(2)} {v.symbol}
                  </Text>
                </GridItem>
                <GridItem zIndex={1} display="flex" alignItems="center" justifyContent="center">
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    {(v.apy.infinite ? Decimal.ZERO : v.apy).prettify(2)}%
                  </Text>
                </GridItem>
                <GridItem display="flex" alignItems="center" justifyContent="center">
                  <Flex color="text.200" fontSize="18px" justifyContent="center" alignItems="center">
                    <EarnAssetMenu
                      items={Object.entries(v.earningByAsset).map(([symbol, value]) => ({
                        symbol,
                        value
                      }))}
                    />
                  </Flex>
                </GridItem>
                <GridItem zIndex={1} display="flex" alignItems="center" justifyContent="center">
                  <Text textAlign="center" color="text.200" fontSize="18px">
                    ${v.totalEarnings.prettify(2)}
                  </Text>
                </GridItem>
                <GridItem zIndex={1} display="flex" alignItems="center" justifyContent="center">
                  <Text
                    textAlign="center"
                    color="primary.100"
                    fontSize="18px"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px"
                      }
                    }}
                    onClick={() => v.onClaimClick()}
                  >
                    Claim Rewards
                  </Text>
                </GridItem>
                <GridItem zIndex={1} display="flex" alignItems="center" justifyContent="center">
                  <Text
                    textAlign="center"
                    color="primary.100"
                    fontSize="18px"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                        textUnderlineOffset: "5px"
                      }
                    }}
                    onClick={() => v.onClick()}
                  >
                    {v.textToClick}
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          ))}
        </Grid>
      )}
    </Flex>
  );
};

export default EarnTransactionBlock;
