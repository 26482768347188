import { extendTheme } from "@chakra-ui/react";

export const zeroLoansTheme = extendTheme({
  fonts: {
    body: "Outfit, sans-serif"
  },
  colors: {
    link: {
      100: "#587CFF",
      200: "#587CFF",
      300: "#587CFF",
      400: "#587CFF",
      500: "#587CFF",
      600: "#587CFF",
      700: "#587CFF",
      800: "#587CFF",
      900: "#587CFF"
    },
    text: {
      100: "#293147",
      200: "#515151",
      300: "#3D3E3F",
      400: "#293147",
      500: "#8D8D8D",
      600: "#7A7E82",
      700: "#293147",
      800: "#293147",
      900: "#293147"
    },
    primary: {
      100: "#E87900",
      200: "#E87900",
      300: "#F38926",
      400: "#E87900",
      500: "#E87900",
      600: "#FFAD7F",
      700: "#FFA743",
      800: "#E87900",
      900: "#7A2200"
    }
  }
});
