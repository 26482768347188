import React, { useState } from "react";
import { Image, Tooltip as ChTooltip, TooltipProps } from "@chakra-ui/react";
import TooltipIcon from "../../assets/icons/tooltip.svg";

export const Tooltip: React.FC<Omit<TooltipProps, "children">> = ({ ...props }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <ChTooltip {...props} isOpen={isOpened}>
      <Image
        src={TooltipIcon}
        alt="tooltip"
        onMouseEnter={() => setIsOpened(true)}
        onClick={() => setIsOpened(true)}
        onMouseLeave={() => setIsOpened(false)}
      />
    </ChTooltip>
  );
};
