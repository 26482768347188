import { Block, BlockTag } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";

import { Decimal, PoolBaseConfig } from "@liquity/lib-base";

import devOrNull from "../deployments/dev.json";
import sepolia from "../deployments/sepolia.json";
// import mainnet from "../deployments/mainnet.json";

import { numberify, panic } from "./_utils";
import { EthersCallOverrides, EthersProvider, EthersSigner } from "./types";

import {
  _connectToContracts,
  _LiquityPoolContractAddresses,
  _LiquitySharedContractAddresses,
  _LiquityContracts,
  _LiquityDeploymentJSON,
  _LiquitySharedContracts,
  _LiquityPoolContracts,
  _LiquityBorrowingOptionContracts,
  _splitPoolName,
  _LiquityPriceFeedsContracts
} from "./contracts";

import { _connectToMulticall, _Multicall } from "./_Multicall";
import { formatUnits } from "ethers/lib/utils";
import { ethers } from "ethers";

const dev = devOrNull as _LiquityDeploymentJSON | null;

const deployments: {
  [chainId: number]: _LiquityDeploymentJSON | undefined;
} = {
  // TODO: uncomment after first mainnet/goerli deployment
  // [mainnet.chainId]: mainnet,
  [sepolia.chainId]: sepolia,
  ...(dev !== null ? { [dev.chainId]: dev } : {})
};

declare const brand: unique symbol;

const branded = <T>(t: Omit<T, typeof brand>): T => t as T;

/**
 * Information about a connection to the Liquity protocol.
 *
 * @remarks
 * Provided for debugging / informational purposes.
 *
 *
 * @public
 */
export interface EthersLiquityConnection extends EthersLiquityConnectionOptionalParams {
  /** Ethers `Provider` used for connecting to the network. */
  readonly provider: EthersProvider;

  /** Ethers `Signer` used for sending transactions. */
  readonly signer?: EthersSigner;

  /** Chain ID of the connected network. */
  readonly chainId: number;

  /** Shared contracts connection */
  readonly sharedContracts: EthersLiquitySharedContracts;

  readonly borrowingOptionContracts: Record<string, EthersLiquityBorrowingOptionContracts>;

  readonly poolContracts: Record<string, EthersLiquityPoolContracts>;

  /** @internal */
  readonly _isDev: boolean;

  /** @internal */
  readonly [brand]: unique symbol;
}

export interface EthersLiquityContracts {
  /** Version of the Liquity contracts (Git commit hash). */
  readonly version: string;

  /** Date when the Liquity contracts were deployed. */
  readonly deploymentDate: Date;

  /** Number of block in which the first Liquity contract was deployed. */
  readonly startBlock: number;

  /** A mapping of Liquity contracts' names to their addresses. */
  readonly addresses: Record<string, string>;
}

export interface EthersLiquityBorrowingOptionContracts extends EthersLiquityContracts {
  /** Amount of LQTY collectively rewarded to stakers of the liquidity mining pool per second. */
  readonly liquidityMiningLQTYRewardRate: Decimal;

  readonly totalStabilityPoolLQTYReward: Decimal;

  readonly issuanceFactor: ethers.BigNumber;
}

export interface EthersLiquitySharedContracts extends EthersLiquityContracts {
  readonly referralCenterReferrerPart: Decimal;
}

export interface EthersLiquityPoolContracts extends EthersLiquityContracts {
  /** Time period (in seconds) after `depчloymentDate` during which redemptions are disabled. */
  readonly bootstrapPeriod: number;

  readonly baseConfiguration: EthersLiquityPoolConfiguration;

  readonly borrowerOperationsReferralsPart: Decimal;

  readonly troveManagerReferralsPart: Decimal;

  /** @internal */
  readonly _priceFeedIsTestnet: boolean;

  readonly hasReversedOracle: boolean;

  readonly isWETH: boolean;
}

export type EthersLiquityPoolConfiguration = PoolBaseConfig;

/** @internal */
export interface _InternalEthersLiquityConnection extends EthersLiquityConnection {
  readonly addressesShared: _LiquitySharedContractAddresses;
  readonly addressesPool: Record<string, _LiquityPoolContractAddresses>;
  readonly _contracts: _LiquityContracts;
  readonly _multicall?: _Multicall;
}

const connectionFrom = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  _contracts: _LiquityContracts,
  _multicall: _Multicall | undefined,
  { pools, sharedContracts, ...deployment }: _LiquityDeploymentJSON,
  optionalParams?: EthersLiquityConnectionOptionalParams
): _InternalEthersLiquityConnection => {
  if (
    optionalParams &&
    optionalParams.useStore !== undefined &&
    !validStoreOptions.includes(optionalParams.useStore)
  ) {
    throw new Error(`Invalid useStore value ${optionalParams.useStore}`);
  }

  // convert contracts to key-value
  const addressesShared = Object.entries(_contracts.sharedContracts).reduce<Record<string, string>>(
    (prev, [key, value]) => ({ ...prev, [key]: value.address }),
    {}
  ) as _LiquitySharedContractAddresses;
  const addressesPool = Object.entries(_contracts.poolContracts).reduce<
    Record<string, _LiquityPoolContractAddresses>
  >((prev, [name, pool]) => {
    return {
      ...prev,
      [name]: Object.entries(pool).reduce<Record<string, string>>(
        (prev, [key, value]) => ({ ...prev, [key]: value.address }),
        {}
      ) as _LiquityPoolContractAddresses
    };
  }, {});

  return branded({
    provider,
    signer,
    _contracts,
    addressesShared,
    addressesPool,
    sharedContracts: {
      ...sharedContracts,
      deploymentDate: new Date(sharedContracts.deploymentDate),
      referralCenterReferrerPart: Decimal.from(
        formatUnits(deployment.config.referralsConfig.referrerPartPercentage, 6)
      )
    },
    borrowingOptionContracts: Object.entries(pools).reduce<
      Record<string, EthersLiquityBorrowingOptionContracts>
    >(
      (
        prev,
        [
          key,
          {
            deploymentDate,
            liquidityMiningLQTYRewardRate,
            totalStabilityPoolLQTYReward,
            issuanceFactor,
            ...value
          }
        ]
      ) => {
        key = _splitPoolName(key).borrowingTokenName;

        return {
          ...prev,
          [key]: {
            ...value,
            deploymentDate: new Date(deploymentDate),
            liquidityMiningLQTYRewardRate: Decimal.from(liquidityMiningLQTYRewardRate),
            totalStabilityPoolLQTYReward: Decimal.from(totalStabilityPoolLQTYReward),
            issuanceFactor: ethers.BigNumber.from(issuanceFactor)
          } as EthersLiquityBorrowingOptionContracts
        };
      },
      {}
    ),
    poolContracts: Object.entries(pools).reduce<Record<string, EthersLiquityPoolContracts>>(
      (prev, [key, { deploymentDate, config, ...value }]) => {
        const configJson = config.baseConfig;

        const borrowingTokenLiquidationReserve = Decimal.from(
          formatUnits(configJson.lusdGasCompensation)
        );
        const borrowingTokenMinNetDebt = Decimal.from(formatUnits(configJson.minNetDebt));

        const configFormatted = {
          minimumBorrowingRate: Decimal.from(formatUnits(configJson.borrowingFeeFloor)),
          criticalCollateralRatio: Decimal.from(formatUnits(configJson.ccr)),
          minimumCollateralRatio: Decimal.from(formatUnits(configJson.mcr)),
          borrowingTokenLiquidationReserve,
          borrowingTokenMinNetDebt,
          borrowingTokenMinimumDebt: borrowingTokenMinNetDebt.add(borrowingTokenLiquidationReserve),
          collateralTokenDecimals: config.poolConfig?.collateralTokenDecimals ?? 18
        } as EthersLiquityPoolConfiguration;

        return {
          ...prev,
          [key]: {
            ...value,
            deploymentDate: new Date(deploymentDate),
            baseConfiguration: configFormatted,
            borrowerOperationsReferralsPart: Decimal.from(
              formatUnits(config.referralsConfig.borrowerOperations.referralsPartPercentage, 6)
            ),
            hasReversedOracle: value.isPriceOracleReversed,
            troveManagerReferralsPart: Decimal.from(
              formatUnits(config.referralsConfig.troveManager.referralsPartPercentage, 6)
            )
          } as EthersLiquityPoolContracts
        };
      },
      {}
    ),
    ...deployment,
    ...optionalParams
  });
};

/** @internal */
export const _getContracts = (connection: EthersLiquityConnection): _LiquityContracts =>
  (connection as _InternalEthersLiquityConnection)._contracts;

export const _getMulticall = (connection: EthersLiquityConnection): _Multicall | undefined =>
  (connection as _InternalEthersLiquityConnection)._multicall;

export const _getPool = (
  poolName: string,
  connection: EthersLiquityConnection
): EthersLiquityPoolContracts => connection.poolContracts[poolName];

export const _getPoolBaseConfig = (
  poolName: string,
  connection: EthersLiquityConnection
): PoolBaseConfig => {
  const config = connection.poolContracts[poolName].baseConfiguration;
  return config;
};
export const _getPoolConfig = (
  poolName: string,
  connection: EthersLiquityConnection
) => {
  const config = connection.poolContracts[poolName];
  return config;
};

export const _getBorrowingOptionContracts = (
  borrowingOptionName: string,
  connection: EthersLiquityConnection
): _LiquityBorrowingOptionContracts =>
  _getContracts(connection).borrowingOptionContracts[borrowingOptionName];

export const _getBorrowingOptionContractsFromPoolName = (
  poolName: string,
  connection: EthersLiquityConnection
): _LiquityBorrowingOptionContracts =>
  _getContracts(connection).borrowingOptionContracts[_splitPoolName(poolName).borrowingTokenName];

export const _getSharedContracts = (connection: EthersLiquityConnection): _LiquitySharedContracts =>
  _getContracts(connection).sharedContracts;

export const _getBorrowingOptions = (connection: EthersLiquityConnection) =>
  Object.entries(_getContracts(connection).borrowingOptionContracts).map(([key, v]) => {
    return {
      tokenAddress: v.borrowingToken.address,
      optionName: key
    };
  });

export const _getCollateralOptions = (connection: EthersLiquityConnection) => {
  const options: string[] = [];

  Object.keys(_getContracts(connection).poolContracts)
    .map(_splitPoolName)
    .map(v => v.collateralTokenName)
    .filter(v => {
      if (options.includes(v)) return;
      options.push(v);
    });

  return options;
};

export const _getPoolsContracts = (
  connection: EthersLiquityConnection
): Record<string, _LiquityPoolContracts> => _getContracts(connection).poolContracts;

export const _getPoolContracts = (
  poolName: string,
  connection: EthersLiquityConnection
): _LiquityPoolContracts => _getPoolsContracts(connection)[poolName];

export const _getFeedsContracts = (connection: EthersLiquityConnection) =>
  _getContracts(connection).feedContracts;

export const _getFeedContracts = (
  tokenName: string,
  connection: EthersLiquityConnection
): _LiquityPriceFeedsContracts[string] => _getFeedsContracts(connection)[tokenName];

const getMulticall = (connection: EthersLiquityConnection): _Multicall | undefined =>
  (connection as _InternalEthersLiquityConnection)._multicall;

const getTimestampFromBlock = ({ timestamp }: Block) => timestamp;

/** @internal */
export const _getBlockTimestamp = (
  connection: EthersLiquityConnection,
  blockTag: BlockTag = "latest"
): Promise<number> =>
  // Get the timestamp via a contract call whenever possible, to make it batchable with other calls
  getMulticall(connection)?.getCurrentBlockTimestamp({ blockTag }).then(numberify) ??
  _getProvider(connection).getBlock(blockTag).then(getTimestampFromBlock);

/** @internal */
export const _requireSigner = (connection: EthersLiquityConnection): EthersSigner =>
  connection.signer ?? panic(new Error("Must be connected through a Signer"));

/** @internal */
export const _getProvider = (connection: EthersLiquityConnection): EthersProvider =>
  connection.provider;

// TODO parameterize error message?
/** @internal */
export const _requireAddress = (
  connection: EthersLiquityConnection,
  overrides?: { from?: string }
): string =>
  overrides?.from ?? connection.userAddress ?? panic(new Error("A user address is required"));

/** @internal */
export const _getOverride = (
  overrides?: EthersCallOverrides
): EthersCallOverrides =>
  overrides ?? { blockTag: 'latest' }

/** @internal */
export const _requireFrontendAddress = (connection: EthersLiquityConnection): string =>
  connection.frontendTag ?? panic(new Error("A frontend address is required"));

/** @internal */
export const _usingStore = (
  connection: EthersLiquityConnection
): connection is EthersLiquityConnection & { useStore: EthersLiquityStoreOption } =>
  connection.useStore !== undefined;

/**
 * Thrown when trying to connect to a network where Liquity is not deployed.
 *
 * @remarks
 * Thrown by {@link ReadableEthersLiquity.(connect:2)} and {@link EthersLiquity.(connect:2)}.
 *
 * @public
 */
export class UnsupportedNetworkError extends Error {
  /** Chain ID of the unsupported network. */
  readonly chainId: number;

  /** @internal */
  constructor(chainId: number) {
    super(`Unsupported network (chainId = ${chainId})`);
    this.name = "UnsupportedNetworkError";
    this.chainId = chainId;
  }
}

const getProviderAndSigner = (
  signerOrProvider: EthersSigner | EthersProvider
): [provider: EthersProvider, signer: EthersSigner | undefined] => {
  const provider: EthersProvider = Signer.isSigner(signerOrProvider)
    ? signerOrProvider.provider ?? panic(new Error("Signer must have a Provider"))
    : signerOrProvider;

  const signer = Signer.isSigner(signerOrProvider) ? signerOrProvider : undefined;

  return [provider, signer];
};

/** @internal */
export const _connectToDeployment = (
  deployment: _LiquityDeploymentJSON,
  signerOrProvider: EthersSigner | EthersProvider,
  optionalParams?: EthersLiquityConnectionOptionalParams
): EthersLiquityConnection =>
  connectionFrom(
    ...getProviderAndSigner(signerOrProvider),
    _connectToContracts(signerOrProvider, deployment),
    undefined,
    deployment,
    optionalParams
  );

/**
 * Possible values for the optional
 * {@link EthersLiquityConnectionOptionalParams.useStore | useStore}
 * connection parameter.
 *
 * @remarks
 * Currently, the only supported value is `"blockPolled"`, in which case a
 * {@link BlockPolledLiquityStore} will be created.
 *
 * @public
 */
export type EthersLiquityStoreOption = "blockPolled";

const validStoreOptions = ["blockPolled"];

/**
 * Optional parameters of {@link ReadableEthersLiquity.(connect:2)} and
 * {@link EthersLiquity.(connect:2)}.
 *
 * @public
 */
export interface EthersLiquityConnectionOptionalParams {
  /**
   * Address whose Trove, Stability Deposit, LQTY Stake and balances will be read by default.
   *
   * @remarks
   * For example {@link EthersLiquity.getTrove | getTrove(address?)} will return the Trove owned by
   * `userAddress` when the `address` parameter is omitted.
   *
   * Should be omitted when connecting through a {@link EthersSigner | Signer}. Instead `userAddress`
   * will be automatically determined from the `Signer`.
   */
  readonly userAddress?: string;

  /**
   * Address that will receive LQTY rewards from newly created Stability Deposits by default.
   *
   * @remarks
   * For example
   * {@link EthersLiquity.depositLUSDInStabilityPool | depositLUSDInStabilityPool(amount, frontendTag?)}
   * will tag newly made Stability Deposits with this address when its `frontendTag` parameter is
   * omitted.
   */
  readonly frontendTag?: string;

  /**
   * Create a {@link @liquity/lib-base#LiquityStore} and expose it as the `store` property.
   *
   * @remarks
   * When set to one of the available {@link EthersLiquityStoreOption | options},
   * {@link ReadableEthersLiquity.(connect:2) | ReadableEthersLiquity.connect()} will return a
   * {@link ReadableEthersLiquityWithStore}, while
   * {@link EthersLiquity.(connect:2) | EthersLiquity.connect()} will return an
   * {@link EthersLiquityWithStore}.
   *
   * Note that the store won't start monitoring the blockchain until its
   * {@link @liquity/lib-base#LiquityStore.start | start()} function is called.
   */
  readonly useStore?: EthersLiquityStoreOption;
}

/** @internal */
export function _connectByChainId<T>(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  optionalParams: EthersLiquityConnectionOptionalParams & { useStore: T }
): EthersLiquityConnection & { useStore: T };

/** @internal */
export function _connectByChainId(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  optionalParams?: EthersLiquityConnectionOptionalParams
): EthersLiquityConnection;

/** @internal */
export function _connectByChainId(
  provider: EthersProvider,
  signer: EthersSigner | undefined,
  chainId: number,
  optionalParams?: EthersLiquityConnectionOptionalParams
): EthersLiquityConnection {
  const deployment: _LiquityDeploymentJSON =
    deployments[chainId] ?? panic(new UnsupportedNetworkError(chainId));

  return connectionFrom(
    provider,
    signer,
    _connectToContracts(signer ?? provider, deployment),
    _connectToMulticall(signer ?? provider, chainId),
    deployment,
    optionalParams
  );
}

/** @internal */
export const _connect = async (
  signerOrProvider: EthersSigner | EthersProvider,
  optionalParams?: EthersLiquityConnectionOptionalParams
): Promise<EthersLiquityConnection> => {
  const [provider, signer] = getProviderAndSigner(signerOrProvider);

  if (signer) {
    if (optionalParams?.userAddress !== undefined) {
      throw new Error("Can't override userAddress when connecting through Signer");
    }

    optionalParams = {
      ...optionalParams,
      userAddress: await signer.getAddress()
    };
  }

  return _connectByChainId(provider, signer, (await provider.getNetwork()).chainId, optionalParams);
};
