import React from "react";
import { createIcon } from "@chakra-ui/react";

export const ArrowIcon = createIcon({
  displayName: "ArrowIcon",
  viewBox: "0 0 18 20",
  path: (
    <>
      <path
        d="M5.2876 4.66772L7.75345 7.18462L0.665039 14.4198L6.13191 19.9998L13.2203 12.7647L15.6876 15.283L17.7452 3.72556L17.9989 2.30859L5.2876 4.66772Z"
        fill="#FD7B1D"
      />
      <path
        d="M17.3349 0.00195312L17.9955 2.30452L17.0856 3.57859L16.25 3.07998C16.25 3.07998 16.616 1.88479 16.6335 1.84609C16.651 1.80739 17.3349 0.00195312 17.3349 0.00195312Z"
        fill="#FD7B1D"
      />
      <path
        d="M4.62305 2.36133L5.288 4.66836L6.50269 5.47657L7.17639 5.16698L6.37583 3.72173L4.62305 2.36133Z"
        fill="#FD7B1D"
      />
      <path
        d="M0 12.1152L0.662031 14.4163L1.92048 15.3347C1.92048 15.3347 2.44981 14.9253 2.44981 14.7944C2.46679 14.6341 2.49555 14.4754 2.53584 14.3196L0.828268 12.3058L0 12.1152Z"
        fill="#FD7B1D"
      />
      <path
        d="M12.5574 9.75443L5.46756 16.9911L0.69043 12.115L7.7803 4.87841L5.62942 2.683L16.7265 0.623047L14.7098 11.9513L12.5574 9.75443Z"
        fill="white"
      />
      <path
        d="M6.63544 3.00361L16.1212 1.24282L14.3961 10.9249L12.5587 9.04952L5.46886 16.2862L1.38584 12.1186L8.47572 4.88198L6.63836 3.00658M7.10061 4.88942L0.0107422 12.1261L5.46595 17.6942L12.5558 10.4576L15.0217 12.9745L17.3329 0L4.62164 2.35913L7.10061 4.88942Z"
        fill="#FD451D"
      />
      <path
        style={{ mixBlendMode: "overlay" }}
        d="M6.63544 3.00361L16.1212 1.24282L14.3961 10.9249L12.5587 9.04952L5.46886 16.2862L1.38584 12.1186L8.47572 4.88198L6.63836 3.00658M7.10061 4.88942L0.0107422 12.1261L5.46595 17.6942L12.5558 10.4576L15.0217 12.9745L17.3329 0L4.62164 2.35913L7.10061 4.88942Z"
        fill="url(#paint0_linear_607_1059)"
      />
      <path
        d="M12.5586 10.459L13.2221 12.7645L15.6894 15.2829L15.0244 12.9759L12.5586 10.459Z"
        fill="#FD7B1D"
      />
      <path
        d="M15.6884 15.2834L15.0234 12.9764L17.3347 0.00195312L17.9997 2.30899L15.6884 15.2834Z"
        fill="#FD7B1D"
      />
      <path
        d="M13.2221 12.7645L12.5586 10.459L5.46875 17.6956L6.1337 19.9997L13.2221 12.7645Z"
        fill="#FD7B1D"
      />
      <path
        style={{ mixBlendMode: "overlay" }}
        d="M13.2221 12.7645L12.5586 10.459L5.46875 17.6956L6.1337 19.9997L13.2221 12.7645Z"
        fill="url(#paint1_linear_607_1059)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_607_1059"
          x1="2.73419"
          y1="14.905"
          x2="17.6345"
          y2="0.306918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F4F4F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_607_1059"
          x1="5.40244"
          y1="19.2544"
          x2="13.4501"
          y2="11.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </>
  )
});
