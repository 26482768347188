import {
  Decimal,
  LiquityStoreState,
  Percent,
  PoolBaseConfig,
  Trove,
  TroveAdjustmentParams,
  TroveChange,
  TroveClosureParams,
  TroveCreationParams
} from "@liquity/lib-base";

export const selectForTroveChangeValidation = ({
  price,
  total,
  assetBalance,
  lusdBalance,
  numberOfTroves,
  assetSymbol
}: LiquityStoreState) => ({ assetSymbol, price, total, assetBalance, lusdBalance, numberOfTroves });

type TroveChangeValidationSelectedState = ReturnType<typeof selectForTroveChangeValidation>;

interface TroveChangeValidationContext extends TroveChangeValidationSelectedState {
  originalTrove: Trove;
  resultingTrove: Trove;
  recoveryMode: boolean;
  wouldTriggerRecoveryMode: boolean;
}

export const validateTroveChange = (
  originalTrove: Trove,
  adjustedTrove: Trove,
  borrowingRate: Decimal,
  selectedState: TroveChangeValidationSelectedState
): [validChange: Exclude<TroveChange<Decimal>, { type: "invalidCreation" }> | undefined] => {
  const { total: totalTrove, price } = selectedState;
  originalTrove = originalTrove.setBaseConfig(adjustedTrove.poolBaseConfig);

  const change = originalTrove.whatChanged(adjustedTrove, borrowingRate);
  console.log("ICHANGE", change);

  const baseConfig = adjustedTrove.poolBaseConfig;

  const mcrPercent = new Percent(baseConfig.minimumCollateralRatio).toString(0);
  const ccrPercent = new Percent(baseConfig.criticalCollateralRatio).toString(0);

  if (!change) {
    return [undefined];
  }

  // Reapply change to get the exact state the Trove will end up in (which could be slightly
  // different from `edite  d` due to imprecision).
  const resultingTrove = originalTrove.apply(change, borrowingRate);

  const total = totalTrove.setBaseConfig(baseConfig);

  const recoveryMode = total.collateralRatioIsBelowCritical(price);
  const wouldTriggerRecoveryMode = total
    .subtract(originalTrove)
    .add(resultingTrove)
    .collateralRatioIsBelowCritical(price);

  const context: TroveChangeValidationContext = {
    ...selectedState,
    originalTrove,
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode
  };

  if (change.type === "invalidCreation") {
    return [undefined];
  }

  const validateParams: ValidateTroveParams = { ccrPercent, mcrPercent, baseConfig };

  const errorDescription =
    change.type === "creation"
      ? validateTroveCreation(validateParams, change.params, context)
      : change.type === "closure"
      ? validateTroveClosure(validateParams, change.params, context)
      : validateTroveAdjustment(validateParams, change.params, context);

  if (errorDescription) {
    return [undefined];
  }

  return [change];
};

type ValidateTroveParams = {
  ccrPercent: string;
  mcrPercent: string;
  baseConfig: PoolBaseConfig;
};

const validateTroveCreation = (
  { baseConfig, ccrPercent, mcrPercent }: ValidateTroveParams,
  { depositCollateral, borrowLUSD }: TroveCreationParams<Decimal>,
  {
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode,
    assetBalance,
    price,
    assetSymbol
  }: TroveChangeValidationContext
): boolean => {
  if (borrowLUSD.lt(baseConfig.borrowingTokenMinNetDebt)) {
    return true;
  }

  if (recoveryMode) {
    if (!resultingTrove.isOpenableInRecoveryMode(price)) {
      return true;
    }
  } else {
    if (resultingTrove.collateralRatioIsBelowMinimum(price)) {
      return true;
    }

    if (wouldTriggerRecoveryMode) {
      return true;
    }
  }

  if (depositCollateral.gt(assetBalance)) {
    return true;
  }

  return false;
};

const validateTroveAdjustment = (
  { baseConfig, ccrPercent, mcrPercent }: ValidateTroveParams,
  { depositCollateral, withdrawCollateral, borrowLUSD, repayLUSD }: TroveAdjustmentParams<Decimal>,
  {
    originalTrove,
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode,
    price,
    assetBalance,
    lusdBalance,
    assetSymbol
  }: TroveChangeValidationContext
): boolean => {
  if (recoveryMode) {
    if (withdrawCollateral) {
      console.log(3)
      return true;
    }

    if (borrowLUSD) {
      if (resultingTrove.collateralRatioIsBelowCritical(price)) {
        console.log(4)
        return true;
      }

      if (resultingTrove.collateralRatio(price).lt(originalTrove.collateralRatio(price))) {
        console.log(5)
        return true;
      }
    }
  } else {
    if (resultingTrove.collateralRatioIsBelowMinimum(price)) {
      console.log(1)
      return true;
    }

    if (wouldTriggerRecoveryMode) {
      console.log(2)
      return true;
    }
  }

  if (repayLUSD) {
    if (resultingTrove.debt.lt(baseConfig.borrowingTokenMinimumDebt)) {
      return true;
    }

    if (repayLUSD.gt(lusdBalance)) {
      return true;
    }
  }

  if (depositCollateral?.gt(assetBalance)) {
    console.log(8);
    return true;
  }

  return false;
};

const validateTroveClosure = (
  { ccrPercent }: ValidateTroveParams,
  { repayLUSD }: TroveClosureParams<Decimal>,
  {
    recoveryMode,
    wouldTriggerRecoveryMode,
    numberOfTroves,
    lusdBalance
  }: TroveChangeValidationContext
): boolean => {
  if (numberOfTroves === 1) {
    return true;
  }

  if (recoveryMode) {
    return true;
  }

  if (repayLUSD?.gt(lusdBalance)) {
    return true;
  }

  if (wouldTriggerRecoveryMode) {
    return true;
  }

  return false;
};
