import React from "react";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { Decimal } from "@liquity/lib-base";

export type EarnInfoItem = {
  token: string;
  poolSize: Decimal;
  apy: Decimal;
};

export type EarnInfoProps = {
  items: EarnInfoItem[];
};

export const EarnInfo: React.FC<EarnInfoProps> = ({ items }) => {
  const { isMobileOrTablet } = useMatchBreakpoints();

  return (
    <Flex w={isMobileOrTablet ? "100%" : "30%"}>
      <Flex
        direction="column"
        bg="white"
        borderRadius="20px"
        p="24px 12px"
        w={isMobileOrTablet ? "100%" : "auto"}
      >
        <Grid
          w="100%"
          templateColumns="repeat(3, 1fr)"
          fontSize="18px"
          columnGap="16px"
          rowGap="10px"
          justifyItems="center"
          color="text.500"
        >
          <GridItem>Token</GridItem>
          <GridItem>Pool Size</GridItem>
          <GridItem>APY</GridItem>
          {items.map(({ token, poolSize, apy }) => (
            <React.Fragment key={token}>
              <GridItem>{token}</GridItem>
              <GridItem color="text.200">${poolSize.prettify(2)}</GridItem>
              <GridItem color="text.200">
                {(apy.infinite ? Decimal.ZERO : apy).prettify(2)}%
              </GridItem>
            </React.Fragment>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};
