import React, {useEffect} from "react";
import {
  Flex,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import LargeLogo from "../../assets/header/logo-large.svg";
import SmallLogo from "../../assets/header/logo-small.svg";
import MenuDot from "../../assets/header/menu-dot.svg";
import { useMatchBreakpoints } from "../../hooks/useMatchBreakpoints";
import { Button } from "../Button";
import { HeaderLink } from "./HeaderLink";
import { blogLink, docsLink } from "../../constants/links";
import { Link as RdLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/config";
import { NavTab } from "./NavTab";
import { useIsApp } from "../../hooks/useIsApp";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import {shortenAddress} from "../../helpers/formatters";

export const Header: React.FC = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();
  const { logo, bWidth, bHeight, padding, mb } = useBreakpointValue(
    [
      { logo: SmallLogo, bWidth: "140px", bHeight: "40px", padding: "20px 16px", mb: "30px" },
      { logo: SmallLogo, bWidth: "140px", bHeight: "40px", padding: "20px 16px", mb: "30px" },
      { logo: LargeLogo, bWidth: "140px", bHeight: "48px", padding: "36px 80px", mb: "70px" }
    ],
    {
      ssr: false
    }
  ) ?? { logo: SmallLogo, bWidth: "132px", bHeight: "40px", padding: "10px 16px", mb: "40px" };
  const { isMobileOrTablet } = useMatchBreakpoints();
  const { isApp, isBorrow, isEarn, isProtocol, isReferral } = useIsApp();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    const timeout = setTimeout(() => {
      onOpen();
    }, 500);
    return () => clearTimeout(timeout);
  }, [onOpen]);

  return (
    <Flex alignItems="center" w="100%" direction="column" mb={mb}>
      <Flex alignItems="center" justifyContent="space-between" padding={padding} w="100%">
        <Image
          src={logo}
          alt="logo"
          cursor="pointer"
          onClick={() => navigate(ROUTES.Landing)}
        />
        {!isMobileOrTablet && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            maxW={isApp ? "661px" : "184px"}
          >
            {isApp ? (
              <>
                <NavTab title="Borrow" onClick={() => navigate(ROUTES.Borrow)} active={isBorrow} />
                <NavTab title="Earn" onClick={() => navigate(ROUTES.Earn)} active={isEarn} />
                <NavTab
                  title="Referral"
                  onClick={() => navigate(ROUTES.Referral)}
                  active={isReferral}
                />
                <NavTab
                  title="Protocol"
                  onClick={() => navigate(ROUTES.Protocol)}
                  active={isProtocol}
                />
              </>
            ) : (
              <>
                <HeaderLink to={docsLink}>Docs</HeaderLink>
                <HeaderLink to={blogLink}>Blog</HeaderLink>
              </>
            )}
          </Flex>
        )}
        <Flex alignItems="center" gap="24px">
          {isApp ? (
            isConnected && address ? (
              <Button
                height={bHeight}
                width={bWidth}
                onClick={() => open({ route: "Account" })}
                variant="outline"
              >
                {shortenAddress(address)}
              </Button>
            ) : (
              <Button
                height={bHeight}
                width={bWidth}
                onClick={() => open({ route: "ConnectWallet" })}
              >
                Connect wallet
              </Button>
            )
          ) : (
              <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={false}
                autoFocus={false}
              >
                <PopoverTrigger>
                  <Link
                    as={RdLink}
                    to={ROUTES.Borrow}
                    sx={{
                      "&:hover": {
                        textDecoration: "none"
                      }
                    }}
                    onClick={async e => {
                      e.preventDefault();
                      try {
                        if (!isConnected) {
                          await open({ route: "ConnectWallet" });
                        }
                        navigate(ROUTES.Borrow);
                      } catch (e) {
                        console.error("CE:", e);
                      }
                    }}
                  >
                  <Button
                    height={bHeight}
                    width={bWidth}
                  >
                    Enter App
                  </Button>
                  </Link>
                </PopoverTrigger>
                <PopoverContent maxW={200}>
                  <PopoverHeader pt={4} fontWeight="bold" border="0" w="100%" textAlign="center">
                    Live on testnet
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={0}
                  />
                </PopoverContent>
              </Popover>
          )}
          {isMobileOrTablet && !isApp && <Image src={MenuDot} alt="menu-dot" cursor="pointer" />}
        </Flex>
      </Flex>
      {isMobileOrTablet && isApp && (
        <Flex alignItems="center" justifyContent="space-between" w="100%" maxW="350px" px={padding}>
          <NavTab
            title="Borrow"
            onClick={() => navigate(ROUTES.Borrow)}
            active={isBorrow}
            fontSize="18px"
          />
          <NavTab
            title="Earn"
            onClick={() => navigate(ROUTES.Earn)}
            active={isEarn}
            fontSize="18px"
          />
          <NavTab
            title="Referral"
            onClick={() => navigate(ROUTES.Referral)}
            active={isReferral}
            fontSize="18px"
          />
          <NavTab
            title="Protocol"
            onClick={() => navigate(ROUTES.Protocol)}
            active={isProtocol}
            fontSize="18px"
          />
        </Flex>
      )}
    </Flex>
  );
};
